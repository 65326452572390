import { FC } from "react";
import { IUPSState } from "../types";
import {
  IconSystemStationsWarning,
  IconUPSBattery,
  IconUPSSource,
  IconUPSTemperature,
  IconUPSVoltage,
} from "../../../assets";
import { Body2, H3, Label } from "../../../components/Typography";
import { useTranslation } from "react-i18next";
import { Nullable } from "../../../app/types";
import styles from "./UPSState.module.css";

interface Props {
  upsState: Nullable<IUPSState>;
}

export const UPSState: FC<Props> = ({ upsState }) => {
  const { t } = useTranslation("upsState");

  if (!upsState) {
    return (
      <div className={styles.container}>
        <div className={styles.warning}>
          <IconSystemStationsWarning /> <Body2>{t("warning")}</Body2>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <H3>{upsState.modelName}</H3>
        <Body2 className={styles["serial-number"]}>
          {upsState.serialNumber}
        </Body2>
      </div>
      <div className={styles.content}>
        <div className={styles["item-container"]}>
          <div className={styles["icon-container"]}>
            <IconUPSVoltage />
          </div>
          <div className={styles["item-content"]}>
            <div className={styles["item-content-value"]}>
              <H3>{upsState.ratinVoltage}</H3>
            </div>
            <Label className={styles["item-content-label"]}>
              {t("ratinVoltage")}
            </Label>
          </div>
        </div>
        <div className={styles["item-container"]}>
          <div className={styles["icon-container"]}>
            <IconUPSVoltage />
          </div>
          <div className={styles["item-content"]}>
            <div className={styles["item-content-value"]}>
              <H3>{upsState.ratoutVoltage}</H3>
            </div>
            <Label className={styles["item-content-label"]}>
              {t("ratoutVoltage")}
            </Label>
          </div>
        </div>
        <div className={styles["item-container"]}>
          <div className={styles["icon-container"]}>
            <IconUPSBattery />
          </div>
          <div className={styles["item-content"]}>
            <div className={styles["item-content-value"]}>
              <H3>{upsState.batteryStatus}</H3>
            </div>
            <Label className={styles["item-content-label"]}>
              {t("battery")}
            </Label>
          </div>
        </div>
        <div className={styles["item-container"]}>
          <div className={styles["icon-container"]}>
            <IconUPSTemperature />
          </div>
          <div className={styles["item-content"]}>
            <div className={styles["item-content-value"]}>
              <H3>{upsState.temperature}°C</H3>
            </div>
            <Label className={styles["item-content-label"]}>
              {t("temperature")}
            </Label>
          </div>
        </div>
        <div className={styles["item-container"]}>
          <div className={styles["icon-container"]}>
            <IconUPSSource />
          </div>
          <div className={styles["item-content"]}>
            <div className={styles["item-content-value"]}>
              <H3>{upsState.outSource}</H3>
            </div>
            <Label className={styles["item-content-label"]}>
              {t("source")}
            </Label>
          </div>
        </div>
      </div>
    </div>
  );
};
