import classNames from "classnames/bind";
import { FC } from "react";
import { Colors } from "../../app/types";
import styles from "./IconWithBackground.module.css";

const cx = classNames.bind(styles);

interface Props {
  icon: JSX.Element;
  color: Colors;
  isCircle?: boolean;
  size?: "small" | "large";
}

export const IconWithBackground: FC<Props> = ({
  icon,
  color,
  isCircle = false,
  size = "small",
}) => {
  if (isCircle) {
    return (
      <div
        className={cx({
          icon: true,
          [color]: true,
          circle: isCircle,
        })}
      >
        <div className={styles.inner}>{icon}</div>
      </div>
    );
  }
  return (
    <div
      className={cx({
        icon: true,
        [color]: true,
        circle: isCircle,
        [size]: true,
      })}
    >
      {icon}
    </div>
  );
};
