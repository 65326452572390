import { FC } from "react";
import { useTranslation } from "react-i18next";
import { LMNewSwitch } from "../../../components/LMNewSwitch";
import { Button2, H3 } from "../../../components/Typography";
import {
  IActiveTaskConfiguration,
  ISystemConfiguration,
  ISystemTasksValues,
} from "../types";
import { EActiveTasks } from "../../systems";
import styles from "./SystemTasks.module.css";

const getIsDisabled = (
  taskName: EActiveTasks,
  configuration: ISystemConfiguration
) => {
  const task = configuration.activeTasks.find(
    (t) => t.activeTask === taskName
  ) as IActiveTaskConfiguration;
  return task.readOnly;
};

interface Props {
  values: ISystemTasksValues;
  onChange: (values: ISystemTasksValues) => void;
  configuration: ISystemConfiguration;
}

export const SystemTasksModalContent: FC<Props> = ({
  values,
  onChange,
  configuration,
}) => {
  const { t } = useTranslation("systemTasks");

  return (
    <div className={styles["confirmation-container"]}>
      <div className={styles["confirmation-title"]}>
        <H3>{t("title")}</H3>
      </div>
      <div className={styles["switch-group"]}>
        {configuration.activeTasks.map((task) => (
          <div className={styles["switch-line"]} key={task.activeTask}>
            <Button2>{t(task.activeTask)}</Button2>
            <LMNewSwitch
              defaultChecked={values[task.activeTask]}
              onChange={() =>
                onChange({
                  ...values,
                  [task.activeTask]: !values[task.activeTask] as boolean,
                })
              }
              disabled={getIsDisabled(task.activeTask, configuration)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
