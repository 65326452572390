import { Auth0Context, withAuthenticationRequired } from "@auth0/auth0-react";
import React, { ComponentType, useContext } from "react";
import { LMLayout } from "../../layout/LMLayout";
import { Loader } from "../../components";

interface ProtectedRouteProps {
  component: ComponentType;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component,
}) => {
  const { isAuthenticated } = useContext(Auth0Context);
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loader />,
  });

  if (!isAuthenticated) return <Component />;

  return (
    <LMLayout>
      <Component />
    </LMLayout>
  );
};
