import { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SystemStations,
  SystemProducts,
  fetchStations,
} from "../../modules/system";
import { AppDispatch, RootState } from "../../app/store";
import { fetchSystemConfiguration } from "../../modules/systemAttributes";

interface Props {
  tenantId: number;
  systemId: number;
}

export const System: FC<Props> = ({ tenantId, systemId }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { stations, selectedStation, status } = useSelector(
    (state: RootState) => {
      return state.system;
    }
  );

  const state = useSelector((state: RootState) => {
    return state.systemsAttributes.state;
  });

  const configuration = useSelector((state: RootState) => {
    return state.systemsAttributes.configuration;
  });

  const getSystemConfiguration = useCallback(() => {
    dispatch(fetchSystemConfiguration(tenantId));
  }, [tenantId, dispatch]);

  useEffect(() => {
    getSystemConfiguration();
  }, [getSystemConfiguration]);

  const getPallets = useCallback(() => {
    dispatch(fetchStations(systemId));
  }, [systemId, dispatch]);

  useEffect(() => {
    getPallets();
  }, [getPallets]);

  if (!state || !configuration) return null;

  return (
    <div>
      <SystemStations
        stations={stations}
        state={state}
        selectedStation={selectedStation}
        status={status}
      />
      <SystemProducts tenantId={tenantId} systemId={systemId} />
    </div>
  );
};
