import { FC, useCallback, useMemo } from "react";
import { ICollectedOrderProduct, IOrderProduct } from "../types";
import { useTranslation } from "react-i18next";
import { LMTable } from "../../../components/LMTable";
import { ColumnType } from "antd/es/table";
import styles from "./OrderProducts.module.css";

interface Props {
  products: IOrderProduct[];
  collectedProducts: ICollectedOrderProduct[];
}

export const OrderProducts: FC<Props> = ({ products, collectedProducts }) => {
  const { t } = useTranslation("order");

  const calculateQuantity = useCallback(
    (product: IOrderProduct) => {
      const collectedProductsOfSelectedProduct = collectedProducts.filter(
        (p) => p.productId === product.id
      );
      return collectedProductsOfSelectedProduct.length;
    },
    [collectedProducts]
  );

  const columns = useMemo(
    () => [
      {
        title: t("name"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: t("sku"),
        key: "sku",
        dataIndex: "sku",
      },
      {
        title: t("quantity"),
        key: "quantity",
        dataIndex: "quantity",
      },
      {
        title: t("collectedQuantity"),
        key: "collectedQuantity",
        render: (product: IOrderProduct) => calculateQuantity(product),
      },
    ],
    [t, calculateQuantity]
  ) as ColumnType<IOrderProduct>[];

  return (
    <div className={styles.container}>
      <LMTable
        columns={columns}
        dataSource={products}
        total={{ amount: products.length, title: t("totalProducts") }}
      />
    </div>
  );
};
