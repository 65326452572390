import { FC, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Tooltip } from "antd";
import { LMDropdown, LMNewInput, LMUpload } from "../../../components";
import {
  IProductFormValues,
  IProductCategory,
  IProduct,
  EProductStatus,
  EProductTemperature,
} from "../types";
import {
  approveProducts,
  createCategory,
  declineProducts,
  revertProducts,
  updateProduct,
} from "../api";
import { AppDispatch } from "../../../app/store";
import { fetchCategories, refreshList } from "../slice";
import { IconCheck, IconPlus, IconRevert, IconTimes } from "../../../assets";
import { IMetadataEntry } from "../../metadata/types";
import { LMNewButton } from "../../../components/LMNewButton";
import { ProductStatus } from "../ProductStatus";
import { LMIconButton } from "../../../components/LMIconButton";
import { Can } from "../../casl";
import { mapProductToUpdate } from "./utils";
import { PopoverConfirmation } from "../../../components/PopoverConfirmation";
import { LMCheck } from "../../../components/LMCheck";
import { Button2 } from "../../../components/Typography";
import { MetadataFields } from "../CreateProductForm/MetadataFields";
import styles from "./UpdateProductForm.module.css";

const NEW_CATEGORY_VALUE = "create-new-category";

interface Props {
  onClose: () => void;
  categories: IProductCategory[];
  metadataEntries: IMetadataEntry[];
  product: IProduct;
  onSuccess: () => void;
  onError: () => void;
  onApproveSuccess: () => void;
  onApproveError: () => void;
  onRevertSuccess: () => void;
  onRevertError: () => void;
  onDeclineSuccess: () => void;
  onDeclineError: () => void;
  editable: boolean;
}

export const UpdateProductForm: FC<Props> = ({
  onClose,
  categories,
  metadataEntries,
  product,
  onSuccess,
  onError,
  onApproveError,
  onApproveSuccess,
  onRevertError,
  onRevertSuccess,
  onDeclineError,
  onDeclineSuccess,
  editable,
}) => {
  const { t } = useTranslation("productsPortfolio");
  const { t: tLayout } = useTranslation("layout");
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm<IProductFormValues>();
  const dispatch = useDispatch<AppDispatch>();

  const getInitialValues = useCallback(() => {
    const values = {
      categoryId: product.categoryId?.toString(),
      name: product.name,
      temperature: product.temperature.toLowerCase(),
      external: product.external,
      metadata: product.metadata,
      imageId: product.imageId,
    };
    return values;
  }, [product]);

  const onFinish = async (values: IProductFormValues) => {
    setLoading(true);
    try {
      if (values.categoryName) {
        const category = await createCategory({ name: values.categoryName });
        await updateProduct(
          product.id,
          mapProductToUpdate({
            ...values,
            categoryId: category.data.id.toString(),
          })
        );
        await dispatch(fetchCategories());
      } else {
        await updateProduct(product.id, mapProductToUpdate(values));
      }
      await dispatch(refreshList());
      form.resetFields();
      setLoading(false);
      onSuccess();
      onClose();
    } catch (e: any) {
      onError();
      setLoading(false);
    }
  };

  const categoriesItems = categories.map((category) => ({
    name: category.name,
    value: category.id.toString(),
  }));

  const addCategoryItem = {
    name: t("createCategory"),
    value: NEW_CATEGORY_VALUE,
    icon: (
      <div className={styles["create-category-icon"]}>
        <IconPlus stroke="var(--color-primary-80)" />
      </div>
    ),
  };

  const category = Form.useWatch("categoryId", form);

  const handleSubmit = () => form.submit();

  const handleApprove = async () => {
    try {
      await approveProducts([product.id]);
      await dispatch(refreshList());
      onApproveSuccess();
      onClose();
    } catch {
      onApproveError();
    }
  };

  const handleRevert = async () => {
    try {
      await revertProducts([product.id]);
      await dispatch(refreshList());
      onRevertSuccess();
      onClose();
    } catch {
      onRevertError();
    }
  };

  const handleDecline = async () => {
    try {
      await declineProducts([product.id]);
      await dispatch(refreshList());
      onDeclineSuccess();
      onClose();
    } catch {
      onDeclineError();
    }
  };

  const handleImageClear = () => {
    form.setFieldValue("imageId", "");
  };

  return (
    <div className={styles.container}>
      <div className={styles.status}>
        <ProductStatus status={product.status} />
        {product.status === EProductStatus.Approved && (
          <Can I="admin" a="products">
            <div className={styles["statuses-buttons"]}>
              <PopoverConfirmation
                onConfirm={handleRevert}
                text={t("confirmation")}
              >
                <Tooltip placement="top" title={t("revertProduct")}>
                  <LMIconButton color="white">
                    <IconRevert />
                  </LMIconButton>
                </Tooltip>
              </PopoverConfirmation>
            </div>
          </Can>
        )}
        {product.status === EProductStatus.Pending && (
          <Can I="admin" a="products">
            <div className={styles["statuses-buttons"]}>
              <Tooltip placement="top" title={t("declineProduct")}>
                <LMIconButton color="white" onClick={handleDecline}>
                  <IconTimes />
                </LMIconButton>
              </Tooltip>
              <PopoverConfirmation
                onConfirm={handleApprove}
                text={t("confirmation")}
              >
                <Tooltip placement="top" title={t("approveProduct")}>
                  <LMIconButton color="white">
                    <IconCheck stroke="var(--color-green-70)" />
                  </LMIconButton>
                </Tooltip>
              </PopoverConfirmation>
            </div>
          </Can>
        )}
      </div>
      <Form
        layout={"vertical"}
        form={form}
        className={styles.form}
        initialValues={getInitialValues()}
        onValuesChange={() => setDirty(true)}
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className={styles.fields}>
          <Form.Item
            name="name"
            label={t("productName")}
            rules={[{ required: true, message: t("requiredMessage") }]}
          >
            <LMNewInput
              placeholder={t("namePlaceholder")}
              disabled={!editable}
            />
          </Form.Item>
          <Form.Item name="base64Image" label={t("productImage")}>
            <LMUpload
              imageId={form.getFieldValue("imageId")}
              onClear={handleImageClear}
            />
          </Form.Item>
          <Form.Item name="categoryId" label={t("category")}>
            <LMDropdown
              placeholder={t("dropdownPlaceholder")}
              items={[addCategoryItem, ...categoriesItems]}
              disabled={!editable}
            />
          </Form.Item>
          {category === NEW_CATEGORY_VALUE && (
            <Form.Item name="categoryName" label={t("categoryName")}>
              <LMNewInput
                placeholder={t("categoryNamePlaceholder")}
                disabled={!editable}
              />
            </Form.Item>
          )}
          <Form.Item
            name="temperature"
            label={t("temperature")}
            rules={[{ required: true, message: t("requiredMessage") }]}
          >
            <LMDropdown
              placeholder={t("dropdownPlaceholder")}
              disabled={!editable}
              items={Object.values(EProductTemperature).map((value) => ({
                name: value,
                value,
              }))}
            />
          </Form.Item>
          <Form.Item name="external" valuePropName="checked">
            <div className={styles["check-container"]}>
              <LMCheck disabled={!editable} />{" "}
              <Button2 className={styles["check-label"]}>
                {t("external")}
              </Button2>
            </div>
          </Form.Item>
        </div>
        <MetadataFields
          withEdit
          entries={metadataEntries}
          disabled={!editable}
        />
        {editable && (
          <div className={styles.buttons}>
            <LMNewButton
              color="default"
              onClick={onClose}
              className={styles.button}
            >
              {t("cancel")}
            </LMNewButton>

            <LMNewButton disabled={!dirty || loading} onClick={handleSubmit}>
              {loading ? tLayout("pleaseWait") : t("saveChanges")}
            </LMNewButton>
          </div>
        )}
      </Form>
    </div>
  );
};
