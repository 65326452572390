import { SystemsDecorator } from "../../modules/systems";
import { Indexing } from "./Indexing";

export const IndexingContainer = () => {
  return (
    <SystemsDecorator>
      {(system) => <Indexing systemId={system.id} />}
    </SystemsDecorator>
  );
};
