import { Pagination } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { LMDropdown } from "../LMDropdown";
import styles from "./LMTable.module.css";

export interface ITableTotal {
  amount?: number;
  title: string;
}

interface Props {
  total?: ITableTotal;
  onChangePageSize?: (size: string) => void;
  onChangePage?: (page: number) => void;
  pageSize?: number;
  page?: number;
}

export const LMTableFooter: FC<Props> = ({
  total,
  onChangePageSize,
  onChangePage,
  pageSize,
  page,
}) => {
  const { t } = useTranslation("layout");

  const pageSizes = [
    { name: "10", value: "10" },
    { name: "20", value: "20" },
    { name: "50", value: "50" },
    { name: "100", value: "100" },
  ];

  return (
    <div className={styles.footer}>
      {onChangePageSize && (
        <div className={styles["page-size"]}>
          <span className={styles["page-size-label"]}>{t("tableRows")}</span>
          <div className={styles["page-size-dropdown"]}>
            <LMDropdown
              defaultValue={"10"}
              items={pageSizes}
              value={pageSize?.toString()}
              onChange={onChangePageSize}
              small
            />
          </div>
        </div>
      )}
      {total && (
        <Pagination
          total={total.amount}
          pageSize={pageSize}
          current={page}
          onChange={onChangePage}
        />
      )}
    </div>
  );
};
