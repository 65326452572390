import { useTranslation } from "react-i18next";
import { IconSupportSuccess } from "../../../assets";
import { Body1, H3 } from "../../../components/Typography";
import styles from "./SupportSuccess.module.css";

export const SupportSuccess = () => {
  const { t } = useTranslation("support");
  return (
    <div className={styles.container}>
      <div className={styles["icon-container"]}>
        <div className={styles["icon-inner"]}>
          <IconSupportSuccess />
        </div>
      </div>
      <H3 className={styles.title}>{t("successTitle")}</H3>
      <Body1>{t("successDescription")}</Body1>
    </div>
  );
};
