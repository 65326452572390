import { useTranslation } from "react-i18next";
import { Col, Form, Row, Select } from "antd";
import { Button2, H3 } from "../../../components/Typography";
import { FormInstance } from "antd/lib/form/Form";
import { LMDropdown, LMNewInput } from "../../../components";
import { EMetadataTypes, IMetadataEntryFormValues } from "../types";
import { FC, useEffect } from "react";
import { useAppNotifications } from "../../../components/LMNotifications";
import { createMetadataEntry } from "../api";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { refreshList } from "../slice";
import styles from "./CreateMetadataForm.module.css";
import { LMCheck } from "../../../components/LMCheck";

interface Props {
  onClose: () => void;
  form: FormInstance<IMetadataEntryFormValues>;
}

export const CreateMetadataForm: FC<Props> = ({ onClose, form }) => {
  const { t } = useTranslation("metadata");

  const { contextHolder, success, error } = useAppNotifications();
  const dispatch = useDispatch<AppDispatch>();

  const type = Form.useWatch("type", form);

  const onFinish = async (values: IMetadataEntryFormValues) => {
    const metadataEntryToCreate = values;
    if (metadataEntryToCreate.type !== EMetadataTypes.String) {
      metadataEntryToCreate.options = undefined;
    }
    try {
      await createMetadataEntry(values);
      await dispatch(refreshList());
      form.resetFields();
      success(t("createSuccessMessage"));
      onClose();
    } catch (e: any) {
      error(t("createErrorMessage"));
    }
  };

  useEffect(() => {
    if (type !== EMetadataTypes.String) {
      form.setFieldValue("options", []);
    }
  }, [type, form]);

  return (
    <>
      {contextHolder}
      <div className={styles.container}>
        <H3>{t("addNew")}</H3>
        <Form
          layout={"vertical"}
          form={form}
          className={styles.form}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="name"
                label={t("name")}
                rules={[{ required: true, message: t("requiredMessage") }]}
              >
                <LMNewInput placeholder={t("namePlaceholder")} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="type"
                label={t("type")}
                rules={[{ required: true, message: t("requiredMessage") }]}
              >
                <LMDropdown
                  items={Object.values(EMetadataTypes).map((type) => ({
                    name: t(type),
                    value: type,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item name="options" label={t("options")}>
                <Select
                  disabled={type !== EMetadataTypes.String}
                  open={false}
                  dropdownRender={() => <></>}
                  mode="tags"
                  style={{ width: "100%" }}
                  tokenSeparators={[","]}
                  placeholder={t("optionsPlaceholder")}
                  className={styles.select}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name="required"
                label={t("required")}
                valuePropName="checked"
              >
                <div className={styles["check-container"]}>
                  <LMCheck />{" "}
                  <Button2 className={styles["check-label"]}>
                    {t("requiredLabel")}
                  </Button2>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};
