import { FC, useCallback, useEffect } from "react";
import {
  SystemHealth,
  SystemTasks,
  MachineSpeed,
  fetchSystemConfiguration,
} from "../../modules/systemAttributes";
import { ISystemDto } from "../../modules/systems";
import styles from "./Home.module.css";
import { SystemActivityGraph } from "../../modules/systemAttributes/SystemActivityGraph";
import { AcceptNewOrders } from "../../modules/tenants";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { LoaderWrapper } from "../../components";

interface Props {
  system: ISystemDto;
}

export const Home: FC<Props> = ({ system }) => {
  const dispatch = useDispatch<AppDispatch>();

  const getSystemConfiguration = useCallback(() => {
    dispatch(fetchSystemConfiguration(system.tenantId));
  }, [system.tenantId, dispatch]);

  useEffect(() => {
    getSystemConfiguration();
  }, [getSystemConfiguration]);

  const status = useSelector((state: RootState) => {
    return state.systemsAttributes.configurationStatus;
  });

  const configuration = useSelector((state: RootState) => {
    return state.systemsAttributes.configuration;
  });

  return (
    <LoaderWrapper status={status}>
      <>
        <div className={styles["grid-container"]}>
          <div className={styles["grid-item"]}>
            <SystemHealth systemId={system.id} />
          </div>
          <div className={styles["grid-item"]}>
            <AcceptNewOrders system={system} />
          </div>
          {configuration && (
            <div className={styles["grid-item"]}>
              <SystemTasks system={system} configuration={configuration} />
            </div>
          )}
          <div className={styles["grid-item"]}>
            <MachineSpeed system={system} />
          </div>
          {/* TODO: API implementation needed
          <SystemMode systemId={systemId} state={state} />
         */}
        </div>
        <div className={styles.graph}>
          <SystemActivityGraph tenantId={system.tenantId} />
        </div>
      </>
    </LoaderWrapper>
  );
};
