import { Tooltip } from "antd";
import { FC, SyntheticEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { IconClearFilters, IconSearchSmall } from "../../../assets";
import { LMNewInput } from "../../../components";
import { LMIconButton } from "../../../components/LMIconButton";
import { LMMultiSelect } from "../../../components/LMMultiSelect";
import { EKeyPrivilegeOperations, TKeyStatusFilter } from "../types";
import { AppDispatch, RootState } from "../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import styles from "./KeysFilters.module.css";
import { actions } from "../slice";

export const KeysFilters: FC = () => {
  const { t } = useTranslation("keys");
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();

  const filters = useSelector((state: RootState) => {
    return state.keys.filters;
  });

  const handleStatusesChange = (value: string[]) => {
    searchParams.set("statuses", value.join(","));
    setSearchParams(searchParams);
  };

  const handlePrivilegesChange = (value: string[]) => {
    searchParams.set("privileges", value.join(","));
    setSearchParams(searchParams);
  };

  const handleIdOrNameSearchChange = (evt: SyntheticEvent) => {
    const target = evt.target as HTMLInputElement;
    searchParams.set("search", target.value);
    setSearchParams(searchParams);
  };

  const handleClearFilters = () => {
    setSearchParams({});
  };

  useEffect(() => {
    dispatch(
      actions.setFilters({
        idOrNameFilter: searchParams.get("search") || "",
        statusFilter: searchParams.get("statuses") || "",
        privilegesFilter: searchParams.get("privileges") || "",
      })
    );
  }, [searchParams, dispatch]);

  return (
    <div className={styles.container}>
      <LMNewInput
        placeholder={t("filtersSearch")}
        onChange={handleIdOrNameSearchChange}
        icon={<IconSearchSmall />}
        value={filters.idOrNameFilter}
      />
      <div className={styles.status}>
        <LMMultiSelect
          value={
            filters.statusFilter
              ? (filters.statusFilter.split(",") as TKeyStatusFilter)
              : []
          }
          onChange={handleStatusesChange}
          placeholder={t("filterStatus")}
          items={["active", "inactive"].map((status) => ({
            name: t(status),
            value: status,
          }))}
        />
      </div>
      <div className={styles.type}>
        <LMMultiSelect
          value={
            filters.privilegesFilter
              ? (filters.privilegesFilter.split(
                  ","
                ) as EKeyPrivilegeOperations[])
              : []
          }
          onChange={handlePrivilegesChange}
          placeholder={t("filterPrivileges")}
          items={Object.keys(EKeyPrivilegeOperations).map((privilege) => ({
            name: t(privilege),
            value: privilege,
          }))}
        />
      </div>
      <Tooltip placement="top" title={t("resetFilters")}>
        <LMIconButton color="white" onClick={handleClearFilters}>
          <IconClearFilters />
        </LMIconButton>
      </Tooltip>
    </div>
  );
};
