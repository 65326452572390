import { ChangeEvent, FC, HTMLProps, ReactNode } from "react";
import { FormInstance } from "antd";
import styles from "./LMTextarea.module.css";

interface Props<T> extends HTMLProps<HTMLTextAreaElement> {
  icon?: ReactNode;
  formInstance?: FormInstance<T>;
}

export const LMTextarea: FC<Props<unknown>> = ({
  icon,
  formInstance,
  ...rest
}) => {
  const handleChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    if (rest.onChange) rest.onChange(evt);
    if (formInstance && rest.name) {
      formInstance.setFieldValue(rest.name, evt.target.value);
    }
  };

  return (
    <textarea
      {...rest}
      onChange={handleChange}
      autoComplete="off"
      className={`${styles.textarea} ${rest.className || ""}`}
    />
  );
};
