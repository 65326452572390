import { Modal } from "antd";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { actions } from "../slice";
import styles from "./ImagePreviewModal.module.css";

export const ImagePreviewModal: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const imageId = useSelector((state: RootState) => {
    return state.store.productImageIdPreview;
  });

  const handleClose = () => {
    dispatch(actions.setImageIdToPreview(undefined));
  };

  return (
    <Modal
      className={styles.modal}
      footer={null}
      open={!!imageId}
      onCancel={handleClose}
      destroyOnClose
      centered
    >
      <img
        src={`https://imagedelivery.net/aVfW2-rBW-pPqTdLRDNMyA/${imageId}/public`}
        alt="Product preview"
      />
    </Modal>
  );
};
