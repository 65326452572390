import { AxiosPromise } from 'axios';
import { IApiList } from '../../app/types';
import { axios } from '../axios';
import { IMaintenance } from '../maintenance';
import { ICreateIndexingRequestValues, IIndexingRequest, IIndexingRequestDetails } from './types';
import { getPagination } from '../../app/utils';
//import { details } from './mock';

export const getIndexingRequestsList = (systemId: number, page: number, pageSize: number): AxiosPromise<IApiList<IIndexingRequest>> => {
  //return Promise.resolve({ data: { values: [], count: 0 } }) as unknown as AxiosPromise;
  return axios.get(`/indexes?systemId=${systemId}${getPagination(page, pageSize)}`);
}

export const getIndexingRequestDetails = (requestId: number): AxiosPromise<IIndexingRequestDetails> => {
  //return Promise.resolve({ data: details }) as unknown as AxiosPromise;
  return axios.get(`/indexes/${requestId}`);
}

export const createIndexingRequest = (systemId: number, values: ICreateIndexingRequestValues): AxiosPromise<void> => {
  const ranges = values.ranges;
  if (values.maintenanceId) {
    return axios.post(`/indexes?id=${values.maintenanceId}&systemId=${systemId}`, ranges);
  }
  return axios.post(`/indexes?systemId=${systemId}`, ranges);
}

export const getMaintenancesForIndexing = (systemId: number): AxiosPromise<IApiList<IMaintenance>> => {
  return axios.get(`/maintenance?systemId=${systemId}&page=1&pageSize=100`);
}

export const deleteIndexingRequest = (requestId: number): AxiosPromise<void> => {
  return axios.delete(`/indexes/${requestId}`);
}