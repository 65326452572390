import { AxiosPromise } from "axios";
import { IMetadataEntry, IMetadataEntryFormValues } from "./types";
import { axios } from "../axios";

// const getNameFilter = (name: string) => {
//   if (!name) return "";
//   return `&name=${name}`;
// };

// const getRequiredFilter = (required: boolean) => {
//   if (!required) return "";
//   return `&required=${required}`;
// };

export const getMetadataEntries = (): AxiosPromise<IMetadataEntry[]> => {
  //const { nameFilter, requiredFilter } = filters;
  return axios.get(`/products/metadata`);
}

export const getMetadataEntry = (id: string): AxiosPromise<IMetadataEntry> => {
  return axios.get(`/products/metadata/${id}`);
}

export const createMetadataEntry = (entry: IMetadataEntryFormValues): AxiosPromise<IMetadataEntry> => {
  return axios.post(`/products/metadata`, entry);
}

export const deleteMetadataEntry = (id: string): AxiosPromise => {
  return axios.delete(`/products/metadata`, { data: [id] });
}

export const updateMetadataEntry = (id: string, entry: IMetadataEntryFormValues): AxiosPromise => {
  return axios.patch(`/products/metadata/${id}`, entry);
}