import { FC } from "react";
import { IStockTransfer } from "../types";
import { format } from "date-fns";
import styles from "./TransferDetails.module.css";
import { DATE_TIME_FORMAT } from "../../../app/constants";

interface Props {
  transfer: IStockTransfer;
}

export const TransferHeader: FC<Props> = ({ transfer }) => {
  return (
    <div className={styles.header}>
      <div className={styles["transfer-id"]}>#{transfer.id}</div>
      <div>{transfer.type}</div>
      <div className={styles.time}>
        {format(new Date(transfer.createdOn), DATE_TIME_FORMAT)}
      </div>
    </div>
  );
};
