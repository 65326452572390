import { ApiStatuses } from "../../app/types";

export enum EMetadataTypes {
  Integer = "Int",
  String = "String",
  Bool = "Bool",
  Float = "Float"
}

export interface IMetadataEntry  {
  id: string;
  name: string;
  options?: string[];
  type: EMetadataTypes;
  required: boolean;
}


export interface IMetadataFilters {
  nameFilter: string;
  requiredFilter: boolean;
}

export interface IMetadataEntryFormValues {
  name: string;
  options?: string[];
  type: EMetadataTypes;
  required: boolean;
}

export interface IMetadataSliceState {
  list: IMetadataEntry[];
  filters: IMetadataFilters;
  total: number;
  status: ApiStatuses;
  detailsStatus: ApiStatuses;
  selectedMetadataId?: number;
}