import { Divider } from "antd";
import classNames from "classnames/bind";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../app/store";
import { SystemIcon } from "../../assets";
import { LMDropdown, LoaderWrapper } from "../../components";
import { Label } from "../../components/Typography";
import styles from "./SystemsSelector.module.css";

const cx = classNames.bind(styles);

interface Props {
  collapsed: boolean;
}

export const SystemsSelector: FC<Props> = ({ collapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { t } = useTranslation("layout");

  const { status, systems, selectedSystem } = useSelector(
    (state: RootState) => {
      return state.systems;
    }
  );

  const handleChange = async (systemId: string) => {
    navigate(
      location.pathname.replace(params.systemId as string, systemId.toString()),
      { replace: true }
    );
  };

  const title = t("system") as string;
  const shortTitle = title.slice(0, 3);

  const getValue = useCallback(() => {
    if (!selectedSystem) return;
    return selectedSystem.id.toString();
  }, [selectedSystem]);

  return (
    <LoaderWrapper status={status}>
      <>
        <div className={cx({ header: true, collapsed })}>
          <Divider className={styles.divider} />
          <Label className={cx({ title: true, collapsed })}>
            {collapsed ? shortTitle : title}
          </Label>
        </div>
        <div className={styles.container}>
          <LMDropdown
            Icon={({ className }) => <SystemIcon className={className} />}
            onChange={handleChange}
            items={systems.map((system) => ({
              name: system.name,
              value: system.id.toString(),
            }))}
            value={getValue()}
            title={t("chooseSystem")}
            collapsed={collapsed}
          />
        </div>
      </>
    </LoaderWrapper>
  );
};
