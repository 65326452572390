import { addMinutes } from "date-fns";
import { ICreateMediaRequest, IRequestMediaFormValues } from "../types";

export const mapMediaRequestFromValues = (values: IRequestMediaFormValues): ICreateMediaRequest => {
  const dateIso = new Date(values.date).toISOString();
  const timeIso = new Date(values.time).toISOString();
  const dateTimeIso = `${dateIso.split("T")[0]}T${
    timeIso.split("T")[1]
  }`;
  const toDate = addMinutes(
    new Date(dateTimeIso),
    Number(values.duration)
  );
  return {
    from: dateTimeIso,
    to: toDate.toISOString(),
    mediaType: values.mediaType,
    details: values.details,
  };
}