import { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Empty } from "antd";
import { AppDispatch, RootState } from "../../../app/store";
import { IndexingDetails } from "../../indexing";
import { fetchIndexing } from "../slice";

interface Props {
  maintenanceId: number;
  systemId: number;
}

export const IndexingTab: FC<Props> = ({ maintenanceId, systemId }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { indexing } = useSelector((state: RootState) => {
    return state.maintenance;
  });

  const getIndexing = useCallback(() => {
    if (!maintenanceId) return;
    dispatch(fetchIndexing({ maintenanceId }));
  }, [dispatch, maintenanceId]);

  useEffect(() => {
    getIndexing();
  }, [getIndexing]);

  if (indexing[0]) {
    return <IndexingDetails requestId={indexing[0].id} systemId={systemId} />;
  }

  return <Empty />;
};
