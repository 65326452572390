import { FC, useCallback, useContext } from "react";
import { Drawer, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { EProductStatus, IProduct, IProductCategory } from "../types";
import { Nullable } from "../../../app/types";
import { useAppNotifications } from "../../../components/LMNotifications";
import { ProductSkus } from "../ProductSkus";
import { AbilityContext } from "../../casl";
import { sortSkus } from "../utils";
import { UpdateProductForm } from "../UpdateProductForm";
import { IMetadataEntry } from "../../metadata/types";
import { H3 } from "../../../components/Typography";
import styles from "./ProductPortfolioDetails.module.css";

interface Props {
  product: Nullable<IProduct>;
  categories: IProductCategory[];
  metadataEntries: IMetadataEntry[];
  onHide: () => void;
}

export const ProductPortfolioDetails: FC<Props> = ({
  product,
  onHide,
  categories,
  metadataEntries,
}) => {
  const { t } = useTranslation("productsPortfolio");
  const { contextHolder, success, error } = useAppNotifications();
  const ability = useContext(AbilityContext);

  const hasRightsToUpdate = useCallback(() => {
    if (ability.can("manage", "products")) {
      return true;
    }
    if (product?.status === EProductStatus.Approved) {
      return false;
    }
    return ability.can("managing", "products");
  }, [ability, product]);

  const handleUpdateSuccess = () => {
    success(t("updateProductSuccess"));
  };

  const handleUpdateError = () => {
    error(t("updateProductFail"));
  };

  const handleApproveSuccess = () => {
    success(t("approveProductSuccess"));
  };

  const handleApproveError = () => {
    error(t("approveProductFail"));
  };

  const handleRevertSuccess = () => {
    success(t("revertProductSuccess"));
  };

  const handleRevertError = () => {
    error(t("revertProductFail"));
  };

  const handleDeclineSuccess = () => {
    success(t("declineProductSuccess"));
  };

  const handleDeclineError = () => {
    error(t("declineProductFail"));
  };

  return (
    <>
      <Drawer
        title={<H3>{product?.name || ""}</H3>}
        placement="right"
        onClose={onHide}
        className={styles.drawer}
        open={!!product}
      >
        {product && (
          <Tabs
            centered
            className={styles.tabs}
            items={[
              {
                label: t("details"),
                key: "details",
                children: (
                  <UpdateProductForm
                    editable={hasRightsToUpdate()}
                    product={product}
                    categories={categories}
                    metadataEntries={metadataEntries}
                    onClose={onHide}
                    onSuccess={handleUpdateSuccess}
                    onError={handleUpdateError}
                    onApproveSuccess={handleApproveSuccess}
                    onApproveError={handleApproveError}
                    onRevertSuccess={handleRevertSuccess}
                    onRevertError={handleRevertError}
                    onDeclineError={handleDeclineError}
                    onDeclineSuccess={handleDeclineSuccess}
                  />
                ),
              },
              {
                label: t("productSkus"),
                key: "productSkus",
                children: (
                  <ProductSkus
                    editable={hasRightsToUpdate()}
                    productId={product.id}
                    skus={product.skus.slice().sort(sortSkus)}
                    onClose={onHide}
                  />
                ),
              },
            ]}
          ></Tabs>
        )}
      </Drawer>
      {contextHolder}
    </>
  );
};
