import { FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { differenceInYears } from "date-fns";
import { LMDropdown } from "../../../components";
import { LMRangePicker, RangeValue } from "../../../components/LMRangePicker";
import { ActivitiesDatesRanges } from "../types";
import { getRangeDates, getRanges } from "./utils";
import { IconInfoCircle } from "../../../assets";
import { DATE_TIME_FORMAT } from "../../../app/constants";
import styles from "./SystemActivityGraph.module.css";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

interface Props {
  onSetDates: (dates: { start: Date; end: Date }) => void;
  dates: { start: Date; end: Date };
}

export const ActivityDateFilters: FC<Props> = ({ dates, onSetDates }) => {
  const { t, i18n } = useTranslation("systemActivityGraph");
  const [range, setRange] = useState<ActivitiesDatesRanges>("today");
  const [dateInFocus, setDateInFocus] = useState<"start" | "end">();
  const [tempDates, setTempDates] = useState<RangeValue>([
    dates.start,
    dates.end,
  ]);

  const handleChangeRange = (value: string) => {
    const updatedRange = value as ActivitiesDatesRanges;
    const updatedDates = getRangeDates(updatedRange);
    onSetDates(updatedDates);
  };

  const handleChange = (values: RangeValue) => {
    if (!values) return;
    const start = values[0] as Date;
    const end = values[1] as Date;
    onSetDates({ start, end });
    setRange("custom");
  };

  const disabledDate = (current: Date) => {
    if (!tempDates) {
      return false;
    }
    const tooLate =
      dateInFocus !== "start" &&
      tempDates[0] &&
      Math.abs(differenceInYears(tempDates[0], current)) > 1;
    const tooEarly =
      dateInFocus !== "end" &&
      tempDates[1] &&
      Math.abs(differenceInYears(tempDates[1], current)) > 1;
    return !!tooLate || !!tooEarly || current > new Date();
  };

  const handleCalendarOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      setTempDates([null, null]);
      return;
    }
    if (range) {
      setTempDates([dates.start, dates.end]);
    }
  };

  const handleFocus = (evt: SyntheticEvent) => {
    const input = evt.target as HTMLInputElement;
    setDateInFocus(input.id === "date" ? "start" : "end");
  };

  return (
    <div className={styles.dates}>
      <div className={styles.range}>
        <LMRangePicker
          value={[dates.start, dates.end]}
          format={[DATE_TIME_FORMAT, DATE_TIME_FORMAT]}
          onChange={handleChange}
          disabledDate={disabledDate}
          onOpenChange={handleCalendarOpenChange}
          onCalendarChange={(val) => setTempDates(val)}
          onFocus={handleFocus}
          renderExtraFooter={() => (
            <div
              className={cx({
                "range-footer": true,
                rtl: i18n.language === "he",
              })}
              dir={`${i18n.language === "he" ? "rtl" : "ltr"}`}
            >
              <IconInfoCircle />
              {t("rangeWarn")}&nbsp;
              <span className={styles["range-footer-caption"]}>
                {t("rangeWarnAmount")}
              </span>
            </div>
          )}
        />
      </div>
      <div className={styles.select}>
        <LMDropdown
          value={range}
          onChange={handleChangeRange}
          items={getRanges(t)}
          defaultValue="today"
          small
        />
      </div>
    </div>
  );
};
