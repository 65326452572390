import { FC, useCallback, useEffect } from "react";
import classNames from "classnames/bind";
import { IOperation } from "../types";
import { Col, Row, Table } from "antd";
import { useTranslation } from "react-i18next";
import { AppDispatch, RootState } from "../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { fetchOperations, fetchProducts } from "../slice";
import { ApiStatuses } from "../../../app/types";
import { getOperationsColumns, getProductsColumns } from "./utils";
import styles from "./ReplenishmentDrawer.module.css";

const cx = classNames.bind(styles);

interface Props {
  maintenanceId: number;
}

export const OperationsAndProducts: FC<Props> = ({ maintenanceId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation("maintenance");

  const { operations, operationsStatus, products, productsStatus } =
    useSelector((state: RootState) => {
      return state.maintenance;
    });

  const getProducts = useCallback(
    (operationId: number) => {
      dispatch(fetchProducts({ operationId }));
    },
    [dispatch]
  );

  const getOperations = useCallback(() => {
    if (!maintenanceId) return;
    dispatch(fetchOperations({ maintenanceId }));
  }, [dispatch, maintenanceId]);

  useEffect(() => {
    getOperations();
  }, [getOperations]);

  const handleSelectOperation = (operation: IOperation) => {
    getProducts(operation.id);
  };

  return (
    <Row>
      <Col className={styles.col} span={products.length === 0 ? 24 : 12}>
        <Table
          dataSource={operations}
          loading={operationsStatus === ApiStatuses.loading}
          columns={getOperationsColumns(t)}
          rowKey="id"
          onRow={(row) => ({
            onClick: () => handleSelectOperation(row),
            style: { cursor: "pointer" },
          })}
          pagination={false}
        />
      </Col>
      {(products.length !== 0 || productsStatus === ApiStatuses.loading) && (
        <Col
          className={cx({
            "with-gap": productsStatus !== ApiStatuses.loading,
            col: true,
          })}
          span={12}
        >
          <Table
            loading={productsStatus === ApiStatuses.loading}
            dataSource={products}
            columns={getProductsColumns(t)}
            rowKey="productId"
            pagination={false}
          />
        </Col>
      )}
    </Row>
  );
};
