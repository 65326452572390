import { FC, useEffect } from "react";
import { Col, Drawer, Form, Row, Select } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  EMetadataTypes,
  IMetadataEntry,
  IMetadataEntryFormValues,
} from "../types";
import { Nullable } from "../../../app/types";
import { updateMetadataEntry } from "../api";
import { refreshList } from "../slice";
import { AppDispatch } from "../../../app/store";
import { LMDropdown, LMNewInput } from "../../../components";
import { LMCheck } from "../../../components/LMCheck";
import { Button2 } from "../../../components/Typography";
import { LMNewButton } from "../../../components/LMNewButton";
import styles from "./MetadataEntryDetails.module.css";

interface Props {
  entry: Nullable<IMetadataEntry>;
  onHide: () => void;
  onSuccess: () => void;
  onError: () => void;
}

export const MetadataEntryDetails: FC<Props> = ({
  entry,
  onHide,
  onSuccess,
  onError,
}) => {
  const { t } = useTranslation("metadata");

  const [form] = Form.useForm<IMetadataEntryFormValues>();
  const dispatch = useDispatch<AppDispatch>();

  const type = Form.useWatch("type", form);

  const onFinish = async (values: IMetadataEntryFormValues) => {
    if (!entry) return;
    const metadataEntryToCreate = values;
    if (metadataEntryToCreate.type !== EMetadataTypes.String) {
      metadataEntryToCreate.options = undefined;
    }
    try {
      await updateMetadataEntry(entry.id, values);
      await dispatch(refreshList());
      form.resetFields();
      onSuccess();
      onHide();
    } catch (e: any) {
      onError();
    }
  };

  useEffect(() => {
    if (type !== EMetadataTypes.String) {
      form.setFieldValue("options", []);
    }
  }, [type, form]);

  return (
    <Drawer
      title={t("editDrawerTitle")}
      placement="right"
      onClose={onHide}
      open={!!entry}
    >
      {entry && (
        <div className={styles.container}>
          <Form
            initialValues={entry}
            layout={"vertical"}
            form={form}
            className={styles.form}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item name="name" label={t("name")}>
                  <LMNewInput placeholder={t("namePlaceholder")} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="type" label={t("type")}>
                  <LMDropdown
                    items={Object.values(EMetadataTypes).map((type) => ({
                      name: t(type),
                      value: type,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item name="options" label={t("options")}>
                  <Select
                    disabled={type !== EMetadataTypes.String}
                    open={false}
                    dropdownRender={() => <></>}
                    mode="tags"
                    style={{ width: "100%" }}
                    tokenSeparators={[","]}
                    placeholder={t("optionsPlaceholder")}
                    className={styles.select}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24} className={styles["check-container"]}>
                <Form.Item
                  name="required"
                  label={t("required")}
                  valuePropName="checked"
                >
                  <LMCheck />
                </Form.Item>
                <Button2 className={styles["check-label"]}>
                  {t("requiredLabel")}
                </Button2>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <LMNewButton
                  color="default"
                  onClick={onHide}
                  className={styles.button}
                >
                  {t("cancel")}
                </LMNewButton>
              </Col>
              <Col span={12}>
                <LMNewButton type="submit">{t("save")}</LMNewButton>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </Drawer>
  );
};
