import { FC } from "react";
import { jsPDF } from "jspdf";
import QRCode from "qrcode";
import { IKey } from "../types";
import { useTranslation } from "react-i18next";
import { IconBarcode } from "../../../assets";
import { LMIconButton } from "../../../components/LMIconButton";

interface Props {
  maintenanceKey: IKey;
}

export const QrDownloadCell: FC<Props> = ({ maintenanceKey }) => {
  const { t } = useTranslation("keys");

  const handleDownload = async () => {
    const PAGE_WIDTH = 6;
    const PAGE_HEIGHT = 6;
    const TEXT_FONT_SIZE = 13;
    const qr = await QRCode.toDataURL(`MaintenanceKey-${maintenanceKey.code}`);
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "cm",
      format: [PAGE_WIDTH, PAGE_HEIGHT],
    });

    const text = t("pdfTitle");
    pdf.setFontSize(TEXT_FONT_SIZE);

    const txtWidth =
      (pdf.getStringUnitWidth(text) * TEXT_FONT_SIZE) /
      pdf.internal.scaleFactor;
    const x = (PAGE_WIDTH - txtWidth) / 2;

    pdf.text(text, x, 1);
    pdf.addImage(qr, "png", 1, 1.5, 4, 4);

    pdf.output("dataurlnewwindow");
  };

  return (
    <div>
      <LMIconButton onClick={handleDownload} color="white">
        <IconBarcode />
      </LMIconButton>
    </div>
  );
};
