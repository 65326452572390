import { SortTypes } from "./types";

export const capitalizeFirst = (client: string) =>
`${client.charAt(0).toUpperCase()}${client.slice(1)}`;

export const getClientNameFromLocation = () => {
  const ids = window.location.pathname.split("/");
  return ids[1]; 
}

export const splitCamelCase = (text: string) => {
  const splitText = text.replace(/([a-z])([A-Z])/g, '$1 $2');
  return splitText.charAt(0).toUpperCase() + splitText.slice(1);
}

// FILTERS & PAGINATION

export const getSearchFilter = (search: string, propName: string, minLength?: number) => {
  if (!search) return "";
  if (minLength && search.length < minLength) return "";
  return `&${propName}=${search}`;
}

export const getPagination = (page: number, pageSize: number, isStart = false) => {
  if (isStart) {
    return `?pageSize=${pageSize}&page=${page}`;
  }
  return `&pageSize=${pageSize}&page=${page}`;
}

export const getSortFilter = (order: SortTypes, orderBy?: string) => {
  if (order === "none") return "";
  if (order === "ascend") {
    return  orderBy ? `&orderBy=${orderBy}&sortBy=asc` : "&sortBy=asc";
  }
  return  orderBy ? `&orderBy=${orderBy}&sortBy=desc` : "&sortBy=desc";
};

export const generateId = () => Math.floor(Math.random() * 100);
