import { IUpdateProduct, IProductFormValues } from "../types";

export const mapProductToUpdate = (values: IProductFormValues): IUpdateProduct => {
  return {
    name: values.name,
    categoryId: values.categoryId,
    temperature: values.temperature,
    external: values.external,
    metadata: values.metadata,
    base64Image: values.base64Image ? values.base64Image.split(',')[1] : ""
  }
}