import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { systemsAttributes } from '../modules/systemAttributes';
import { systems } from '../modules/systems';
import { monitors } from '../modules/monitors';
import { keys } from '../modules/keys';
import { orders } from '../modules/orders';
import { transfers } from '../modules/transfers';
import { maintenance } from '../modules/maintenance';
import { auth } from '../modules/auth';
import { indexing } from '../modules/indexing';
import { system } from '../modules/system';
import { productsPortfolio } from '../modules/productsPortfolio';
import { monitoring } from '../modules/monitoring';
import { support } from '../modules/support';
import { store as _store } from '../modules/store';
import { tenants } from '../modules/tenants';
import { control } from '../modules/control';
import { metadata } from '../modules/metadata';

export const store = configureStore({
  reducer: {
    systems,
    systemsAttributes,
    monitors,
    keys,
    orders,
    transfers,
    maintenance,
    auth,
    indexing,
    system,
    productsPortfolio,
    monitoring,
    store: _store,
    tenants,
    control,
    support,
    metadata
  },
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({ serializableCheck: false })
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
