import { FC, useEffect, useState } from "react";
import { Layout } from "antd";
import classNames from "classnames/bind";
import { ArrowLeft, Logo } from "../../assets";
import { LMIconButton } from "../../components/LMIconButton";
import { Navigation } from "../../modules/navigation";
import { EnvironmentSelector } from "../../modules/auth";
import { SystemsSelector } from "../../modules/systems";
import styles from "./LMSider.module.css";
import { useLocation } from "react-router-dom";

const cx = classNames.bind(styles);

const { Sider } = Layout;

export const LMSider: FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.includes("monitoring") &&
      location.search.includes("viewType=streams")
    ) {
      setCollapsed(true);
    }
  }, [location.pathname, location.search]);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Sider
      breakpoint="lg"
      className={styles.sider}
      theme="light"
      collapsible
      collapsedWidth={66}
      width={230}
      collapsed={collapsed}
    >
      <div className={cx({ "header-container": true, collapsed })}>
        <div className={styles.header}>
          <Logo className={cx({ logo: true, collapsed })} />
          <LMIconButton onClick={handleCollapse}>
            <ArrowLeft className={cx({ "collapse-icon": true, collapsed })} />
          </LMIconButton>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles["select-container"]}>
          <EnvironmentSelector collapsed={collapsed} />
        </div>
        <Navigation collapsed={collapsed} isOrganization />
        <div className={styles["select-container"]}>
          <SystemsSelector collapsed={collapsed} />
        </div>
        <Navigation collapsed={collapsed} />
      </div>
    </Sider>
  );
};
