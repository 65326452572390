import { Modal } from "antd";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { actions } from "../slice";
import { SupportForm } from "../SupportForm";
import { LMNewButton } from "../../../components/LMNewButton";
import { Body1 } from "../../../components/Typography";
import { SupportSuccess } from "../SupportSuccess";
import styles from "./SupportModal.module.css";

export const SupportModal = () => {
  const { t } = useTranslation("support");
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const open = useSelector((state: RootState) => {
    return state.support.isDrawerOpen;
  });

  const handleSuccess = () => setSubmitted(true);

  const handleCancel = useCallback(() => {
    dispatch(actions.setDrawerOpen(false));
    setSubmitted(false);
  }, [dispatch]);

  return (
    <Modal
      title={submitted ? "" : t("support")}
      open={open}
      footer={null}
      onCancel={handleCancel}
      width={submitted ? 400 : 560}
      className={styles.container}
    >
      <div className={styles.content}>
        {submitted ? (
          <>
            <SupportSuccess />
            <LMNewButton color="default" onClick={handleCancel}>
              {t("close")}
            </LMNewButton>
          </>
        ) : (
          <>
            <Body1>{t("modalDescription")}</Body1>
            <SupportForm onSuccess={handleSuccess} onCancel={handleCancel} />
          </>
        )}
      </div>
    </Modal>
  );
};
