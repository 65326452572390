import { Form } from "antd";
import { LMNewInput, Loader, LMTextarea } from "../../../components";
import { useTranslation } from "react-i18next";
import { FC, useCallback, useEffect, useState } from "react";
import { IdToken, useAuth0 } from "@auth0/auth0-react";
import { LMNewButton } from "../../../components/LMNewButton";
import styles from "./SupportForm.module.css";

interface Props {
  onSuccess: () => void;
  onCancel?: () => void;
}

export const SupportForm: FC<Props> = ({ onSuccess, onCancel }) => {
  const { getIdTokenClaims } = useAuth0();
  const [user, setUser] = useState<IdToken>();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("support");
  const handleFinish = async () => {
    setLoading(true);
    setTimeout(() => {
      onSuccess();
    }, 1000);
  };

  const fetchClaims = useCallback(async () => {
    getIdTokenClaims().then((idTokenClaims) => {
      if (!idTokenClaims) {
        return;
      }
      setUser(idTokenClaims);
    });
  }, [getIdTokenClaims]);

  useEffect(() => {
    fetchClaims();
  }, [fetchClaims]);

  if (!user) return null;

  return (
    <div className={styles.container}>
      {loading && (
        <div className={styles["loading-container"]}>
          <Loader />
        </div>
      )}
      <form
        className={styles.form}
        onSubmit={handleFinish}
        action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
        target="form-iframe"
      >
        <input type="hidden" name="orgid" value="00D0900000DXXU2" />
        <input name="email" type="hidden" value={user.email} />
        <input
          type="hidden"
          name="retURL"
          value="https://app.1mrobotics.com/"
        />
        <Form.Item name="subject" label={t("subject")} labelCol={{ span: 24 }}>
          <LMNewInput
            name="subject"
            placeholder={t("subjectPlaceholder")}
            required
          />
        </Form.Item>
        <Form.Item
          name="description"
          label={t("description")}
          labelCol={{ span: 24 }}
        >
          <LMTextarea
            name="description"
            className={styles.textarea}
            placeholder={t("descriptionPlaceholder")}
            required
          />
        </Form.Item>
        <div className={onCancel ? styles.buttons : styles.button}>
          {onCancel && (
            <LMNewButton color="default" onClick={onCancel}>
              {t("cancel")}
            </LMNewButton>
          )}
          <LMNewButton type="submit">{t("submit")}</LMNewButton>
        </div>
      </form>
    </div>
  );
};
