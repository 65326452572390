import { FC } from "react";
import { IOrderPart } from "../types";
import { OrderStatus } from "../OrderStatus";
import styles from "./OrderBoardItem.module.css";

interface Props {
  parts: IOrderPart[];
}

export const OrderBoardParts: FC<Props> = ({ parts }) => {
  return (
    <ul className={styles["parts-container"]}>
      {parts.map((part) => (
        <li key={part.id} className={styles["parts-item"]}>
          {part.id} <OrderStatus status={part.status} />
        </li>
      ))}
    </ul>
  );
};
