import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStoreProduct, IStoreSliceState } from './types';
import { ApiStatuses, SortTypes } from '../../app/types';
import { getProducts } from './api';

export const initialState: IStoreSliceState = {
  list: [],
  status: ApiStatuses.initial,
  searchString: "",
  cartProducts: [],
  nameFilterSort: "none",
};

export const fetchList = createAsyncThunk(
  'store/fetchList',
  async (tenantId: number) => {
    const response = await getProducts(tenantId);
    return response.data;
  }
);

const slice = createSlice({
  name: "store",
  initialState,
  reducers: {
    setFilters(state: IStoreSliceState, action: PayloadAction<{ searchString: string }>) {
      state.searchString = action.payload.searchString;
    },
    setNameSort(state: IStoreSliceState, action: PayloadAction<SortTypes>) {
      state.nameFilterSort = action.payload;
    },
    updateCartProducts(state: IStoreSliceState, action: PayloadAction<IStoreProduct[]>) {
      state.cartProducts = action.payload;
    },
    updateProducts(state: IStoreSliceState, action: PayloadAction<IStoreProduct[]>) {
      state.list = action.payload;
    },
    setImageIdToPreview(state: IStoreSliceState, action: PayloadAction<string | undefined>) {
      state.productImageIdPreview = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchList
      .addCase(fetchList.pending, (state) => {
        state.status = ApiStatuses.loading;
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        state.status = ApiStatuses.success;
        state.list = action.payload.filter(p => p.availableQuantity > 0).map(p => ({ ...p, quantity: 0 }));
      })
      .addCase(fetchList.rejected, (state) => {
        state.status = ApiStatuses.fail;
      })
  },
});

export const store = slice.reducer;
export const actions = slice.actions;