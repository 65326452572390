import { ChangeEvent, FC, ReactNode, useState } from "react";
import { LMInput } from "../LMInput";
import { IconSearch } from "../../assets";
import styles from "./PageHeader.module.css";
import { useSearchParams } from "react-router-dom";
import { searchUtils } from "./utils";

interface Props {
  title: string | ReactNode;
  searchFor?: "orders" | "transfers";
  searchPlaceholder?: string;
  actionComponent?: ReactNode;
}

export const PageHeader: FC<Props> = ({
  title,
  searchFor,
  searchPlaceholder,
  actionComponent,
}) => {
  const [search, setSearch] = useState("");
  const [, setSearchParams] = useSearchParams();

  const handleSetSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as string;
    setSearch(value);
  };

  const isNumeric = (value: string) => {
    return /^-?\d+$/.test(value);
  };

  const handleEnter = () => {
    if (!searchFor) return;
    const utils = searchUtils[searchFor];
    if (!isNumeric(search)) {
      return;
    }
    setSearchParams({ [utils.param]: search });
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{title}</h1>
      {actionComponent && actionComponent}
      {searchFor && (
        <LMInput
          value={search}
          size={"large"}
          style={{ maxWidth: 356 }}
          placeholder={searchPlaceholder}
          suffix={<IconSearch />}
          disabled={false}
          onChange={handleSetSearch}
          onPressEnter={handleEnter}
        />
      )}
    </div>
  );
};
