import { FC } from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { Tag } from "antd";
import { IndexingRequestStatus } from "../types";
import styles from "./IndexingRequestStatusTag.module.css";

const cx = classNames.bind(styles);

interface Props {
  status: IndexingRequestStatus;
}

export const IndexingRequestStatusTag: FC<Props> = ({ status }) => {
  const { t } = useTranslation("indexing");

  return (
    <Tag
      className={cx({
        tag: true,
        green:
          status === IndexingRequestStatus.Completed ||
          status === IndexingRequestStatus.Approved,
        yellow: status === IndexingRequestStatus.Pending,
        red: status === IndexingRequestStatus.Failed,
      })}
    >
      {t(status.toLowerCase())}
    </Tag>
  );
};
