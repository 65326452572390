import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { IconHealthy, IconUnhealthy } from "../../../assets";
import { CardContainer } from "../../../components/CardContainer";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { IconWithBackground } from "../../../components/IconWithBackground";
import { LMNewButton } from "../../../components/LMNewButton";
import { updateHealth } from "../slice";
import { SystemHealthConfirmationContent } from "./SystemHealthConfirmationContent";
import { useAppNotifications } from "../../../components/LMNotifications";

interface Props {
  systemId: number;
}

export const SystemHealth: FC<Props> = ({ systemId }) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { contextHolder, success, error } = useAppNotifications();
  const { t } = useTranslation("healthy");
  const dispatch = useDispatch<AppDispatch>();

  const state = useSelector((state: RootState) => {
    return state.systemsAttributes.state;
  });

  const handleConfirm = async () => {
    try {
      await dispatch(updateHealth({ systemId, status: true })).unwrap();
      success(t("healthUpdateSuccess"));
    } catch {
      error(t("healthUpdateError"));
    }
    handleCloseConfirmationModal();
  };

  const handleHealthUpdate = () => setConfirmModalOpen(true);
  const handleCloseConfirmationModal = () => setConfirmModalOpen(false);

  if (!state) return null;

  return (
    <>
      {contextHolder}
      <CardContainer
        icon={
          <IconWithBackground
            icon={state.health ? <IconHealthy /> : <IconUnhealthy />}
            color={state.health ? "green" : "red"}
          />
        }
        title={state.health ? t("titleActive") : t("titleInactive")}
        subtitle={state.health ? t("subtitleActive") : t("subtitleInactive")}
      >
        <LMNewButton
          color="default"
          disabled={state.health}
          onClick={handleHealthUpdate}
          small
        >
          {state.health ? t("actionActive") : t("actionInactive")}
        </LMNewButton>
      </CardContainer>
      <ConfirmationModal
        content={<SystemHealthConfirmationContent />}
        confirmText={t("confirm")}
        open={confirmModalOpen}
        onConfirm={handleConfirm}
        onCancel={handleCloseConfirmationModal}
      />
    </>
  );
};
