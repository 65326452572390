import { Tooltip } from "antd";
import { SegmentedValue } from "antd/es/segmented";
import { FC, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import {
  IconClearFilters,
  IconLibrary,
  IconSearchSmall,
  IconStream,
} from "../../../assets";
import { LMNewInput } from "../../../components";
import { LMIconButton } from "../../../components/LMIconButton";
import { LMMultiSelect } from "../../../components/LMMultiSelect";
import { LMNewRadio } from "../../indexing/LMNewRadio";
import { LMRangePicker } from "../../../components/LMRangePicker";
import {
  EMediaType,
  EMediaStatus,
  EMonitoringViewType,
  IMediaItemsListFilters,
} from "../types";
import { RangeValue } from "../../../components/LMRangePicker";
import { H2 } from "../../../components/Typography";
import styles from "./MediaItemsFilters.module.css";

interface Props {
  filters: IMediaItemsListFilters;
}

export const MediaItemsFilters: FC<Props> = ({ filters }) => {
  const { t } = useTranslation("monitoring");
  const [searchParams, setSearchParams] = useSearchParams();

  const handleStatusesChange = (value: string[]) => {
    searchParams.set("statuses", value.join(","));
    setSearchParams(searchParams);
  };

  const handleTypesChange = (value: string[]) => {
    searchParams.set("types", value.join(","));
    setSearchParams(searchParams);
  };

  const handleNameSearchChange = (evt: SyntheticEvent) => {
    const target = evt.target as HTMLInputElement;
    searchParams.set("user", target.value);
    setSearchParams(searchParams);
  };

  const handleDateChange = (values: RangeValue) => {
    if (!values) return;
    const start = values[0] as Date;
    const end = values[1] as Date;
    searchParams.set("start", start.toString());
    searchParams.set("end", end.toString());
    setSearchParams(searchParams);
  };

  const handleClearFilters = () => {
    setSearchParams({ viewType: filters.viewType });
  };

  const handleSetViewType = (value: SegmentedValue) => {
    searchParams.set("viewType", value as string);
    setSearchParams(searchParams);
  };

  return (
    <div className={styles.container}>
      {filters.viewType === EMonitoringViewType.library ? (
        <>
          <LMNewInput
            placeholder={t("userPlaceholder")}
            onChange={handleNameSearchChange}
            icon={<IconSearchSmall />}
            value={filters.userFilter}
          />
          <LMRangePicker
            value={[
              filters.dateFilter.start || null,
              filters.dateFilter.end || null,
            ]}
            size="large"
            onChange={handleDateChange}
          />
          <div className={styles.status}>
            <LMMultiSelect
              value={filters.statusFilter}
              onChange={handleStatusesChange}
              placeholder={t("statusPlaceholder")}
              items={Object.keys(EMediaStatus).map((status) => ({
                name: t(status),
                value: status,
              }))}
            />
          </div>
          <div className={styles.type}>
            <LMMultiSelect
              value={filters.typeFilter}
              onChange={handleTypesChange}
              placeholder={t("typePlaceholder")}
              items={Object.keys(EMediaType).map((type) => ({
                name: t(type),
                value: type,
              }))}
            />
          </div>
          <Tooltip placement="top" title={t("resetFilters")}>
            <LMIconButton color="white" onClick={handleClearFilters}>
              <IconClearFilters />
            </LMIconButton>
          </Tooltip>
        </>
      ) : (
        <H2>{t("streams")}</H2>
      )}
      <div className={styles["switch-container"]}>
        <LMNewRadio
          onChange={handleSetViewType}
          size="large"
          options={[
            {
              label: (
                <div className={styles["switch-item-container"]}>
                  <IconLibrary />
                  {t("library")}
                </div>
              ),
              value: EMonitoringViewType.library,
            },
            {
              label: (
                <div className={styles["switch-item-container"]}>
                  <IconStream />
                  {t("streams")}
                </div>
              ),
              value: EMonitoringViewType.streams,
            },
          ]}
          value={filters.viewType}
        />
      </div>
    </div>
  );
};
