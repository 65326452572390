import { FC, useCallback, useEffect } from "react";
import { Drawer } from "antd";
import { SystemStationDetailsHeader } from "./SystemStationDetailsHeader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { StationProducts } from "../StationProducts";
import { fetchStationProducts } from "../slice";
import { EPalletType } from "../types";

interface Props {
  onHide: () => void;
  systemId: number;
}

export const SystemStationDetails: FC<Props> = ({ onHide, systemId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { selectedStation, productsStatus, stationProducts } = useSelector(
    (state: RootState) => {
      return state.system;
    }
  );

  const fetchProducts = useCallback(() => {
    if (!selectedStation || !selectedStation.palletId) return;
    dispatch(
      fetchStationProducts({ systemId, palletId: selectedStation.palletId })
    );
  }, [selectedStation, systemId, dispatch]);

  useEffect(() => {
    if (!selectedStation) return;
    fetchProducts();
  }, [fetchProducts, selectedStation, selectedStation?.palletId]);

  return (
    <>
      <Drawer
        title={
          selectedStation ? (
            <SystemStationDetailsHeader station={selectedStation} />
          ) : (
            <></>
          )
        }
        width="50%"
        placement="right"
        onClose={onHide}
        open={!!(selectedStation && selectedStation.type === EPalletType.Hive)}
      >
        <StationProducts products={stationProducts} status={productsStatus} />
      </Drawer>
    </>
  );
};
