import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { useSearchParams } from "react-router-dom";
import {
  ProductCartDrawer,
  fetchList,
  storeActions,
} from "../../modules/store";
import { StoreFilters } from "../../modules/store/StoreFilters";
import { ProductsStoreList } from "../../modules/store/ProductsStoreList";
import styles from "./Store.module.css";
import { useAppNotifications } from "../../components/LMNotifications";

interface Props {
  tenantId: number;
}

export const Store: FC<Props> = ({ tenantId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation("store");
  const { contextHolder, success, error } = useAppNotifications();
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [searchParams] = useSearchParams();

  const { status, list, searchString, cartProducts } = useSelector(
    (state: RootState) => {
      return state.store;
    }
  );

  const getProducts = useCallback(() => {
    dispatch(fetchList(tenantId));
  }, [dispatch, tenantId]);

  useEffect(() => {
    dispatch(
      storeActions.setFilters({
        searchString: searchParams.get("searchString") || "",
      })
    );
  }, [searchParams, dispatch]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  const showCartProducts = () => setIsCartOpen(true);
  const hideCartProducts = () => setIsCartOpen(false);

  const handlePlaceOrderSuccess = () => success(t("placeOrderSuccess"));
  const handlePlaceOrderError = () => error(t("placeOrderError"));

  return (
    <div className={styles.container}>
      {contextHolder}
      <div className={styles.filters}>
        <StoreFilters
          searchString={searchString}
          cartProducts={cartProducts}
          onShowCart={showCartProducts}
        />
      </div>
      <div className={styles.list}>
        <ProductsStoreList
          searchString={searchString}
          list={list}
          status={status}
          cartProducts={cartProducts}
        />
      </div>
      <ProductCartDrawer
        open={isCartOpen}
        onClose={hideCartProducts}
        tenantId={tenantId}
        onSuccess={handlePlaceOrderSuccess}
        onError={handlePlaceOrderError}
      />
    </div>
  );
};
