import { FC, useCallback, useEffect } from "react";
import { TransferHeader } from "./TransferHeader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { fetchTransfer } from "../slice";
import styles from "./TransferDetails.module.css";
import { LoaderWrapper } from "../../../components";
import { TransferProducts } from "../TransferProducts";

interface Props {
  transferId: string;
}

export const TransferDetails: FC<Props> = ({ transferId }) => {
  const dispatch = useDispatch<AppDispatch>();

  const getTransfer = useCallback(() => {
    dispatch(fetchTransfer({ transferId }));
  }, [transferId, dispatch]);

  useEffect(() => {
    getTransfer();
  }, [getTransfer]);

  const { selectedTransfer, detailsStatus } = useSelector(
    (state: RootState) => {
      return state.transfers;
    }
  );

  return (
    <LoaderWrapper status={detailsStatus}>
      <div className={styles.container}>
        {selectedTransfer && (
          <>
            <TransferHeader transfer={selectedTransfer} />
            <TransferProducts products={selectedTransfer.products} />
          </>
        )}
      </div>
    </LoaderWrapper>
  );
};
