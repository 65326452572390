import { FC, useEffect, useRef, useState } from "react";
import { Popover } from "antd";
import { useTranslation } from "react-i18next";
import { LMNewButton } from "../LMNewButton";
import styles from "./PopoverConfirmation.module.css";

interface Props {
  children: JSX.Element;
  onConfirm: () => void;
  text?: string;
  cancelText?: string;
  confirmText?: string;
}

export const PopoverConfirmation: FC<Props> = ({
  children,
  onConfirm,
  text,
  cancelText,
  confirmText,
}) => {
  const { t } = useTranslation("layout");
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm();
    setOpen(false);
  };

  const handleOpen = () => setOpen(true);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLDivElement;
      if (wrapperRef.current && !wrapperRef.current.contains(target)) {
        setOpen(false);
      }
    };
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const content = (
    <div ref={wrapperRef} className={styles.container}>
      <p className={styles.title}>{text || t("sure")}</p>
      <p className={styles.buttons}>
        <LMNewButton color="default" onClick={handleClose}>
          {cancelText || t("cancel")}
        </LMNewButton>
        <LMNewButton onClick={handleConfirm}>
          {confirmText || t("confirm")}
        </LMNewButton>
      </p>
    </div>
  );

  return (
    <Popover open={open} trigger="click" content={content}>
      <span onClick={handleOpen}>{children}</span>
    </Popover>
  );
};
