import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EControlMode, IControlSliceState } from './types';
import { ApiStatuses } from '../../app/types';
import { getControlCommands, getControlMode } from './api';
import { MessageOperation } from '../signalR';

export const initialState: IControlSliceState = {
  status: ApiStatuses.initial,
  searchString: "",
  commands: []
};

export const fetchControlMode = createAsyncThunk(
  'control/fetchControlMode',
  async (serialNumber: string) => {
    const response = await getControlMode(serialNumber);
    return response.data;
  }
);

export const fetchControlCommands = createAsyncThunk(
  'control/fetchControlCommands',
  async (serialNumber: string) => {
    const response = await getControlCommands(serialNumber);
    return response.data;
  }
);

const slice = createSlice({
  name: "control",
  initialState,
  reducers: {
    setFilters(state: IControlSliceState, action: PayloadAction<{ searchString: string }>) {
      state.searchString = action.payload.searchString;
    },
    updateMode(state: IControlSliceState, action: PayloadAction<{ operation: MessageOperation, mode: string }>) {
      if (action.payload.operation === MessageOperation.Updates) {
        state.mode = action.payload.mode as EControlMode;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchControlMode
      .addCase(fetchControlMode.pending, (state) => {
        state.status = ApiStatuses.loading;
      })
      .addCase(fetchControlMode.fulfilled, (state, action) => {
        state.status = ApiStatuses.success;
        state.mode = action.payload;
      })
      .addCase(fetchControlMode.rejected, (state) => {
        state.status = ApiStatuses.fail;
      })
      // fetchControlCommands
      .addCase(fetchControlCommands.fulfilled, (state, action) => {
        state.commands = action.payload;
      })
  },
});

export const control = slice.reducer;
export const actions = slice.actions;