import { splitCamelCase } from "../../app/utils";
import { EOrderStatus, IOrder, IOrderPartProduct, IOrderPartProductWithMetadata, IOrderWithDetailsExpanded } from "./types";

export const getColorByStatus = (status?: EOrderStatus) => {
  if (!status) {
    return "gray"
  }
  if (status === EOrderStatus.Canceled) {
    return "yellow";
  }
  if (status === EOrderStatus.Failed || status === EOrderStatus.DeliveryFailed ||
    status === EOrderStatus.PackingFailed || status === EOrderStatus.ProcessingFailed) {
    return "red";
  }
  if (status === EOrderStatus.Collected || status === EOrderStatus.CollectedManually ||
    status === EOrderStatus.Shipped) {
    return "green";
  }
  return "violet";
}

export const getOrderPartId = (order: IOrder) => {
  if (!order.parts) return undefined;
  const part = order.parts[order.parts.length - 1];
  return part ? part.id : undefined;
}


export const extendByMetadata = (products: IOrderPartProduct[]) => {
  const metadataList: { title: string, value: string }[] = [];
  const productsWithMetadata: IOrderPartProductWithMetadata[] = [];
  products.forEach(product => {
    if (!product.metadata) {
      productsWithMetadata.push(product as IOrderPartProductWithMetadata);
      return;
    }
    let currentProductMetadata = {};
    Object.keys(product.metadata).forEach(key => {
      currentProductMetadata = {
        ...currentProductMetadata,
        [key]: product.metadata[key]
      }
      Object.keys(product.metadata).forEach(metadataKey => {
        if (!product.metadata[metadataKey] || metadataList.some(metadataItem => metadataItem.value === metadataKey)) return;
        metadataList.push({ title: splitCamelCase(metadataKey), value: metadataKey });
      })
    });
    const productWithMetadata = {
      ...product,
      ...currentProductMetadata
    } as IOrderPartProductWithMetadata;
    productsWithMetadata.push(productWithMetadata);
  })
  return {
    metadataList,
    productsWithMetadata
  }
}

export const extendByDetails = (orders: IOrder[]) => {
  const detailsList: { title: string, value: string }[] = [];
  const ordersWithDetailsExpanded: IOrderWithDetailsExpanded[] = [];
  orders.forEach(order => {
    if (!order.details) {
      ordersWithDetailsExpanded.push(order as IOrderWithDetailsExpanded);
      return;
    }
    const details = order.details as IOrderWithDetailsExpanded;
    let currentOrderWithDetailsExpanded = {};
    Object.keys(details).forEach(key => {
      currentOrderWithDetailsExpanded = {
        ...currentOrderWithDetailsExpanded,
        [key]: details[key]
      }
      Object.keys(details).forEach(detailsKey => {
        if (!details[detailsKey] || detailsList.some(detailsItem => detailsItem.value === detailsKey)) return;
        detailsList.push({ title: splitCamelCase(detailsKey), value: detailsKey });
      })
    });
    const productWithMetadata = {
      ...order,
      ...currentOrderWithDetailsExpanded
    } as IOrderWithDetailsExpanded;
    ordersWithDetailsExpanded.push(productWithMetadata);
  })
  return {
    detailsList,
    ordersWithDetailsExpanded
  }
}


export const sortOrderPartProducts = (a: IOrderPartProduct, b: IOrderPartProduct) => {
  const aPackId = a.packId || "";
  const bPackId = b.packId || "";
  if (aPackId < bPackId) return 1;
  if (aPackId > bPackId) return -1;
  return 0;
};

export const getOrderStatusesForFilter = () => {
  return [
    EOrderStatus.Pending,
    EOrderStatus.Queued,
    EOrderStatus.InProgress,
    EOrderStatus.Packed,
    EOrderStatus.Collected,
    EOrderStatus.Failed,
    EOrderStatus.Canceled,
    EOrderStatus.OutOfStock,
    EOrderStatus.PartialCollected
  ]
}