import { FC } from "react";
import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";
import classNames from "classnames/bind";
import styles from "./LMTimePicker.module.css";
import generatePicker, {
  PickerBaseProps,
} from "antd/es/date-picker/generatePicker";

const cx = classNames.bind(styles);

export const Picker = generatePicker<Date>(dateFnsGenerateConfig);

interface Props extends Omit<PickerBaseProps<Date>, "picker"> {
  size?: "small" | "large";
  onChange?: (value: Date | null) => void;
}

const { TimePicker } = Picker;

export const LMTimePicker: FC<Props> = ({
  className,
  size = "small",
  ...rest
}) => {
  return (
    <TimePicker
      {...rest}
      className={`${cx({ picker: true, [size]: true })} ${className || ""}`}
      format={"HH:mm:ss"}
    />
  );
};
