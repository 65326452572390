import { FC, useCallback, useEffect, useState } from "react";
import hlsjs from "hls.js";
import { MonitoringStreamsFilters } from "./MonitoringStreamsFilters";
import { fetchStreams, actions } from "../slice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { MediaStream } from "../MediaStream";
import { Alert } from "antd";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import styles from "./MonitoringStreams.module.css";

interface Props {
  systemId: number;
}

export const MonitoringStreams: FC<Props> = ({ systemId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [filtersCollapsed, setFiltersCollapsed] = useState(false);
  const { t } = useTranslation("monitoring");
  const [searchParams] = useSearchParams();
  const { selectedCameras, streams } = useSelector((state: RootState) => {
    return state.monitoring;
  });

  const getStreams = useCallback(
    () => dispatch(fetchStreams({ systemId })),
    [systemId, dispatch]
  );

  useEffect(() => {
    getStreams();
  }, [getStreams]);

  useEffect(() => {
    const camerasString = searchParams.get("cameras") || "";
    if (camerasString) {
      const cameras = camerasString.split(",").sort();
      dispatch(actions.setCameras(cameras));
    }
  }, [searchParams, dispatch]);

  return (
    <div className={styles.container}>
      {streams && (
        <>
          <div className={styles.streams}>
            {hlsjs.isSupported() ? (
              selectedCameras.map((camera) => (
                <MediaStream
                  key={camera}
                  camera={camera}
                  stream={streams[camera]}
                />
              ))
            ) : (
              <Alert message={t("hlsNotSupported")} type="error" />
            )}
          </div>
          <MonitoringStreamsFilters
            collapsed={filtersCollapsed}
            onChangeCollapsed={setFiltersCollapsed}
            streams={streams}
            selectedCameras={selectedCameras}
          />
        </>
      )}
    </div>
  );
};
