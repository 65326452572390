import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { LMNewButton } from "../../../components/LMNewButton";
import { FC, createRef, useCallback, useEffect, useState } from "react";
import { RequestMediaForm } from "./RequestMediaForm";
import { IRequestMediaFormValues } from "../types";
import { createMediaRequests } from "../api";
import { mapMediaRequestFromValues } from "./utils";
import { IdToken, useAuth0 } from "@auth0/auth0-react";
import styles from "./RequestMediaModal.module.css";
import { IconPlus } from "../../../assets";

interface Props {
  open: boolean;
  systemId: number;
  onCancel: () => void;
  onSuccess: (amount: number) => void;
  onError: () => void;
}

export const RequestMediaModal: FC<Props> = ({
  open,
  onCancel,
  systemId,
  onError,
  onSuccess,
}) => {
  const { t } = useTranslation("monitoring");
  const { getIdTokenClaims } = useAuth0();
  const [user, setUser] = useState<IdToken>();
  const formRef = {
    ref: createRef<{ submit: () => void }>(),
    id: Math.floor(Math.random() * 100),
  };
  const [formRefs, setFormRefs] = useState([formRef]);
  const [, setForms] = useState<Record<number, IRequestMediaFormValues>>({});

  const handleAddMediaForm = () => {
    const newRef = {
      ref: createRef<{ submit: () => void }>(),
      id: Math.floor(Math.random() * 100),
    };
    setFormRefs([...formRefs, newRef]);
  };

  const handleConfirm = () => {
    formRefs.forEach((ref) => ref.ref.current?.submit());
  };

  const fetchClaims = useCallback(async () => {
    getIdTokenClaims().then((idTokenClaims) => {
      setUser(idTokenClaims);
    });
  }, [getIdTokenClaims]);

  useEffect(() => {
    fetchClaims();
  }, [fetchClaims]);

  const handleFormSubmit = async (
    index: number,
    values: IRequestMediaFormValues
  ) => {
    let updatedForms: Record<number, IRequestMediaFormValues> = {};
    setForms((oldForms) => {
      updatedForms = { ...oldForms, [index]: values };
      return updatedForms;
    });
    setTimeout(async () => {
      if (Object.keys(updatedForms).length === formRefs.length) {
        const requests = Object.keys(updatedForms).map((index) => {
          const formKey = Number(index);
          const values = updatedForms[formKey] as IRequestMediaFormValues;
          return mapMediaRequestFromValues(values);
        });
        try {
          await createMediaRequests({
            systemId,
            user: user?.name || "",
            requests,
          });
          onSuccess(requests.length);
        } catch (error) {
          console.error(error);
          onError();
        }
      }
    });
  };

  const handleRemoveForm = (id: number) => {
    const updatedFormRefs = formRefs.filter((ref, i) => ref.id !== id);
    setFormRefs(updatedFormRefs);
  };

  useEffect(() => {
    if (open) return;
    const initialRef = {
      ref: createRef<{ submit: () => void }>(),
      id: Math.floor(Math.random() * 100),
    };
    setFormRefs([initialRef]);
    setForms({});
  }, [open]);

  return (
    <Modal
      open={open}
      footer={null}
      destroyOnClose
      onCancel={onCancel}
      width={640}
      className={styles.container}
      title={t("requestTitle")}
    >
      <div className={styles.content}>
        {formRefs.map((ref, index) => (
          <RequestMediaForm
            isRemovable={formRefs.length > 1}
            key={ref.id}
            refId={ref.id}
            index={index}
            ref={ref.ref}
            onSubmit={handleFormSubmit}
            onRemove={handleRemoveForm}
          />
        ))}
      </div>

      <div className={styles.buttons}>
        <LMNewButton
          color="transparent"
          onClick={handleAddMediaForm}
          className={styles["add-button"]}
          iconRight={<IconPlus stroke="var(--color-primary-80)" />}
        >
          {t("addMedia")}
        </LMNewButton>
        <LMNewButton
          color="default"
          onClick={onCancel}
          className={styles.button}
        >
          {t("cancel")}
        </LMNewButton>
        <LMNewButton onClick={handleConfirm} className={styles.button}>
          {t("request")}
        </LMNewButton>
      </div>
    </Modal>
  );
};
