import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProductsPortfolioSliceState, IProductsListFilters } from './types';
import { ApiStatuses, SortTypes } from '../../app/types';
import { getProduct, getProductCategories, getProductsPortfolio } from './api';
import { RootState } from '../../app/store';

export const initialState: IProductsPortfolioSliceState = {
  list: [],
  status: ApiStatuses.initial,
  filters: {
    searchString: "",
    categoriesFilter: [],
    statusFilter: "",
    disabledSystemsFilter: false,
    page: 1,
    pageSize: 10,
    total: 0,
    nameFilterSort: "none",
  },
  selectedProduct: null,
  categories: [],
};

export const fetchList = createAsyncThunk(
  'productsPortfolio/fetchList',
  async (filters: IProductsListFilters) => {
    const response = await getProductsPortfolio(filters);
    return response.data;
  }
);

export const refreshList = createAsyncThunk(
  'productsPortfolio/refreshList',
  async (args, { getState }) => {
    const state = getState() as RootState;
    const { filters } = state.productsPortfolio;
    const response = await getProductsPortfolio(filters);
    return response.data;
  }
);

export const refreshSelectedProduct = createAsyncThunk(
  'productsPortfolio/refreshSelectedProduct',
  async (args, { getState }) => {
    const state = getState() as RootState;
    const { selectedProduct } = state.productsPortfolio;
    if (!selectedProduct) return selectedProduct;
    const response = await getProduct(selectedProduct.id);
    return response.data;
  }
);

export const fetchCategories = createAsyncThunk(
  'productsPortfolio/fetchCategories',
  async () => {
    const response = await getProductCategories();
    return response.data;
  }
);

export const fetchProductDetails = createAsyncThunk(
  'productsPortfolio/fetchProductDetails',
  async (productId: number) => {
    const response = await getProduct(productId);
    return response.data;
  }
);

const slice = createSlice({
  name: "productsPortfolio",
  initialState,
  reducers: {
    setPage(state: IProductsPortfolioSliceState, action: PayloadAction<number>) {
      state.filters.page = action.payload;
    },
    setPageSize(state: IProductsPortfolioSliceState, action: PayloadAction<number>) {
      state.filters.pageSize = action.payload;
    },
    setFilters(state: IProductsPortfolioSliceState, action: PayloadAction<{ searchString: string, categories: string, disabledSystems: boolean, status: string }>) {
      const updatedSearchString = action.payload.searchString;
      const updatedStatus = action.payload.status;
      const updatedCategoriesFilter = action.payload.categories ? action.payload.categories.split(",") as string[] : [];
      if (state.filters.categoriesFilter.join(",") !== updatedCategoriesFilter.join(",") || state.filters.searchString !== updatedSearchString) {
        state.filters.page = 1;
      }
      state.filters.statusFilter = updatedStatus;
      state.filters.searchString = updatedSearchString;
      state.filters.categoriesFilter = updatedCategoriesFilter;
      state.filters.disabledSystemsFilter = action.payload.disabledSystems;
    },
    setNameSort(state: IProductsPortfolioSliceState, action: PayloadAction<SortTypes>) {
      state.filters.nameFilterSort = action.payload;
    },
    clearSelectedProduct(state: IProductsPortfolioSliceState) {
      state.selectedProduct = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchList
      .addCase(fetchList.pending, (state) => {
        state.status = ApiStatuses.loading;
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        state.status = ApiStatuses.success;
        state.list = action.payload.values;
        state.filters.total = action.payload.count;
      })
      .addCase(fetchList.rejected, (state) => {
        state.status = ApiStatuses.fail;
      })
      // refreshList
      .addCase(refreshList.fulfilled, (state, action) => {
        state.list = action.payload.values;
        state.filters.total = action.payload.count;
      })
      // fetchCategories
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
      })
      // fetchProductDetails
      .addCase(fetchProductDetails.fulfilled, (state, action) => {
        state.selectedProduct = action.payload;
      })
      // refreshSelectedProduct
      .addCase(refreshSelectedProduct.fulfilled, (state, action) => {
        state.selectedProduct = action.payload;
      })
  },
});

export const productsPortfolio = slice.reducer;
export const actions = slice.actions;