import { FC, useCallback, useEffect, useState } from "react";
import { Divider } from "antd";
import { Area, AreaConfig } from "@ant-design/plots";
import { useDispatch, useSelector } from "react-redux";
import { fetchActivity as _fetchActivity } from "../slice";
import { AppDispatch, RootState } from "../../../app/store";
import { ISystemActivitiesState } from "../types";
import {
  calculate,
  getColor,
  getRangeDates,
  getTypes,
  mapActivity,
} from "./utils";
import { useTranslation } from "react-i18next";
import { H3 } from "../../../components/Typography";
import { ActivitiesTypeFilters } from "./ActivitiesTypeFilters";
import { ActivityDateFilters } from "./ActivityDateFilters";
import styles from "./SystemActivityGraph.module.css";

interface Props {
  tenantId: number;
}

export const SystemActivityGraph: FC<Props> = ({ tenantId }) => {
  const [dates, setDates] = useState<{ start: Date; end: Date }>(
    getRangeDates("today")
  );

  const [lines, setLines] = useState<ISystemActivitiesState>({
    orderActivities: true,
    reservationActivities: false,
    validationActivities: false,
  });

  const activity = useSelector((state: RootState) => {
    return state.systemsAttributes.activity;
  });

  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation("systemActivityGraph");

  const fetchActivity = useCallback(() => {
    const types = getTypes(lines);
    dispatch(
      _fetchActivity({
        tenantId,
        startTime: dates.start,
        endTime: dates.end,
        types,
      })
    );
  }, [tenantId, dates, lines, dispatch]);

  useEffect(() => {
    fetchActivity();
    const intervalId = setInterval(fetchActivity, 30000);
    return () => {
      clearInterval(intervalId);
    };
  }, [fetchActivity]);

  const config: AreaConfig = {
    data: mapActivity(dates, lines, t, activity),
    xField: "time",
    yField: "count",
    seriesField: "category",
    isStack: false,
    xAxis: {
      tickCount: 31,
      label: {
        formatter() {
          return "";
        },
      },
    },
    areaStyle: {
      fillOpacity: 0.1,
    },
    color: ({ category }) => getColor(category),
  };

  const handleSwitcherChange = (
    type: "orderActivities" | "reservationActivities" | "validationActivities"
  ) => {
    const updatedLines = { ...lines, [type]: !lines[type] };
    setLines(updatedLines);
  };

  return (
    <div className={styles.container}>
      <div className={styles.filters}>
        <div className={styles.activities}>
          <H3>{t("title")}</H3>
          <Divider type="vertical" className={styles.divider} />
          <ActivitiesTypeFilters
            lines={lines}
            onChange={handleSwitcherChange}
          />
        </div>
        <div className={styles.date}>
          <ActivityDateFilters dates={dates} onSetDates={setDates} />
        </div>
      </div>
      <div className={styles.content} dir="ltr">
        <Area
          {...config}
          autoFit
          tooltip={{
            formatter: (data) => {
              return {
                name: t(data.category),
                value: data.count,
              };
            },
          }}
          legend={{
            position: "bottom",
            marker: { symbol: "circle" },
            itemValue: {
              formatter: (text, item) => {
                if (!activity) return "";
                return calculate(activity, item.value);
              },
            },
            itemName: {
              formatter(text) {
                return t(text);
              },
            },
          }}
        />
      </div>
    </div>
  );
};
