import { ApiStatuses } from "../../app/types";

export interface IMonitor {
  id: number;
  createdOn: string; 
  details: string; 
  eventType: string;
  severity: SeverityLabels;
  systemId: number;
  updatedOn: string;
}

export interface IMonitorsSliceState {
  monitors: IMonitor[];
  status: ApiStatuses;
  page: number;
  total: number;
}

export enum SeverityLabels {
  info    = 'Info',
  warning = 'Warning',
  error   = 'Error',
}