import { Tooltip } from "antd";
import { FC, SyntheticEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import {
  IconClearFilters,
  IconSearchSmall,
  IconStoreBasket,
} from "../../../assets";
import { LMNewInput } from "../../../components";
import { LMIconButton } from "../../../components/LMIconButton";
import { LMNewButton } from "../../../components/LMNewButton";
import { IStoreProduct } from "../types";
import { Button1 } from "../../../components/Typography";
import styles from "./StoreFilters.module.css";

interface Props {
  cartProducts: IStoreProduct[];
  searchString: string;
  onShowCart: () => void;
}

export const StoreFilters: FC<Props> = ({
  searchString,
  onShowCart,
  cartProducts,
}) => {
  const { t } = useTranslation("store");
  const [searchParams, setSearchParams] = useSearchParams();

  const handleNameSearchChange = (evt: SyntheticEvent) => {
    const target = evt.target as HTMLInputElement;
    searchParams.set("searchString", target.value);
    setSearchParams(searchParams);
  };

  const handleClearFilters = () => {
    setSearchParams({});
  };

  const calculateAmount = useCallback(() => {
    return cartProducts.reduce((amount: number, product: IStoreProduct) => {
      return amount + product.quantity;
    }, 0);
  }, [cartProducts]);

  return (
    <div className={styles.container}>
      <LMNewInput
        placeholder={t("searchPlaceholder")}
        onChange={handleNameSearchChange}
        icon={<IconSearchSmall />}
        value={searchString}
      />
      <Tooltip placement="top" title={t("resetFilters")}>
        <LMIconButton color="white" onClick={handleClearFilters}>
          <IconClearFilters />
        </LMIconButton>
      </Tooltip>
      <div className={styles["button-container"]}>
        <LMNewButton
          iconRight={
            <Button1 className={styles["cart-amount"]}>
              {calculateAmount()}
            </Button1>
          }
          onClick={onShowCart}
        >
          <IconStoreBasket />
          {t("cart")}
        </LMNewButton>
      </div>
    </div>
  );
};
