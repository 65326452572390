import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IStation } from "../types";
import { Body2, Button2, H3 } from "../../../components/Typography";
import styles from "./SystemStationDetails.module.css";

interface Props {
  station: IStation;
}

export const SystemStationDetailsHeader: FC<Props> = ({ station }) => {
  const { t } = useTranslation("system");
  return (
    <div className={styles["header-container"]}>
      <H3>
        {t("id")}: {station.palletId}
      </H3>
      <div className={styles.info}>
        <Body2>{t("station")}</Body2>:&nbsp;
        <Button2>{station.location}</Button2>
      </div>
    </div>
  );
};
