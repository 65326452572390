import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICreateKeyDto, IKeysFilters, IKeysSliceState, UpdateKeyDto } from './types';
import { ApiStatuses } from '../../app/types';
import { getKeys, updateKey, createKey, getActiveKeysCount, getKey } from './api';
import { MessageOperation } from '../signalR';

export const initialState: IKeysSliceState = {
  keys: [],
  status: ApiStatuses.initial,
  keyDetailsStatus: ApiStatuses.initial,
  activeKeysCount: 0,
  createStatus: ApiStatuses.success,
  total: 0,
  filters: {
    page: 1,
    pageSize: 10,
    idOrNameFilter: "",
    statusFilter: "",
    privilegesFilter: ""
  }
};

export const fetchKeyDetails = createAsyncThunk(
  'keys/fetchKeyDetails',
  async (keyId: string) => {
    const response = await getKey(keyId);
    return response.data;
  }
);

export const fetchList = createAsyncThunk(
  'keys/fetchList',
  async ({ systemId, filters }: { systemId: number, filters: IKeysFilters }) => {
    const response = await getKeys(systemId, filters);
    return response.data;
  }
);

export const fetchActiveKeysCount = createAsyncThunk(
  'keys/fetchActiveKeysCount',
  async ({ systemId }: { systemId: number }) => {
    const response = await getActiveKeysCount(systemId);
    return response.data;
  }
);

export const updateOne = createAsyncThunk(
  'keys/updateOne',
  async ({ keyId, key }: { keyId: number, key: UpdateKeyDto }) => {
    const response = await updateKey(keyId, key);
    return response.data;
  }
);

export const create = createAsyncThunk(
  'keys/create',
  async (key: ICreateKeyDto) => {
    const response = await createKey(key);
    return response.data;
  }
);

const slice = createSlice({
  name: "keys",
  initialState,
  reducers: {
    setPage(state: IKeysSliceState, action: PayloadAction<number>) {
      state.filters.page = action.payload;
    },
    setPageSize(state: IKeysSliceState, action: PayloadAction<number>) {
      state.filters.pageSize = action.payload;
    },
    updateList(state: IKeysSliceState, action: PayloadAction<{ operation: MessageOperation, keyString: string }>) {
      if (state.status !== ApiStatuses.success) return;
      const key = JSON.parse(action.payload.keyString);
      if (action.payload.operation === MessageOperation.Updates) {
        state.keys = state.keys.map(k => k.id === key.id ? key : k);
      }
      if (action.payload.operation === MessageOperation.Create && state.filters.page === 1) {
        state.keys = [key, ...state.keys.slice(0, -1)]
      }
    },
    setFilters(
      state: IKeysSliceState,
      action: PayloadAction<Partial<IKeysFilters>>
    ) {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      // create
      .addCase(create.pending, (state) => {
        state.createStatus = ApiStatuses.loading;
      })
      .addCase(create.fulfilled, (state) => {
        state.createStatus = ApiStatuses.success;
        state.activeKeysCount = state.activeKeysCount + 1;
      })
      .addCase(create.rejected, (state) => {
        state.createStatus = ApiStatuses.fail;
      })
      // fetchActiveKeysCount
      .addCase(fetchActiveKeysCount.pending, (state) => {
        state.status = ApiStatuses.loading;
      })
      .addCase(fetchActiveKeysCount.fulfilled, (state, action) => {
        state.status = ApiStatuses.success;
        state.activeKeysCount = action.payload.count;
      })
      .addCase(fetchActiveKeysCount.rejected, (state) => {
        state.status = ApiStatuses.fail;
      })
      // fetchList
      .addCase(fetchList.pending, (state) => {
        state.status = ApiStatuses.loading;
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        state.status = ApiStatuses.success;
        state.keys = action.payload.values;
        state.total = action.payload.count;
      })
      .addCase(fetchList.rejected, (state) => {
        state.status = ApiStatuses.fail;
      })
      // fetchKeyDetails
      .addCase(fetchKeyDetails.pending, (state) => {
        state.keyDetailsStatus = ApiStatuses.loading;
      })
      .addCase(fetchKeyDetails.fulfilled, (state, action) => {
        state.keyDetailsStatus = ApiStatuses.success;
        state.keyDetails = action.payload;
      })
      .addCase(fetchKeyDetails.rejected, (state) => {
        state.keyDetailsStatus = ApiStatuses.fail;
      })
      // updateOne
      .addCase(updateOne.fulfilled, (state, action) => {
        state.keys = state.keys.map(k => {
          if (k.id === action.payload.id) {
            return { ...k, active: action.payload.active };
          }
          return k;
        });
      })
      .addCase(updateOne.rejected, (state) => {
        state.status = ApiStatuses.fail;
      })
  },
});

export const keys = slice.reducer;
export const actions = slice.actions;