import { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { fetchActiveKeysCount, fetchList } from "../slice";
import { KeysList } from "./KeysList";
import { Can } from "../../casl";
import { CreateKeyModal } from "../CreateKeyModal";
import { KeyDetailsDrawer } from "../KeyDetailsDrawer";
import { KeysFilters } from "../KeysFilters";

interface Props {
  systemId: number;
}

export const KeysListContainer: FC<Props> = ({ systemId }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { status, keys, total, filters } = useSelector((state: RootState) => {
    return state.keys;
  });

  const fetchKeys = useCallback(() => {
    dispatch(
      fetchList({
        systemId,
        filters,
      })
    );
  }, [dispatch, systemId, filters]);

  useEffect(() => {
    fetchKeys();
  }, [fetchKeys]);

  const fetchActiveKeys = useCallback(() => {
    dispatch(fetchActiveKeysCount({ systemId }));
  }, [systemId, dispatch]);

  useEffect(() => {
    fetchActiveKeys();
  }, [fetchActiveKeys]);

  return (
    <>
      <KeysFilters />
      <KeysList
        keys={keys}
        page={filters.page}
        pageSize={filters.pageSize}
        total={total}
        systemId={systemId}
        status={status}
      />
      <Can I="create" a="keys">
        <CreateKeyModal systemId={systemId} />
      </Can>
      <KeyDetailsDrawer />
    </>
  );
};
