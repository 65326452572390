import { FC } from "react";
import { EControlMode } from "../types";
import classNames from "classnames/bind";
import { isControlModeAuto } from "../utils";
import { LMNewButton } from "../../../components/LMNewButton";
import { useTranslation } from "react-i18next";
import {
  IconAutoMode,
  IconManualMode,
  IconManualControlAction,
  IconAutoControlAction,
} from "../../../assets";
import { Button2 } from "../../../components/Typography";
import { switchMode } from "../api";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import styles from "./ControlMode.module.css";
import { fetchControlMode } from "../slice";

const cx = classNames.bind(styles);

interface Props {
  mode: EControlMode;
  serialNumber: string;
  onAutoSuccess: () => void;
  onError: () => void;
  onConfirmationOpen: () => void;
  onConfirmationClose: () => void;
}

export const ControlMode: FC<Props> = ({
  mode,
  serialNumber,
  onAutoSuccess,
  onError,
  onConfirmationOpen,
  onConfirmationClose,
}) => {
  const { t } = useTranslation("control");
  const dispatch = useDispatch<AppDispatch>();
  const isAutoMode = isControlModeAuto(mode);

  const handleSwitch = async () => {
    if (isAutoMode) {
      onConfirmationOpen();
      return;
    }
    try {
      await switchMode(serialNumber, false);
      await dispatch(fetchControlMode(serialNumber)).unwrap();
      onAutoSuccess();
      onConfirmationClose();
    } catch {
      onError();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.mode}>
        <div
          className={cx({
            "icon-container": true,
            auto: isAutoMode,
          })}
        >
          {isAutoMode ? <IconAutoMode /> : <IconManualMode />}
        </div>
        <Button2>{isAutoMode ? t("autoMode") : t("manualMode")}</Button2>
      </div>
      <div className={styles["button-container"]}>
        <LMNewButton
          color="default"
          onClick={handleSwitch}
          className={styles.button}
          iconRight={
            isAutoMode ? <IconManualControlAction /> : <IconAutoControlAction />
          }
        >
          {isAutoMode ? t("switchToManual") : t("switchToAuto")}
        </LMNewButton>
      </div>
    </div>
  );
};
