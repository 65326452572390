import { DownloadOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { Button } from "antd";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { ApiStatuses } from "../../../app/types";
import { fetchReport } from "../slice";
import styles from "./ReplenishmentDrawer.module.css";

interface Props {
  downloadStatus: ApiStatuses;
  maintenanceId?: number;
  onCloseHandler: () => void;
}

export const ReplenishmentDrawerHeader: FC<Props> = ({
  downloadStatus,
  maintenanceId,
  onCloseHandler,
}) => {
  const { t } = useTranslation("maintenance");
  const dispatch = useDispatch<AppDispatch>();

  const handleDownLoadReport = useCallback(() => {
    if (!maintenanceId) return;
    dispatch(fetchReport({ maintenanceId }));
  }, [dispatch, maintenanceId]);

  return (
    <div className={styles.header}>
      <PageHeader
        className={styles["page-header"]}
        onBack={onCloseHandler}
        title={`#${maintenanceId ? maintenanceId : ""}`}
      />
      <Button
        type="primary"
        className={styles.button}
        shape="round"
        icon={<DownloadOutlined />}
        size={"middle"}
        download
        onClick={handleDownLoadReport}
        loading={downloadStatus === ApiStatuses.loading}
      >
        {t("report")}
      </Button>
    </div>
  );
};
