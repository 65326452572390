import { FC } from "react";
import { IKeyUsage } from "../types";
import { Body2, Button2, H3 } from "../../../components/Typography";
import { useTranslation } from "react-i18next";
import { LMLink } from "../../../components";
import { format } from "date-fns";
import { DATE_FORMAT, TIME_NO_SECONDS_FORMAT } from "../../../app/constants";
import { IconKeyUsageOpen } from "../../../assets";
import styles from "./KeyDetailsDrawer.module.css";

interface Props {
  usages: IKeyUsage[];
}

export const KeyUsageHistory: FC<Props> = ({ usages }) => {
  const { t } = useTranslation("keys");
  return (
    <div className={styles.usages}>
      <H3 className={styles["usages-title"]}>{t("detailsHistory")}</H3>
      {usages.map((usage) => (
        <LMLink
          target="_blank"
          to={`/maintenance/replenishment?maintenanceId=${usage.id}`}
          className={styles.usage}
          key={usage.id}
        >
          <div className={styles["usage-content"]}>
            <Button2 className={styles["usage-date"]}>
              {format(new Date(usage.started), DATE_FORMAT)}{" "}
              <span className={styles["usage-date-light"]}>
                {format(new Date(usage.started), TIME_NO_SECONDS_FORMAT)}
              </span>
            </Button2>
            <Body2 className={styles["usage-details"]}>
              {t("system")}: {usage.systemId}
            </Body2>
          </div>
          <div className={styles["usage-icon"]}>
            <IconKeyUsageOpen />
          </div>
        </LMLink>
      ))}
    </div>
  );
};
