import {FC, ReactNode} from "react";
import styles from "./PageWrapper.module.css";

interface Props {
  children: ReactNode
}

export const PageWrapper: FC<Props> = ({
  children, ...rest
}) => {
  return (
    <div {...rest} className={styles.content}>
      {children}
    </div>
  );
};
