import { ApiStatuses } from "../../app/types";

export enum EControlMode {
  ManualSystemTerminated = "ManualSystemTerminated",
  AutoSystemTerminated = "AutoSystemTerminated",
  Manual = "Manual",
  Auto = "Auto",
  Idle = "Idle",
}

export interface IControlCommand {
  commandName: string;
  commandSystem: string;
  commandDescription: string;
  fieldsDescription: Record<string, string>;
  parameters: Record<string, string>;
}

export interface IControlSliceState {
  status: ApiStatuses;
  commands: IControlCommand[];
  mode?: EControlMode;
  searchString: string;
}

export interface IRunCommand {
  commandSystem: string;
  name: string;
  parameters: Record<string, string>;
}

export type TControlCommandGrouped = Record<string, IControlCommand[]>;