import { Table } from "antd";
import { ColumnType } from "antd/lib/table";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IStockTransferProduct } from "../types";

interface Props {
  products: IStockTransferProduct[];
}

export const TransferProducts: FC<Props> = ({ products }) => {
  const { t } = useTranslation("transfers");

  const columns = useMemo(
    () => [
      {
        title: t("name"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: t("sku"),
        dataIndex: "sku",
        key: "sku",
      },
      {
        title: t("quantity"),
        dataIndex: "quantity",
        key: "quantity",
      },
    ],
    [t]
  ) as ColumnType<IStockTransferProduct>[];

  return (
    <Table
      rowKey="productId"
      dataSource={products}
      columns={columns}
      pagination={false}
    />
  );
};
