import { FC } from "react";
import { ISystemActivitiesState } from "../types";
import { useTranslation } from "react-i18next";
import { LMCheck } from "../../../components/LMCheck";
import styles from "./SystemActivityGraph.module.css";

interface Props {
  lines: ISystemActivitiesState;
  onChange: (
    type: "orderActivities" | "reservationActivities" | "validationActivities"
  ) => void;
}

export const ActivitiesTypeFilters: FC<Props> = ({ lines, onChange }) => {
  const { t } = useTranslation("systemActivityGraph");
  return (
    <>
      <div className={styles["switch-container"]}>
        <LMCheck
          id="orders"
          onChange={() => onChange("orderActivities")}
          checked={lines.orderActivities}
        />
        <label htmlFor="orders" onChange={() => onChange("orderActivities")}>
          {t("orderActivities")}
        </label>
      </div>
      <div
        className={styles["switch-container"]}
        onChange={() => onChange("reservationActivities")}
      >
        <LMCheck
          id="reservations"
          onChange={() => onChange("reservationActivities")}
          checked={lines.reservationActivities}
        />
        <label htmlFor="reservations">{t("reservationActivities")}</label>
      </div>
      <div className={styles["switch-container"]}>
        <LMCheck
          id="validations"
          onChange={() => onChange("validationActivities")}
          checked={lines.validationActivities}
        />
        <label
          htmlFor="validations"
          onChange={() => onChange("validationActivities")}
        >
          {t("validationActivities")}
        </label>
      </div>
    </>
  );
};
