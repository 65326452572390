import { Navigate } from "react-router-dom";
import { getClientNameFromScope, getEnvironment } from "../auth";

const isProduction = process.env.REACT_APP_ENV === "production";

export const RouteNotMatch = () => {
  const client = getEnvironment() as string;

  if (isProduction) {
    return <Navigate to={`/${getClientNameFromScope(client)}/1/`} />;
  }

  return <Navigate to={`/1/`} />;
};
