import { Divider, Form } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Body2 } from "../../../components/Typography";
import { InfoCard } from "../InfoCard";
import {
  EShipmentOperations,
  IShippingDetails,
  IShippingRetry,
} from "../types";
import { RetryShipmentForm } from "../RetryShipmentForm";
import { FormModal } from "../../../components/FormModal";
import styles from "./ShipmentInfo.module.css";
import { getShipmentOperations } from "../api";
import { LMNewButton } from "../../../components/LMNewButton";
import { IconRetryShipping } from "../../../assets";

interface Props {
  shipping: IShippingDetails;
  orderId: string;
}

export const ShipmentInfo: FC<Props> = ({ shipping, orderId }) => {
  const { t } = useTranslation("shipping");
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [operations, setOperations] = useState<EShipmentOperations[]>([]);
  const [form] = Form.useForm<IShippingRetry>();

  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const handleConfirmRetryShipment = () => {
    form.submit();
  };

  const handleGetShippingOperations = useCallback(async () => {
    const response = await getShipmentOperations(orderId);
    setOperations(response.data);
  }, [orderId]);

  useEffect(() => {
    handleGetShippingOperations();
  }, [handleGetShippingOperations]);

  return (
    <div className={styles.container}>
      <InfoCard
        title={t("delivery")}
        status={shipping.status}
        iconType="delivery"
        action={
          operations.includes(EShipmentOperations.RetryShipping) && (
            <LMNewButton
              color="default"
              className={styles["cancel-button"]}
              onClick={() => setConfirmationModalOpen(true)}
            >
              <IconRetryShipping /> {t("retry")}
            </LMNewButton>
          )
        }
      >
        <Body2>
          <span className={styles.light}>{t("externalId")}: </span>
          {shipping.externalId}
          <Divider type="vertical" className={styles.divider} />
          <span className={styles.light}>{t("verification")}: </span>
          {shipping.verificationCode}
        </Body2>
      </InfoCard>
      <FormModal
        onConfirm={handleConfirmRetryShipment}
        content={
          <RetryShipmentForm
            form={form}
            onClose={handleCloseConfirmationModal}
            orderId={orderId}
            shipping={shipping}
          />
        }
        open={confirmationModalOpen}
        onCancel={handleCloseConfirmationModal}
      />
    </div>
  );
};
