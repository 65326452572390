import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IconPlus } from "../../../assets";
import { LMNewButton } from "../../../components/LMNewButton";
import { Can } from "../../casl";
import styles from "./MetadataFilters.module.css";

interface Props {
  nameFilter: string;
  requiredFilter: boolean;
  onShowCreateMetadataModal: () => void;
}

export const MetadataFilters: FC<Props> = ({ onShowCreateMetadataModal }) => {
  const { t } = useTranslation("metadata");
  //const [searchParams, setSearchParams] = useSearchParams();

  // const handleNameChange = (evt: SyntheticEvent) => {
  //   const target = evt.target as HTMLInputElement;
  //   searchParams.set("name", target.value);
  //   setSearchParams(searchParams);
  // };

  // const handleRequiredChange = () => {
  //   if (requiredFilter) {
  //     searchParams.delete("required");
  //   } else {
  //     searchParams.set("required", "true");
  //   }
  //   setSearchParams(searchParams);
  // };

  // const handleClearFilters = () => {
  //   setSearchParams({});
  // };

  return (
    <div className={styles.container}>
      {/* <LMNewInput
        placeholder={t("filterName")}
        onChange={handleNameChange}
        icon={<IconSearchSmall />}
        value={nameFilter}
      />
      <div className={styles["required-filter"]}>
        <LMCheck checked={requiredFilter} onChange={handleRequiredChange} />{" "}
        <Body2>{t("filterRequired")}</Body2>
      </div>
      <Tooltip placement="top" title={t("filterClear")}>
        <LMIconButton color="white" onClick={handleClearFilters}>
          <IconClearFilters />
        </LMIconButton>
      </Tooltip> */}
      <Can I="metadata:create" a="products">
        <div className={styles["create-button-container"]}>
          <LMNewButton
            iconRight={<IconPlus />}
            onClick={onShowCreateMetadataModal}
          >
            {t("addNew")}
          </LMNewButton>
        </div>
      </Can>
    </div>
  );
};
