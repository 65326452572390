import { FC, useCallback } from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { Button2 } from "../../../components/Typography";
import { EProductStatus } from "../types";
import styles from "./ProductStatus.module.css";

const cx = classNames.bind(styles);

interface Props {
  status: EProductStatus;
}

export const ProductStatus: FC<Props> = ({ status }) => {
  const { t } = useTranslation("productsPortfolio");

  const getColorByStatus = useCallback(() => {
    if (status === EProductStatus.Approved) {
      return "green";
    }
    return "yellow";
  }, [status]);

  return (
    <Button2 className={cx({ container: true, [getColorByStatus()]: true })}>
      <span className={styles.dot} />
      {t(status.toLowerCase())}
    </Button2>
  );
};
