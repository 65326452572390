import { FC, SyntheticEvent } from "react";
import { Select } from "antd";
import { BaseOptionType } from "antd/es/select";
import { LMCheck } from "../LMCheck";
import styles from "./LMMultiSelect.module.css";
import { FormInstance } from "antd/lib/form/Form";

interface IDropdownItem {
  name: string;
  value: string;
}

interface Props<T> {
  items: IDropdownItem[];
  onChange?: (value: string[]) => void;
  placeholder?: string;
  value?: string[];
  formInstance?: FormInstance<T>;
  name?: string;
}

export const LMMultiSelect: FC<Props<unknown>> = ({
  items,
  onChange,
  value = [],
  placeholder,
  formInstance,
  name,
}) => {
  const handleChange = (items: IDropdownItem[]) => {
    const updatedValue = items.map((i) => i.value);
    if (formInstance && name) {
      formInstance.setFieldValue(name, updatedValue);
    }
    if (!onChange) return;
    onChange(updatedValue);
  };

  const handleItemClick = (evt: SyntheticEvent, itemValue: string) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (formInstance && name) {
      const currentValue = formInstance.getFieldValue(name);
      if (currentValue.includes(itemValue)) {
        formInstance.setFieldValue(
          name,
          currentValue.filter((v: string) => v !== itemValue)
        );
        return;
      }
      formInstance.setFieldValue(name, [...currentValue, itemValue]);
      return;
    }
    if (!onChange) return;
    if (value.includes(itemValue)) {
      onChange(value.filter((v) => v !== itemValue));
      return;
    }
    onChange([...value, itemValue]);
  };

  const dropdownItems: BaseOptionType[] = items.map((item) => ({
    name: item.name,
    label: (
      <label
        className={styles.item}
        htmlFor={item.name}
        onClick={(evt) => handleItemClick(evt, item.value)}
      >
        <LMCheck checked={value?.includes(item.value)} id={item.name} />{" "}
        {item.name}
      </label>
    ),
    value: item.value,
  }));

  return (
    <Select
      labelInValue
      className={styles.dropdown}
      mode="tags"
      value={value as unknown as IDropdownItem[]}
      placeholder={placeholder}
      onChange={handleChange}
      style={{ width: "100%" }}
      options={dropdownItems}
    />
  );
};
