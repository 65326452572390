import { notification } from "antd";
import { IconAlert, IconAlertSuccess } from "../../assets";
import { Body2 } from "../Typography";
import styles from "./LMNotifications.module.css";

type Action = {
  name: string;
  callback: () => void;
};

const getMessageComponent = (message: string, action?: Action) => {
  if (action) {
    return (
      <Body2 className={styles.message}>
        {message}
        <button className={styles.button} onClick={action.callback}>
          {action.name}
        </button>
      </Body2>
    );
  }
  return <Body2 className={styles.message}>{message}</Body2>;
};

export const useAppNotifications = () => {
  const [api, contextHolder] = notification.useNotification();

  const success = (message: string, action?: Action) => {
    api.success({
      className: styles.container,
      message: getMessageComponent(message, action),
      icon: <IconAlertSuccess />,
      duration: 5,
    });
  };

  const warning = (message: string, action?: Action) => {
    api.warning({
      className: styles.container,
      message: getMessageComponent(message, action),
      icon: <IconAlert fill="var(--color-yellow-70)" />,
      duration: 5,
    });
  };

  const error = (message: string, action?: Action) => {
    api.error({
      className: styles.container,
      message: getMessageComponent(message, action),
      icon: <IconAlert fill="var(--color-red-70)" />,
      duration: 5,
    });
  };

  return {
    success,
    warning,
    error,
    contextHolder,
  };
};
