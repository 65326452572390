import { ApiStatuses, IDateRange, IPagination, Nullable, SortTypes } from "../../app/types";

export enum EMediaType {
  images = "Images",
  videos = "Videos"
}

export enum EMediaStatus {
  Pending = 'Pending',
  Processing = 'Processing',
  Uploading = 'Uploading',
  Success = "Success",
  Failed = "Failed"
}

export enum EMonitoringViewType {
  library = "library",
  streams = "streams"
}

export interface IMediaItem {
  count: number
  createdOn: string;
  updatedOn: string;
  from: string;
  to: string;
  id: number;
  mediaStatus: EMediaStatus;
  mediaType: EMediaType;
  requestId: string;
  systemId: string;
  user: string;
}

export interface IMediaItemsListFilters extends IPagination {
  userFilter: string;
  dateFilter: IDateRange;
  statusFilter: EMediaStatus[];
  typeFilter: EMediaType[];
  dateFilterSort: SortTypes;
  viewType: EMonitoringViewType;
}

export interface IMonitoringSlice {
  list: IMediaItem[];
  filters: IMediaItemsListFilters;
  status: ApiStatuses;
  selectedCameras: string[];
  blobs: Nullable<Blobs>;
  streamsStatus: ApiStatuses;
  streams: Nullable<Streams>;
  selectedMedia: Nullable<IMediaItem>;
  mediaToPlay: Nullable<string>; 
}

export interface IFetchMediaItemsProps {
  systemId: number;
  filters: IMediaItemsListFilters;
}

export interface IRequestMediaFormValues {
  mediaType: EMediaType;
  date: string;
  time: string;
  duration: number;
  details: string;
  id: number;
}

export interface ICreateMediaRequest {
  from: string;
  to: string;
  mediaType: EMediaType;
  details: string;
}
export interface ICreateMediaRequests {
  systemId: number;
  user: string;
  requests: ICreateMediaRequest[];
}

export type Blobs = Record<string, string>;

export type Streams = Record<string, string>;