import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMonitorsSliceState } from './types';
import { ApiStatuses } from '../../app/types';
import { getMonitors } from './api';
import { MessageOperation } from '../signalR';

export const initialState: IMonitorsSliceState = {
  monitors: [],
  status: ApiStatuses.initial,
  page: 1,
  total: 0,
};

export const fetchList = createAsyncThunk(
  'monitors/fetchList',
  async ({ systemId, page }: { systemId: number, page: number }) => {
    const response = await getMonitors(systemId, page, 10);
    return response.data;
  }
);

const slice = createSlice({
  name: "monitors",
  initialState,
  reducers: {
    setPage(state: IMonitorsSliceState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateList(state: IMonitorsSliceState, action: PayloadAction<{ operation: MessageOperation, monitorString: string }>) {
      if (state.status !== ApiStatuses.success) return;
      const monitor = JSON.parse(action.payload.monitorString);
      if (action.payload.operation === MessageOperation.Updates) {
        state.monitors = state.monitors.map(m => m.id === monitor.id ? monitor : m);
      }
      if (action.payload.operation === MessageOperation.Create && state.page === 1) {
        state.monitors = [monitor, ...state.monitors.slice(0, -1)]
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchList
      .addCase(fetchList.pending, (state) => {
        state.status = ApiStatuses.loading;
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        state.status = ApiStatuses.success;
        state.monitors = action.payload.values;
        state.total = action.payload.count;
      })
      .addCase(fetchList.rejected, (state) => {
        state.status = ApiStatuses.fail;
      })
  },
});

export const monitors = slice.reducer;
export const actions = slice.actions;