import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITransfersSliceState } from './types';
import { ApiStatuses } from '../../app/types';
import { getTransfer, getTransfers } from './api';

export const initialState: ITransfersSliceState = {
  list: [],
  page: 1,
  total: 0,
  status: ApiStatuses.initial,
  detailsStatus: ApiStatuses.initial,
  transferNotFound: false,
};

export const fetchList = createAsyncThunk(
  'transfers/fetchList',
  async ({ page }: { page: number }) => {
    const response = await getTransfers(page, 20);
    return response.data;
  }
);

export const fetchTransfer = createAsyncThunk(
  'transfers/fetchTransfer',
  async ({ transferId }: { transferId: string }) => {
    const response = await getTransfer(transferId);
    return response.data;
  }
);

const slice = createSlice({
  name: "transfers",
  initialState,
  reducers: {
    setSelectedTransferId(state: ITransfersSliceState, action: PayloadAction<string | undefined>) {
      if (!state.selectedTransferId) {
        state.transferNotFound = false;
      }
      state.selectedTransferId = action.payload;
    },
    setPage(state: ITransfersSliceState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchList
      .addCase(fetchList.pending, (state) => {
        state.status = ApiStatuses.loading;
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        state.status = ApiStatuses.success;
        state.list = action.payload.values;
        state.total = action.payload.count;
      })
      .addCase(fetchList.rejected, (state) => {
        state.status = ApiStatuses.fail;
      })
      // fetchTransfer
      .addCase(fetchTransfer.pending, (state) => {
        state.transferNotFound = false;
        state.detailsStatus = ApiStatuses.loading;
      })
      .addCase(fetchTransfer.fulfilled, (state, action) => {
        state.detailsStatus = ApiStatuses.success;
        state.selectedTransfer = action.payload;
      })
      .addCase(fetchTransfer.rejected, (state, action) => {
        state.transferNotFound = !!action.error.message?.includes("404");
        state.detailsStatus = ApiStatuses.fail;
      })
  },
});

export const transfers = slice.reducer;
export const actions = slice.actions;