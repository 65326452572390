import { FC } from "react";
import Table from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { IIndexPalletRequest } from "../types";
import { getRequestsTableColumns } from "./utils";
import { generateId } from "../../../app/utils";

interface Props {
  requests: IIndexPalletRequest[];
}

export const IndexingRangesTable: FC<Props> = ({ requests }) => {
  const { t } = useTranslation("indexing");

  const columns = getRequestsTableColumns(t);

  return (
    <Table
      dataSource={requests.map((r) => ({ ...r, id: generateId() }))}
      rowKey="id"
      columns={columns}
      pagination={false}
    />
  );
};
