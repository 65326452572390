import { HttpTransportType, HubConnectionBuilder } from "@microsoft/signalr";
import { axios } from "../axios";

export const connect = () => {
  const url = `${axios.defaults.baseURL}hubs/solaris/`;
  return new HubConnectionBuilder()
  .withUrl(url, {
    skipNegotiation: true,
    transport: HttpTransportType.WebSockets,
  })
  .withAutomaticReconnect()
  .build();
}