import { FC } from "react";
import { Switch, SwitchProps } from "antd";
import styles from "./LMNewSwitch.module.css";

interface Props extends SwitchProps {}

export const LMNewSwitch: FC<Props> = (props) => {
  return (
    <Switch {...props} className={`${styles.container} ${props.className}`} />
  );
};
