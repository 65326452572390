import classNames from "classnames/bind";
import { FC, HTMLProps } from "react";
import styles from "./LMNewButton.module.css";

const cx = classNames.bind(styles);

interface Props extends HTMLProps<HTMLButtonElement> {
  color?: "primary" | "default" | "transparent" | "danger";
  iconRight?: JSX.Element;
  small?: boolean;
}

export const LMNewButton: FC<Props> = ({
  color = "primary",
  children,
  iconRight,
  small = false,
  type = "button",
  ...rest
}) => {
  return (
    <button
      {...rest}
      type={type as "button" | "submit" | "reset" | undefined}
      className={`${cx({ button: true, [color]: true, small })} ${
        rest.className
      }`}
    >
      {children}
      {iconRight && <span className={styles["icon-right"]}>{iconRight}</span>}
    </button>
  );
};
