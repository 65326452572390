import { ApiStatuses, Nullable } from "../../app/types";

export enum KeyPrivilege {
  LevelOne = 'LevelOne',
  LevelTwo = 'LevelTwo',
  LevelThree = 'LevelThree'
}

export enum EKeyPrivilegeOperations {
  SafeMode = "SafeMode",
  JogMode = "JogMode",
  InsertPallets = "InsertPallets",
  ExtractStockPallets = "ExtractStockPallets",
  ExtractBufferPallets = "ExtractBufferPallets",
  ExtractMachinePallets = "ExtractMachinePallets"
}

export enum KeyType {
  OneTime = 'OneTime',
  Reusable = 'Reusable'
}

export interface IKey {
  id: number;
  name: string;
  phoneNumber: Nullable<string>;
  type: KeyType;
  privileges: EKeyPrivilegeOperations[];
  code: string;
  createdBy: string;
  active: boolean;
  createdOn: string;
  updatedOn: string;
  systemId: number;
}

export interface IKeyUsage {
  id: number;
  started: string;
  systemId: number;
}

export interface IKeyDetails extends IKey {
  usages: IKeyUsage[];
}

export type TKeyStatusFilter = ('active' | 'inactive')[];

export interface IKeysSliceState {
  keys: IKey[];
  activeKeysCount: number,
  status: ApiStatuses;
  total: number;
  createStatus: ApiStatuses;
  keyDetails?: IKeyDetails;
  keyDetailsStatus: ApiStatuses;
  filters: IKeysFilters;
}

export interface IAuthorizationLevel {
  name: string;
  value: string;
  information: string;
  alert: boolean;
}

export interface ICreateKeyDto {
  name: string;
  phoneNumber: string;
  privileges: EKeyPrivilegeOperations[];
  timeFrameStart?: string;
  timeFrameEnd?: string;
  systemId: number;
  type: KeyType;
}

export interface IKeysFilters {
  page: number;
  pageSize: number;
  idOrNameFilter: string;
  statusFilter: string;
  privilegesFilter: string;
}

export type UpdateKeyDto = Partial<Pick<IKey, 'active'>>;