import { SystemsDecorator } from "../../modules/systems";
import { Store } from "./Store";

export const StoreContainer = () => {
  return (
    <SystemsDecorator>
      {(system) => <Store tenantId={system.tenantId} />}
    </SystemsDecorator>
  );
};
