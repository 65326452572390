import { AxiosPromise } from "axios";
import { ITenantDto } from "./types";
import { axios } from "../axios";

export const getTenant = (tenantId: number): AxiosPromise<ITenantDto> => {
  return axios.get(`/tenants/${tenantId}`);
};

export const updateTenant = (tenantId: number, tenant: Partial<ITenantDto>): AxiosPromise<ITenantDto> => {
  return axios.patch(`/tenants/${tenantId}`, tenant);
};