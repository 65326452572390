import { FC, Ref } from "react";
import { Segmented, SegmentedProps } from "antd";
import styles from "./LMNewRadio.module.css";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

interface Props extends SegmentedProps {
  ref?: Ref<HTMLDivElement>;
  size?: "small" | "large";
}

export const LMNewRadio: FC<Props> = ({
  size = "small",
  className,
  ...rest
}) => {
  return (
    <Segmented
      {...rest}
      className={`${cx({ container: true, [size]: true })} ${className || ""}`}
    />
  );
};
