import { FC, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { IconSearchSmall } from "../../../assets";
import { LMNewInput } from "../../../components";
import styles from "./SystemProductsFilters.module.css";

interface Props {
  searchString: string;
}

export const SystemProductsFilters: FC<Props> = ({ searchString }) => {
  const { t } = useTranslation("system");
  const [searchParams, setSearchParams] = useSearchParams();

  const handleIdSearchChange = (evt: SyntheticEvent) => {
    const target = evt.target as HTMLInputElement;
    searchParams.set("searchString", target.value);
    setSearchParams(searchParams);
  };

  return (
    <div className={styles.container}>
      <div className={styles.search}>
        <LMNewInput
          placeholder={t("searchPlaceholder")}
          onChange={handleIdSearchChange}
          icon={<IconSearchSmall />}
          value={searchString}
        />
      </div>
    </div>
  );
};
