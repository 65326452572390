import { FC } from "react";
import generatePicker, {
  RangePickerBaseProps,
} from "antd/es/date-picker/generatePicker";
import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";
import styles from "./LMRangePicker.module.css";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

export type RangeValue = Parameters<
  NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>["onChange"]>
>[0];

const DatePicker = generatePicker(dateFnsGenerateConfig);

const { RangePicker } = DatePicker;

interface Props extends Omit<RangePickerBaseProps<Date>, "picker"> {
  onChange?: (values: RangeValue) => void;
  value?: RangeValue;
  size?: "small" | "large";
}

export const LMRangePicker: FC<Props> = ({
  className,
  size = "small",
  ...rest
}) => {
  return (
    <RangePicker
      {...rest}
      className={`${cx({ range: true, [size]: true })} ${className || ""}`}
      showTime={{ use12Hours: true }}
      format={"MMM dd, yyyy h a"}
    />
  );
};
