import { SystemsDecorator } from "../../modules/systems";
import { Replenishment } from "./Replenishment";

export const ReplenishmentContainer = () => {
  return (
    <SystemsDecorator>
      {(system) => <Replenishment systemId={system.id} />}
    </SystemsDecorator>
  );
};
