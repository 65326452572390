import { SystemsDecorator } from "../../modules/systems";
import { Monitoring } from "./Monitoring";

export const MonitoringContainer = () => {
  return (
    <SystemsDecorator>
      {(system) => {
        return <Monitoring systemId={system.id} />;
      }}
    </SystemsDecorator>
  );
};
