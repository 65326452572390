import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import {
  IconAcceptNewOrdersActive,
  IconAcceptNewOrdersInactive,
} from "../../../assets";
import { CardContainer } from "../../../components/CardContainer";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { IconWithBackground } from "../../../components/IconWithBackground";
import { LMNewRadio } from "../../indexing/LMNewRadio";
import { ISystemDto } from "../../systems";
import { AcceptNewOrdersConfirmationContent } from "./AcceptNewOrdersConfirmationContent";
import { LoaderWrapper } from "../../../components";
import { fetchTenant, updateTenant } from "../slice";

interface Props {
  system: ISystemDto;
}

export const AcceptNewOrders: FC<Props> = ({ system }) => {
  const { t } = useTranslation("acceptNewOrders");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const { selectedTenant, status } = useSelector((state: RootState) => {
    return state.tenants;
  });

  const getTenant = useCallback(() => {
    dispatch(fetchTenant(system.tenantId));
  }, [system, dispatch]);

  useEffect(() => {
    getTenant();
  }, [getTenant]);

  const handleConfirm = () => {
    if (!selectedTenant) return;
    dispatch(
      updateTenant({
        tenantId: selectedTenant.id,
        tenant: { acceptNewOrders: !selectedTenant.acceptNewOrders },
      })
    );
    handleCloseConfirmationModal();
  };

  const handleAcceptNewOrdersUpdate = () => setConfirmModalOpen(true);
  const handleCloseConfirmationModal = () => setConfirmModalOpen(false);

  return (
    <LoaderWrapper status={status}>
      {selectedTenant ? (
        <>
          <CardContainer
            icon={
              <IconWithBackground
                icon={
                  selectedTenant.acceptNewOrders ? (
                    <IconAcceptNewOrdersActive />
                  ) : (
                    <IconAcceptNewOrdersInactive />
                  )
                }
                color={selectedTenant.acceptNewOrders ? "green" : "red"}
              />
            }
            title={t("title")}
            subtitle={
              selectedTenant.acceptNewOrders
                ? t("subtitleActive")
                : t("subtitleInactive")
            }
          >
            <LMNewRadio
              onChange={handleAcceptNewOrdersUpdate}
              options={[
                { label: t("enabled"), value: "enabled" },
                { label: t("disabled"), value: "disabled" },
              ]}
              value={selectedTenant.acceptNewOrders ? "enabled" : "disabled"}
            />
          </CardContainer>
          <ConfirmationModal
            content={<AcceptNewOrdersConfirmationContent />}
            confirmText={t("confirm")}
            open={confirmModalOpen}
            onConfirm={handleConfirm}
            onCancel={handleCloseConfirmationModal}
          />
        </>
      ) : (
        <></>
      )}
    </LoaderWrapper>
  );
};
