import { FC } from "react";
import { Link, LinkProps } from "react-router-dom";
import { SystemsDecorator } from "../../modules/systems";
import { getEnvironment } from "../../modules/auth";

const isProduction = process.env.REACT_APP_ENV === "production";

export const LMLink: FC<LinkProps> = ({ to, ...rest }) => {
  const environment = getEnvironment() as string;
  const environmentPath = isProduction
    ? `/${environment.replace("clients:", "")}`
    : "";
  return (
    <SystemsDecorator>
      {(system) => (
        <Link to={`${environmentPath}/${system.id}${to}`} {...rest} />
      )}
    </SystemsDecorator>
  );
};
