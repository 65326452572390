import { FC } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { EOrderOperations, IOrder } from "../types";
import { LMNewButton } from "../../../components/LMNewButton";
import { IconCancelOrder, IconProcessOrder, IconRevert } from "../../../assets";
import { cancelOrder, completeOrder, queueOrder, resetOrder } from "../api";
import { AppDispatch } from "../../../app/store";
import { refreshOrder } from "../slice";
import { useAppNotifications } from "../../../components/LMNotifications";
import { PopoverConfirmation } from "../../../components/PopoverConfirmation";
import styles from "./OrderActions.module.css";

interface Props {
  order: IOrder;
  operations: EOrderOperations[];
}

export const OrderActions: FC<Props> = ({ order, operations }) => {
  const { t } = useTranslation("order");
  const dispatch = useDispatch<AppDispatch>();
  const { contextHolder, success, error } = useAppNotifications();

  const handleCancelOrder = async () => {
    try {
      await cancelOrder(order.id);
      await dispatch(refreshOrder({ orderId: order.id })).unwrap();
      success(t("cancelSuccess"));
    } catch {
      error(t("operationFail"));
    }
  };

  const handleQueueOrder = async () => {
    try {
      await queueOrder(order.id);
      await dispatch(refreshOrder({ orderId: order.id })).unwrap();
      success(t("queueSuccess"));
    } catch {
      error(t("operationFail"));
    }
  };

  const handleCompleteOrder = async () => {
    try {
      await completeOrder(order.id);
      await dispatch(refreshOrder({ orderId: order.id })).unwrap();
      success(t("completeSuccess"));
    } catch {
      error(t("operationFail"));
    }
  };

  const handleResetOrder = async () => {
    try {
      await resetOrder(order.id);
      await dispatch(refreshOrder({ orderId: order.id })).unwrap();
      success(t("resetSuccess"));
    } catch {
      error(t("operationFail"));
    }
  };

  const hasOperation = (operation: EOrderOperations) => {
    return operations.some((o) => o === operation);
  };

  return (
    <>
      {contextHolder}
      <div className={styles.actions}>
        {hasOperation(EOrderOperations.Queue) && (
          <PopoverConfirmation
            onConfirm={handleQueueOrder}
            text={t("confirmProcess")}
          >
            <LMNewButton color="default" className={styles["process-button"]}>
              {t("process")}
              <IconProcessOrder />
            </LMNewButton>
          </PopoverConfirmation>
        )}
        {hasOperation(EOrderOperations.Complete) && (
          <PopoverConfirmation
            onConfirm={handleCompleteOrder}
            text={t("confirmComplete")}
          >
            <LMNewButton color="default">
              {t("complete")}
              <IconProcessOrder />
            </LMNewButton>
          </PopoverConfirmation>
        )}
        {hasOperation(EOrderOperations.Cancel) && (
          <PopoverConfirmation
            onConfirm={handleCancelOrder}
            text={t("confirmCancel")}
          >
            <LMNewButton color="danger">
              {t("cancel")}
              <IconCancelOrder />
            </LMNewButton>
          </PopoverConfirmation>
        )}
        {hasOperation(EOrderOperations.Reset) && (
          <PopoverConfirmation
            onConfirm={handleResetOrder}
            text={t("confirmReset")}
          >
            <LMNewButton color="default">
              {t("reset")}
              <IconRevert />
            </LMNewButton>
          </PopoverConfirmation>
        )}
      </div>
    </>
  );
};
