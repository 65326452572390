import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuthSliceState } from './types';

export const initialState: IAuthSliceState = {
  clientsAllAccess: false
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setClientsAllAccess(state, action: PayloadAction<boolean>) {
      state.clientsAllAccess = action.payload;
    },
  },
});

export const auth = slice.reducer;
export const actions = slice.actions;