import { AxiosPromise } from "axios";
import { IControlCommand, EControlMode, IRunCommand } from "./types";
import { axios } from "../axios";

export const getControlMode = (serialNumber: string): AxiosPromise<EControlMode> => {
  return axios.get(`/control/${serialNumber.toLowerCase()}/mode`);
};

export const getControlCommands = (serialNumber: string): AxiosPromise<IControlCommand[]> => {
  return axios.get(`/control/${serialNumber.toLowerCase()}/commands`);
};

export const runCommand = (serialNumber: string, command: IRunCommand) => {
  return axios.post(`/control/${serialNumber.toLowerCase()}/run`, command);
};

export const switchMode = (serialNumber: string, take: boolean): AxiosPromise<EControlMode> => {
  return axios.post(`/control/${serialNumber.toLowerCase()}/manual?take=${take}`);
};
