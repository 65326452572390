import { FC, useCallback } from "react";
import { Collapse } from "antd";
import { EOrderStatus, IOrderPart } from "../types";
import { OrderPartProducts } from "../OrderPartProducts";
import { OrderPartItem } from "./OrderPartItem";
import { sortOrderPartProducts } from "../utils";
import styles from "./OrderParts.module.css";

const { Panel } = Collapse;

interface Props {
  parts: IOrderPart[];
}

export const OrderParts: FC<Props> = ({ parts }) => {
  const getSortedParts = useCallback(() => {
    const sortedParts = parts.slice();
    sortedParts.sort((a, b) => {
      if (a.status === EOrderStatus.Collected) {
        return -1;
      }
      return 1;
    });
    return sortedParts;
  }, [parts]);
  return (
    <Collapse className={styles.container}>
      {getSortedParts().map((part) => (
        <Panel header={<OrderPartItem part={part} />} key={part.id}>
          <OrderPartProducts
            products={part.products.slice().sort(sortOrderPartProducts)}
          />
        </Panel>
      ))}
    </Collapse>
  );
};
