export enum ApiStatuses {
  initial = 'initial',
  loading = 'loading',
  success = 'success',
  fail = 'fail',
} 

export interface IApiError {
  message: string;
  code: number;
}

export type Colors = "gray" | "red" | "yellow" | "green" | "violet" | "orange" | "blue";
export type SortTypes = "ascend" | "descend" | "none";

export interface IApiList<Type> {
  count: number;
  values: Type[];
}

export interface IDateRange {
  start: Nullable<Date>,
  end: Nullable<Date>,
}

export interface IPagination {
  page: number;
  pageSize: number;
  total: number;
}


export type Nullable<T> = T | null;