import { FC, ReactNode } from "react";
import classNames from "classnames/bind";
import { Colors } from "../../../app/types";
import { IconDelivery, IconInfoCircle, IconOrder } from "../../../assets";
import { IconWithBackground } from "../../../components/IconWithBackground";
import { Button1 } from "../../../components/Typography";
import { OrderStatus } from "../OrderStatus";
import { EOrderStatus } from "../types";
import { getColorByStatus } from "../utils";
import styles from "./InfoCard.module.css";

const cx = classNames.bind(styles);

interface Props {
  iconType: "order" | "delivery" | "orderInfo";
  title: string;
  status?: EOrderStatus;
  children: ReactNode;
  action?: ReactNode;
  noBottomRadius?: boolean;
}

const getCssColor = (color: Colors) => {
  if (color === "red") {
    return "var(--color-red-70)";
  }
  if (color === "yellow") {
    return "var(--color-yellow-70)";
  }
  if (color === "green") {
    return "var(--color-green-70)";
  }
  if (color === "gray") {
    return "var(--color-gray-80)";
  }
  return "var(--color-violet-70)";
};

const getIcon = (
  iconType: "order" | "delivery" | "orderInfo",
  status?: EOrderStatus
) => {
  if (iconType === "order" && status) {
    return <IconOrder stroke={getCssColor(getColorByStatus(status))} />;
  }

  if (iconType === "delivery" && status) {
    return <IconDelivery stroke={getCssColor(getColorByStatus(status))} />;
  }

  return <IconInfoCircle />;
};

export const InfoCard: FC<Props> = ({
  status,
  iconType,
  title,
  children,
  noBottomRadius = false,
  action,
}) => {
  return (
    <div
      className={cx({
        container: true,
        "no-bottom-radius": noBottomRadius,
        "order-info": iconType === "orderInfo",
      })}
    >
      <div className={styles["icon-container"]}>
        <IconWithBackground
          size="large"
          color={iconType === "orderInfo" ? "blue" : getColorByStatus(status)}
          icon={getIcon(iconType, status)}
        />
      </div>
      <div className={styles["info-container"]}>
        <div className={styles["title-container"]}>
          <Button1 className={styles.title}>{title}</Button1>{" "}
          {status && <OrderStatus status={status} />}
        </div>
        <div className={styles.content}>{children}</div>
      </div>
      {action && <div className={styles.action}>{action}</div>}
    </div>
  );
};
