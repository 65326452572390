import { FC } from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { Button2 } from "../../../components/Typography";
import { EOrderStatus } from "../types";
import { getColorByStatus } from "../utils";
import styles from "./OrderStatus.module.css";

const cx = classNames.bind(styles);

interface Props {
  status?: EOrderStatus;
  onlyDot?: boolean;
}

export const OrderStatus: FC<Props> = ({ status, onlyDot = false }) => {
  const { t } = useTranslation("order");
  return (
    <Button2
      className={cx({ container: true, [getColorByStatus(status)]: true })}
    >
      <span className={styles.dot} />
      {!onlyDot && (status ? t(status) : t("noDelivery"))}
    </Button2>
  );
};
