export enum MessageKind {
  OrderEvent = 'OrderEvent',
  KeyEvent = 'KeyEvent',
  MonitorEvent = 'MonitorEvent',
  MediaEvent = 'MediaEvent',
  SystemEvent = 'SystemEvent',
  TenantEvent = 'TenantEvent',
  ShippingEvent = 'ShippingEvent',
  StateEvent = 'StateEvent',
  ControlEvent = 'ControlEvent',
}

export enum MessageOperation {
  Create,
  Updates
}