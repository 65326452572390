import { FC, ReactNode } from "react";
import { Button1, H3 } from "../Typography";
import classNames from "classnames/bind";
import styles from "./LMTable.module.css";

const cx = classNames.bind(styles);

export interface ITableTotal {
  amount?: number;
  title: string;
}

interface Props {
  total?: ITableTotal;
  action?: ReactNode;
  totalSuffix?: ReactNode;
}

export const LMTableHeader: FC<Props> = ({ total, action, totalSuffix }) => {
  return (
    <div className={styles.header}>
      {total && (
        <div className={cx({ total: true, primary: !total.amount })}>
          <H3 className={styles.title}>{total.title}</H3>
          {total.amount !== undefined && (
            <span className={styles["total-label"]}>
              <Button1>
                {total.amount}
                {totalSuffix ? ` ${totalSuffix}` : ""}
              </Button1>
            </span>
          )}
        </div>
      )}
      {action && <div className={styles["action-container"]}>{action}</div>}
    </div>
  );
};
