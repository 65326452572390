import { SystemsDecorator } from "../../modules/systems";
import { Control } from "./Control";

export const ControlContainer = () => {
  return (
    <SystemsDecorator>
      {(system) => {
        return (
          <Control serialNumber={system.serialNumber} systemId={system.id} />
        );
      }}
    </SystemsDecorator>
  );
};
