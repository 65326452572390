import { FC, ReactNode } from "react";
import { Table } from "antd";
import { ColumnsType, TablePaginationConfig, TableProps } from "antd/es/table";
import styles from "./LMTable.module.css";
import { LMTableFooter } from "./LMTableFooter";
import { LMTableHeader } from "./LMTableHeader";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { SortTypes } from "../../app/types";

export interface ITableTotal {
  amount?: number;
  title: string;
}

interface Props<T> extends TableProps<T> {
  columns: ColumnsType<T>;
  dataSource: T[];
  total?: ITableTotal;
  onChangePageSize?: (size: string) => void;
  onChangePage?: (page: number) => void;
  onSortChange?: (column: string, order: SortTypes) => void;
  action?: ReactNode;
  pageSize?: number;
  page?: number;
  loading?: boolean;
  totalSuffix?: string;
  rowKey?: string;
}

export const LMTable: FC<Props<any>> = ({
  columns,
  dataSource,
  total,
  onChangePageSize,
  onChangePage,
  onSortChange,
  pageSize,
  page,
  onRow,
  action,
  loading = false,
  rowKey = "id",
  totalSuffix,
  ...rest
}) => {
  const onChange = (
    pagination: TablePaginationConfig,
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    const sortParams = sorter as SorterResult<any>;
    if (sorter && onSortChange) {
      onSortChange(
        sortParams.columnKey as string,
        sortParams.order as SortTypes
      );
    }
  };

  return (
    <div className={styles.container}>
      {(total || action) && (
        <LMTableHeader
          total={total}
          action={action}
          totalSuffix={totalSuffix}
        />
      )}
      <Table
        className={styles.table}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        rowKey={rowKey}
        onRow={onRow}
        onChange={onChange}
        loading={loading}
        {...rest}
      />
      {onChangePageSize && onChangePage && (
        <LMTableFooter
          total={total}
          onChangePageSize={onChangePageSize}
          onChangePage={onChangePage}
          pageSize={pageSize}
          page={page}
        />
      )}
    </div>
  );
};
