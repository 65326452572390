import { useEffect, FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import {
  fetchMaintenances,
  maintenanceActions,
  ReplenishmentDrawer,
  ReplenishmentList,
} from "../../modules/maintenance";
import { AppDispatch, RootState } from "../../app/store";
import { PageHeader } from "../../components";
import { PageWrapper } from "../../components/PageWrapper";
import styles from "./Replenishment.module.css";

interface Props {
  systemId: number;
}

export const Replenishment: FC<Props> = ({ systemId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation("maintenance");

  const { status, maintenances, page, total, selectedMaintenanceId } =
    useSelector((state: RootState) => {
      return state.maintenance;
    });

  const handleFetchMaintenances = useCallback(() => {
    dispatch(fetchMaintenances({ systemId, page }));
  }, [systemId, dispatch, page]);

  useEffect(() => {
    const maintenanceId = searchParams.get("maintenanceId");
    if (maintenanceId) {
      dispatch(
        maintenanceActions.setSelectedMaintenanceId(parseInt(maintenanceId, 0))
      );
    }
    return () => {
      maintenanceActions.setSelectedMaintenanceId();
    };
  }, [searchParams, dispatch]);

  useEffect(() => {
    handleFetchMaintenances();
  }, [handleFetchMaintenances]);

  return (
    <PageWrapper>
      <PageHeader title={t("title")} />
      <div className={styles.container}>
        <ReplenishmentList
          list={maintenances}
          page={page}
          total={total}
          status={status}
        />
        <ReplenishmentDrawer
          maintenanceId={selectedMaintenanceId}
          systemId={systemId}
        />
      </div>
    </PageWrapper>
  );
};
