import { FC } from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { EPalletType, IStation } from "../types";
import { Caption } from "../../../components/Typography";
import styles from "./Station.module.css";
import { Nullable } from "../../../app/types";

const cx = classNames.bind(styles);

interface Props {
  station: IStation;
  onSelect: (station: IStation) => void;
  isSelected: boolean;
}

export const Station: FC<Props> = ({ station, onSelect, isSelected }) => {
  const { t } = useTranslation("system");

  const handleClick = () => {
    // TODO: uncomment when API will be ready
    // if (!station.palletId) return;
    // if (!isContainProducts(station.type) && !isContainOrders(station.type))
    //   return;
    // onSelect(station);
  };

  const isContainProducts = (type: Nullable<EPalletType>) =>
    type === EPalletType.SingleItemHive || type === EPalletType.Hive;
  const isContainOrders = (type: Nullable<EPalletType>) =>
    type === EPalletType.BufferHive;

  return (
    <button
      className={cx({
        container: true,
        selected: isSelected,
        empty: !station.palletId,
        box: station.type === EPalletType.Boxer,
        // TODO: Remove when API will be ready
        "not-clickable": true,
        // "not-clickable":
        //   !isContainProducts(station.type) && !isContainOrders(station.type),
      })}
      onClick={handleClick}
    >
      <div className={styles.station}>{station.location}</div>
      {isContainProducts(station.type) && (
        <div className={styles.quantity}>
          {station.quantity ? station.quantity : ""}
        </div>
      )}
      {isContainOrders(station.type) && (
        <div className={styles.buffer}>
          <div className={styles.dot} />
          <Caption className={styles["buffer-text"]}>{t("buffer")}</Caption>
        </div>
      )}
    </button>
  );
};
