import { FC, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Table } from "antd";
import { IStockTransfer } from "../types";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { ColumnType } from "antd/lib/table";
import { format } from "date-fns";
import { actions } from "../slice";
import { useTranslation } from "react-i18next";
import { ApiStatuses } from "../../../app/types";
import { DATE_TIME_FORMAT } from "../../../app/constants";

interface Props {
  transfers: IStockTransfer[];
  total: number;
  page: number;
  status: ApiStatuses;
}

export const TransfersList: FC<Props> = ({
  transfers,
  total,
  page,
  status,
}) => {
  const [, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation("transfers");

  const columns = useMemo(
    () => [
      {
        title: t("id"),
        dataIndex: "id",
        key: "id",
      },
      {
        title: t("type"),
        dataIndex: "type",
        key: "type",
      },
      {
        title: t("quantity"),
        key: "quantity",
        render: (transfer: IStockTransfer) =>
          transfer.products
            .map((p) => p.quantity)
            .reduce((total, val) => total + val),
      },
      {
        title: t("created"),
        dataIndex: "createdOn",
        key: "createdOn",
        render: (createdOn: string) =>
          format(new Date(createdOn), DATE_TIME_FORMAT),
      },
    ],
    [t]
  ) as ColumnType<IStockTransfer>[];

  const handleRowClick = (transfer: IStockTransfer) => {
    setSearchParams({ transferId: transfer.id });
  };

  const handlePageChange = (updatedPage: number) => {
    dispatch(actions.setPage(updatedPage));
  };

  return (
    <Table
      rowKey="id"
      dataSource={transfers}
      columns={columns}
      loading={status === ApiStatuses.loading}
      onRow={(row) => ({
        onClick: () => handleRowClick(row),
        style: { cursor: "pointer" },
      })}
      pagination={{
        showSizeChanger: false,
        size: "small",
        current: page,
        onChange: handlePageChange,
        pageSize: 20,
        total,
      }}
    />
  );
};
