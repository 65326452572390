import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { IconSystemTasks } from "../../../assets";
import { CardContainer } from "../../../components/CardContainer";
import { IconWithBackground } from "../../../components/IconWithBackground";
import { LMNewButton } from "../../../components/LMNewButton";
import { ISystemDto } from "../../systems";
import { updateSystem } from "../../systems/slice";
import { ISystemConfiguration, ISystemTasksValues } from "../types";
import { SystemTasksModalContent } from "./SystemTasksModalContent";
import {
  getDefaultValues,
  getIconBackgroundColor,
  getIconColor,
  getSystemTasksNames,
} from "./utils";
import { FormModal } from "../../../components/FormModal";

interface Props {
  system: ISystemDto;
  configuration: ISystemConfiguration;
}

export const SystemTasks: FC<Props> = ({ system, configuration }) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [values, setValues] = useState<ISystemTasksValues>(
    getDefaultValues(system, configuration)
  );
  const { t } = useTranslation("systemTasks");
  const dispatch = useDispatch<AppDispatch>();

  const handleConfirm = async () => {
    dispatch(
      updateSystem({ systemId: system.id, system: { activeTasks: values } })
    );
    handleCloseConfirmationModal();
  };

  const handleChangeTasks = () => setConfirmModalOpen(true);
  const handleCloseConfirmationModal = () => setConfirmModalOpen(false);

  const handleChangeValues = (values: ISystemTasksValues) => {
    setValues(values);
  };

  useEffect(() => {
    const newValues = getDefaultValues(system, configuration);
    setValues(newValues);
  }, [system, configuration]);

  return (
    <>
      <CardContainer
        icon={
          <IconWithBackground
            icon={<IconSystemTasks stroke={getIconColor(system)} />}
            color={getIconBackgroundColor(system)}
          />
        }
        title={t("title")}
        subtitle={getSystemTasksNames(system, t)}
      >
        <LMNewButton color="default" onClick={handleChangeTasks} small>
          {t("action")}
        </LMNewButton>
      </CardContainer>
      <FormModal
        onConfirm={handleConfirm}
        submitText={t("apply")}
        content={
          <SystemTasksModalContent
            key={system.id}
            values={values}
            onChange={handleChangeValues}
            configuration={configuration}
          />
        }
        open={confirmModalOpen}
        onCancel={handleCloseConfirmationModal}
      />
    </>
  );
};
