import {
  cloneElement,
  FC,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { Dropdown, theme, type MenuProps, FormInstance } from "antd";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { IconDropdownArrow } from "../../assets";
import { LMNewInput } from "../LMNewInput";
import styles from "./LMDropdown.module.css";

const { useToken } = theme;

const cx = classNames.bind(styles);

interface IDropdownItem {
  name: string | ReactNode;
  value: string;
  icon?: string;
}

const getNameFromValue = (
  items: IDropdownItem[],
  value: string,
  placeholder?: string
) => {
  const item = items.find((i) => i.value === value) as IDropdownItem;
  if (!item) return placeholder || "";
  return item.name;
};

interface Props<T> {
  items: IDropdownItem[];
  Icon?: ({ stroke }: { stroke: string; className: string }) => JSX.Element;
  collapsed?: boolean;
  onChange?: (value: string) => void;
  defaultValue?: string;
  title?: string;
  placeholder?: string;
  small?: boolean;
  value?: string;
  formInstance?: FormInstance<T>;
  name?: string;
  disabled?: boolean;
}

export const LMDropdown: FC<Props<unknown>> = ({
  items,
  Icon,
  collapsed,
  onChange,
  defaultValue,
  title,
  small = false,
  placeholder,
  value: _value,
  formInstance,
  name,
  disabled = false,
}) => {
  const [value, setValue] = useState(defaultValue ? defaultValue : "");
  const [search, setSearch] = useState("");
  const [filteredItems, setFilteredItems] = useState(items);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("layout");

  const handleSetSearch = (evt: SyntheticEvent) => {
    const target = evt.target as HTMLInputElement;
    setSearch(target.value);
  };

  useEffect(() => {
    const updatedFilteredItems = items.filter((i) =>
      i.name?.toString().toLowerCase().includes(search.toLowerCase())
    );
    setFilteredItems(updatedFilteredItems);
  }, [search, items]);

  const dropdownItems: MenuProps["items"] = filteredItems.map((item) => ({
    label: <div>{item.name}</div>,
    key: item.value,
    icon: item.icon,
  }));

  const { token } = useToken();

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };

  useEffect(() => {
    if (_value || _value === "") setValue(_value);
  }, [_value]);

  const handleMenuClick = ({ key }: { key: string }) => {
    if (onChange) {
      onChange(key);
    }
    if (formInstance && name) {
      formInstance.setFieldValue(name, key);
    }
    setValue(key);
    setOpen(false);
  };

  return (
    <Dropdown
      trigger={["click"]}
      menu={{
        items: dropdownItems,
        onClick: handleMenuClick,
        selectable: true,
        defaultSelectedKeys: [value],
        className: styles.menu,
      }}
      disabled={disabled}
      className={cx({ dropdown: true, small })}
      onOpenChange={setOpen}
      dropdownRender={(menu) => (
        <div style={contentStyle}>
          {title && (
            <div className={styles.header}>
              <div className={styles.title}>{title}</div>
              <LMNewInput
                placeholder={t("search")}
                onChange={handleSetSearch}
              />
            </div>
          )}
          {cloneElement(menu as React.ReactElement, {
            style: menuStyle,
          })}
        </div>
      )}
    >
      <div className={cx({ label: true, active: open, collapsed })}>
        {Icon && (
          <Icon
            className={cx({ icon: true, collapsed })}
            stroke={open ? "var(--color-primary-80)" : "var(--color-black)"}
          />
        )}
        <span
          className={cx({
            text: true,
            collapsed,
            placeholder:
              getNameFromValue(items, value, placeholder) === placeholder,
          })}
        >
          {getNameFromValue(items, value, placeholder)}
        </span>
        <span
          className={cx({ "dropdown-icon": true, rotated: open, collapsed })}
        >
          <IconDropdownArrow
            stroke={open ? "var(--color-primary-80)" : "var(--color-gray-70)"}
          />
        </span>
      </div>
    </Dropdown>
  );
};
