import { EMediaStatus } from "../types";

export const getColorByStatus = (status: EMediaStatus) => {
  if (status === EMediaStatus.Failed) {
    return "red";
  }
  if (status === EMediaStatus.Success) {
    return "green";
  }
  return "violet";
}