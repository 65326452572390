import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useLink } from "../../components/LMLink";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { TFunction } from "i18next";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { Button1 } from "../../components/Typography";
import { getMaintenanceKey } from "./utils";
import { useLocation } from "react-router-dom";
import { fetchActiveKeysCount } from "../../modules/keys";
import styles from "./HeaderTitle.module.css";

export const getMaintenanceKeys = (t: TFunction, activeKeysCount: number) => {
  return [
    {
      key: "keys",
      label: (
        <div className={styles["with-total"]}>
          {t("keys") as ReactNode}{" "}
          <Button1 className={styles["count"]}>{activeKeysCount}</Button1>
        </div>
      ),
    },
    { key: "replenishment", label: t("replenishment") },
    { key: "indexing", label: t("indexing") },
    { key: "transfers", label: t("transfers") },
  ];
};

export const MaintenanceTabs = () => {
  const navigate = useLink();
  const location = useLocation();
  const { t } = useTranslation("navigation");
  const dispatch = useDispatch<AppDispatch>();
  const [activeKey, setActiveKey] = useState(
    getMaintenanceKey(location.pathname)
  );

  const activeKeysCount = useSelector((state: RootState) => {
    return state.keys.activeKeysCount;
  });

  const selectedSystem = useSelector((state: RootState) => {
    return state.systems.selectedSystem;
  });

  const handleMaintenanceTabsChange = (key: string) => {
    setActiveKey(key);
    navigate(`/maintenance/${key}`);
  };

  const getActiveKeysCount = useCallback(() => {
    if (!selectedSystem) return;
    dispatch(fetchActiveKeysCount({ systemId: selectedSystem.id }));
  }, [selectedSystem, dispatch]);

  useEffect(() => {
    getActiveKeysCount();
  }, [getActiveKeysCount]);

  return (
    <Tabs
      className={styles.tabs}
      defaultActiveKey={activeKey}
      items={getMaintenanceKeys(t, activeKeysCount)}
      onChange={handleMaintenanceTabsChange}
    />
  );
};
