import { format, startOfDay, subDays, subMonths, subQuarters, subWeeks, subYears } from "date-fns";
import { TFunction } from "i18next";
import {
  DATE_TIME_NO_SECONDS_COMPACT_FORMAT,
} from "../../../app/constants";
import { ActivityTypes } from "../../systems";
import { ActivitiesDatesRanges, ISystemActivities, ISystemActivitiesState, ISystemActivity } from "../types";

type Tick = { time: string, category: string, count: number };

const updateTick = (
  activity: ISystemActivity,
  tick: Tick[],
  categoryName: string,
  isMultiple: boolean,
): Tick[] => {
  if (isMultiple) {
    return [
      ...tick,
      {
        time: `${format(new Date(activity.startTime), DATE_TIME_NO_SECONDS_COMPACT_FORMAT)} - ${format(new Date(activity.endTime), DATE_TIME_NO_SECONDS_COMPACT_FORMAT)}`,
        category: categoryName,
        count: activity.total,
      }
    ]
  }
  return [
    ...tick,
    {
      time: `${format(new Date(activity.startTime), DATE_TIME_NO_SECONDS_COMPACT_FORMAT)} - ${format(new Date(activity.endTime), DATE_TIME_NO_SECONDS_COMPACT_FORMAT)}`,
      category: "total",
      count: activity.total,
    },
    {
      time: `${format(new Date(activity.startTime), DATE_TIME_NO_SECONDS_COMPACT_FORMAT)} - ${format(new Date(activity.endTime), DATE_TIME_NO_SECONDS_COMPACT_FORMAT)}`,
      category: "failed",
      count: activity.failed,
    }
  ]
}

export const mapActivity = (
  range: { start: Date; end: Date },
  lines: ISystemActivitiesState,
  t: TFunction,
  activities?: ISystemActivities,
): Tick[] => {
  const lists: Tick[][] = [];
  if (!activities) return [];
  const length = activities.orderActivities.length || activities.reservationActivities.length || activities.validationActivities.length;
  for (let index = 0; index < length; index++) {
    let tick: any = [];
    if (lines.orderActivities && activities.orderActivities.length > 0) {
      const orderActivity = activities.orderActivities[index];
      tick = updateTick(
        orderActivity,
        tick,
        "orderActivities",
        lines.reservationActivities || lines.validationActivities
      );
    }
    if (lines.reservationActivities && activities.reservationActivities.length > 0) {
      const reservationActivity = activities.reservationActivities[index];
      tick = updateTick(
        reservationActivity,
        tick,
        "reservationActivities",
        lines.orderActivities || lines.validationActivities
      );
    }
    if (lines.validationActivities && activities.validationActivities.length > 0) {
      const validationActivity = activities.validationActivities[index];
      tick = updateTick(
        validationActivity,
        tick,
        "validationActivities",
        lines.orderActivities || lines.reservationActivities
      );
    }
    lists.push(tick);
  }
  return lists.flat(1);
};

export const getColor = (category: string): string => {
  if (category === "orderActivities") {
    return getComputedStyle(document.documentElement)
      .getPropertyValue('--color-primary-80');
  }
  if (category === "total") {
    return getComputedStyle(document.documentElement)
      .getPropertyValue('--color-green-80');
  }
  if (category === "reservationActivities") {
    return getComputedStyle(document.documentElement)
      .getPropertyValue('--color-yellow-70');
  }
  if (category === "failed") {
    return getComputedStyle(document.documentElement)
      .getPropertyValue('--color-red-70');
  }
  return getComputedStyle(document.documentElement)
    .getPropertyValue('--color-green-80');
};

export const getTypes = (lines: ISystemActivitiesState) => {
  const types: ActivityTypes[] = [];
  if (lines.orderActivities) {
    types.push(ActivityTypes.Orders);
  }
  if (lines.reservationActivities) {
    types.push(ActivityTypes.Reservations);
  }
  if (lines.validationActivities) {
    types.push(ActivityTypes.Validations);
  }
  return types;
}

export const getRanges = (t: TFunction) => ([
  {
    name: t("today"),
    value: "today",
  },
  {
    name: t("day"),
    value: "day",
  },
  {
    name: t("week"),
    value: "week",
  },
  {
    name: t("month"),
    value: "month",
  },
  {
    name: t("quarter"),
    value: "quarter",
  },
  {
    name: t("year"),
    value: "year",
  },
  {
    name: t("custom"),
    value: "custom",
  }
]);

export const getRangeDates = (range: ActivitiesDatesRanges) => {
  const today = new Date();
  if (range === "day") {
    return {
      start: subDays(today, 1),
      end: today,
    }
  }
  if (range === "week") {
    return {
      start: subWeeks(today, 1),
      end: today,
    }
  }
  if (range === "month") {
    return {
      start: subMonths(today, 1),
      end: today,
    }
  }
  if (range === "year") {
    return {
      start: subYears(today, 1),
      end: today,
    }
  }
  if (range === "quarter") {
    return {
      start: subQuarters(today, 1),
      end: today,
    }
  }
  return {
    start: startOfDay(today),
    end: today,
  }
}

export const calculate = (
  activities: ISystemActivities,
  property: "total" | "failed" | "orderActivities" | "reservationActivities" | "validationActivities") => {
  let count = 0;
  if (property === "total" || property === "failed") {
    Object.keys(activities).forEach((key) => {
      const activityName = key as keyof ISystemActivities;
      const activity = activities[activityName];
      activity.forEach(point => {
        count += point[property];
      })
    })
    return count;
  }
  activities[property].forEach((point) => {
    count += point.total;
  })
  return count;
};
