import { UserInfo } from "../../modules/auth";
import { SupportModal } from "../../modules/support";
import { HeaderOptions } from "../HeaderOptions";
import { EStopButton } from "../../modules/systemAttributes";
import { HeaderTitle } from "../HeaderTitle";
import styles from "./LMHeader.module.css";

export const LMHeader = () => {
  return (
    <>
      <div className={styles.left}>
        <HeaderTitle />
      </div>
      <div className={styles.right}>
        <EStopButton />
        <HeaderOptions />
        <UserInfo />
      </div>
      <SupportModal />
    </>
  );
};
