import { FC } from "react";
import { IControlCommand } from "../types";
import { ControlCommand } from "../ControlCommand";
import styles from "./ControlModule.module.css";
import { H3 } from "../../../components/Typography";
import { Collapse } from "antd";
import { useAppNotifications } from "../../../components/LMNotifications";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

interface Props {
  commands: IControlCommand[];
  moduleName: string;
  serialNumber: string;
}

export const ControlModule: FC<Props> = ({
  commands,
  moduleName,
  serialNumber,
}) => {
  const { contextHolder, success, error } = useAppNotifications();
  const { t } = useTranslation("control");

  const handleCommandSuccess = () => success(t("commandSuccess"));

  const handleCommandError = () => error(t("commandError"));

  return (
    <div className={styles.container}>
      <H3 className={styles.header}>{moduleName}</H3>
      <Collapse className={styles.commands}>
        {commands.map((command) => (
          <Panel header={command.commandName} key={command.commandName}>
            <ControlCommand
              command={command}
              serialNumber={serialNumber}
              onSuccess={handleCommandSuccess}
              onError={handleCommandError}
            />
          </Panel>
        ))}
      </Collapse>
      {contextHolder}
    </div>
  );
};
