import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IStationProduct, IStationProductDetails } from "../types";
import { ColumnType } from "antd/lib/table";
import { ApiStatuses } from "../../../app/types";
import { LMTable } from "../../../components/LMTable";
import { extendByMetadata } from "../utils";

interface Props {
  products: IStationProduct[];
  status: ApiStatuses;
}

export const StationProducts: FC<Props> = ({ products, status }) => {
  const { t } = useTranslation("system");

  const columns = useMemo(
    () => [
      {
        title: t("name"),
        key: "product",
        dataIndex: "product",
        render: (product: IStationProductDetails) => product.name,
      },
      {
        title: t("sku"),
        key: "sku",
        dataIndex: "product",
        render: (product: IStationProductDetails) => product.sku,
      },
      {
        title: t("quantity"),
        key: "quantity",
        dataIndex: "quantity",
      },
      ...extendByMetadata(products).metadataList.map((metadataName) => ({
        title: metadataName.title,
        key: metadataName.value,
        dataIndex: metadataName.value,
      })),
    ],
    [t, products]
  ) as ColumnType<IStationProduct>[];

  return (
    <LMTable
      total={{ title: t("stationTotalProducts"), amount: products.length }}
      loading={status === ApiStatuses.loading}
      columns={columns}
      dataSource={extendByMetadata(products).productsWithMetadata}
    />
  );
};
