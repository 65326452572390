import { useTranslation } from "react-i18next";
import { FC } from "react";
import classNames from "classnames/bind";
import { Body1, Body2, H3 } from "../../../components/Typography";
import { Streams } from "../types";
import { LMCheck } from "../../../components/LMCheck";
import { useSearchParams } from "react-router-dom";
import styles from "./MonitoringStreams.module.css";
import { LMIconButton } from "../../../components/LMIconButton";
import { ArrowLeft } from "../../../assets";

const cx = classNames.bind(styles);

interface Props {
  streams: Streams;
  selectedCameras: string[];
  collapsed: boolean;
  onChangeCollapsed: (collapsed: boolean) => void;
}

export const MonitoringStreamsFilters: FC<Props> = ({
  streams,
  selectedCameras,
  collapsed,
  onChangeCollapsed,
}) => {
  const { t } = useTranslation("monitoring");
  const [searchParams, setSearchParams] = useSearchParams();

  const handleToggleCamera = (camera: string) => {
    let updatedCameras = selectedCameras;
    if (selectedCameras.includes(camera)) {
      updatedCameras = selectedCameras.filter((c) => c !== camera);
    } else {
      updatedCameras = [...selectedCameras, camera];
    }
    searchParams.set("cameras", updatedCameras.join(","));
    setSearchParams(searchParams);
  };

  const handleChangeCollapsed = () => onChangeCollapsed(!collapsed);

  return (
    <div className={cx({ "filters-container": true, collapsed })}>
      <LMIconButton
        onClick={handleChangeCollapsed}
        className={styles["collapse-button"]}
      >
        <ArrowLeft className={cx({ "collapse-icon": true, collapsed })} />
      </LMIconButton>
      <div className={styles["filters-header"]}>
        <H3>{t("chooseCamera")}</H3>&nbsp;
        <Body1>{t("chooseCameraTip")}</Body1>
      </div>
      <ul className={styles.cameras}>
        {Object.keys(streams).map((camera) => (
          <li key={camera}>
            <button
              disabled={
                selectedCameras.length === 2 &&
                !selectedCameras.includes(camera)
              }
              className={styles["camera-button"]}
              onClick={() => handleToggleCamera(camera)}
            >
              <LMCheck
                disabled={
                  selectedCameras.length === 2 &&
                  !selectedCameras.includes(camera)
                }
                checked={selectedCameras.includes(camera)}
              />{" "}
              <Body2>{t(camera)}</Body2>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
