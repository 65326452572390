import { AxiosPromise } from "axios";
import { IApiList } from "../../app/types";
import { axios } from "../axios";
import { IStockTransfer } from "./types";
import { getPagination } from "../../app/utils";

export const getTransfers = (page: number, pageSize: number): AxiosPromise<IApiList<IStockTransfer>> => {
  return axios.get(`/stock/transfers/${getPagination(page, pageSize, true)}`);
}

export const getTransfer = (transferId: string): AxiosPromise<IStockTransfer> => {
  return axios.get(`/stock/transfers/${transferId}`);
}