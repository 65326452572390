import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { useDispatch } from "react-redux";
import { PhoneInput } from "../../../components/PhoneInput";
import { H3 } from "../../../components/Typography";
import { LMNewInput } from "../../../components";
import { FormInstance } from "antd/lib/form/Form";
import { IShippingDetails, IShippingRetry } from "../types";
import { FC, useEffect, useState } from "react";
import { useAppNotifications } from "../../../components/LMNotifications";
import { retryShipping } from "../api";
import { AppDispatch } from "../../../app/store";
import { refreshOrder } from "../slice";
import styles from "./RetryShipmentForm.module.css";

interface Props {
  form: FormInstance<IShippingRetry>;
  orderId: string;
  shipping: IShippingDetails;
  onClose: () => void;
}

export const RetryShipmentForm: FC<Props> = ({
  form,
  orderId,
  shipping,
  onClose,
}) => {
  const { t } = useTranslation("shipping");
  const { contextHolder, success, error } = useAppNotifications();
  const dispatch = useDispatch<AppDispatch>();
  const [initialValues, setInitialValues] = useState<IShippingRetry>({
    name: shipping.name,
    address: shipping.address,
    // TODO: need to think how to handle phone number codes
    phoneNumber: shipping.phoneNumber.includes("+")
      ? shipping.phoneNumber
      : `+972${shipping.phoneNumber}`,
    notes: shipping.notes,
  });

  const onFinish = async (values: IShippingRetry) => {
    try {
      await retryShipping(orderId, values);
      await dispatch(refreshOrder({ orderId })).unwrap();
      success(t("retryShipmentSuccess"));
      onClose();
    } catch (e: any) {
      error(t("operationFail"));
    }
  };

  useEffect(() => {
    setInitialValues({
      name: shipping.name,
      address: shipping.address,
      phoneNumber: shipping.phoneNumber,
      notes: shipping.notes,
    });
  }, [shipping]);

  return (
    <>
      {contextHolder}
      <div className={styles["confirmation-container"]}>
        <div className={styles["confirmation-title"]}>
          <H3>{t("retryShipping")}</H3>
        </div>
        <Form
          layout={"vertical"}
          form={form}
          className={styles.form}
          onFinish={onFinish}
          initialValues={initialValues}
          autoComplete="off"
        >
          <Form.Item name="name" label={t("name")}>
            <LMNewInput placeholder={t("enterName")} />
          </Form.Item>
          <Form.Item name="address" label={t("address")}>
            <LMNewInput placeholder={t("enterAddress")} />
          </Form.Item>
          <Form.Item name="phoneNumber" label={t("phone")}>
            <PhoneInput />
          </Form.Item>
          <Form.Item name="notes" label={t("notes")}>
            <LMNewInput placeholder={t("enterNotes")} />
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
