import { FC } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { EMetadataTypes, IMetadataEntry } from "../../metadata/types";
import { Body2, H3 } from "../../../components/Typography";
import styles from "./CreateProductForm.module.css";
import { LMDropdown, LMNewInput } from "../../../components";
import { LMCheck } from "../../../components/LMCheck";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

interface Props {
  entries: IMetadataEntry[];
  withEdit?: boolean;
  disabled?: boolean;
}

export const MetadataFields: FC<Props> = ({
  entries,
  withEdit = false,
  disabled = false,
}) => {
  const { t } = useTranslation("productsPortfolio");

  const getLabel = (entry: IMetadataEntry) => {
    return (
      <div className={styles.category}>
        {entry.name}&nbsp;
        {!entry.required && (
          <Body2 className={styles.optional}>{t("optional")}</Body2>
        )}
      </div>
    );
  };

  const getInputType = (entry: IMetadataEntry) => {
    if (entry.type === EMetadataTypes.Float || EMetadataTypes.Integer) {
      return "number";
    }
    return "text";
  };

  const getValueProp = (entry: IMetadataEntry) => {
    if (entry.type === EMetadataTypes.Bool) return "checked";
    return undefined;
  };

  const getControl = (entry: IMetadataEntry) => {
    if (entry.options && entry.options.length > 0) {
      return (
        <LMDropdown
          key={entry.name}
          placeholder={t("dropdownPlaceholder")}
          disabled={disabled}
          items={(entry.options as string[]).map((option) => ({
            value: option,
            name: option,
          }))}
        />
      );
    }
    if (entry.type === EMetadataTypes.Bool) {
      return <LMCheck key={entry.name} value={false} disabled={disabled} />;
    }
    return (
      <LMNewInput
        key={entry.name}
        type={getInputType(entry)}
        disabled={disabled}
        placeholder="0"
      />
    );
  };

  return (
    <div className={cx({ edit: withEdit })}>
      <H3>{t("properties")}</H3>
      {entries.map((entry) => (
        <Form.Item
          className={styles.horizontal}
          name={["metadata", entry.name]}
          valuePropName={getValueProp(entry)}
          label={getLabel(entry)}
          rules={[
            {
              required: entry.required && entry.type !== EMetadataTypes.Bool,
              message: t("requiredMessage"),
            },
          ]}
        >
          {getControl(entry)}
        </Form.Item>
      ))}
    </div>
  );
};
