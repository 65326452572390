import classNames from "classnames/bind";
import { FC, HTMLProps } from "react";
import styles from "./LMIconButton.module.css";

const cx = classNames.bind(styles);

interface Props extends HTMLProps<HTMLButtonElement> {
  color?: "default" | "white";
}

export const LMIconButton: FC<Props> = ({ color = "default", ...rest }) => {
  return (
    <button
      {...rest}
      type="button"
      className={`${cx({ button: true, [color]: true })} ${
        rest.className || ""
      }`}
    />
  );
};
