import { FC } from "react";
import styles from "./CardContainer.module.css";

interface Props {
  children: JSX.Element;
  icon: JSX.Element;
  title: string;
  subtitle: string | JSX.Element;
}

export const CardContainer: FC<Props> = ({
  children,
  icon,
  title,
  subtitle,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.head}>
        <div className={styles["icon-container"]}>{icon}</div>
        <div className={styles.text}>
          <h4 className={styles.title}>{title}</h4>
          <p className={styles.subtitle}>{subtitle}</p>
        </div>
      </div>
      <div className={styles.actions}>{children}</div>
    </div>
  );
};
