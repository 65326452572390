import { ApiStatuses } from "../../app/types";
import { ISystemTasksValues } from "../systemAttributes";

export enum EActiveTasks {
  Maintenance = "Maintenance",
  Orders = "Orders",
  Deliveries = "Deliveries",
  Fulfillment = "Fulfillment",
  Consolidation = "Consolidation",
}

export enum EMachineSpeedOptions {
  Low = "Low",
  Medium = "Medium",
  Fast = "Fast"
}
export interface ISystemDto {
  activeTasks: EActiveTasks[];
  createdOn: string;
  id: number;
  machineSpeed: EMachineSpeedOptions
  name: string;
  offSite: boolean;
  serialNumber: string
  tenantId: number;
  updatedOn: string
}


export interface IUpdateSystemDto {
  activeTasks?: ISystemTasksValues;
  machineSpeed?: EMachineSpeedOptions
}

export interface ISystemsSliceState {
  status: ApiStatuses;
  systemStatus: ApiStatuses;
  systems: ISystemDto[];
  selectedSystem?: ISystemDto;
}

export enum ActivityTypes {
  Orders = "orders",
  Reservations = "reservations",
  Validations = "validations"
}
