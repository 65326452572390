import { FC, useCallback, useContext, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ColumnType } from "antd/lib/table";
import { refreshList } from "../slice";
import { AppDispatch } from "../../../app/store";
import { ApiStatuses } from "../../../app/types";
import { LMTable } from "../../../components/LMTable";
import { EMetadataTypes, IMetadataEntry } from "../types";
import { Body1, Body2, Caption, H3 } from "../../../components/Typography";
import { Tooltip } from "antd";
import { LMIconButton } from "../../../components/LMIconButton";
import { IconCheck, IconEdit, IconMore, IconTrash } from "../../../assets";
import { deleteMetadataEntry } from "../api";
import { useAppNotifications } from "../../../components/LMNotifications";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { AbilityContext, Can } from "../../casl";
import styles from "./MetadataList.module.css";

interface Props {
  list: IMetadataEntry[];
  total: number;
  status: ApiStatuses;
  onEditEntry: (entry: IMetadataEntry) => void;
}

export const MetadataList: FC<Props> = ({
  list,
  total,
  status,
  onEditEntry,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const ability = useContext(AbilityContext);
  const [entryToDelete, setEntryToDelete] = useState("");
  const { contextHolder, success, error } = useAppNotifications();
  const { t } = useTranslation("metadata");

  const handleDeleteEntry = useCallback(async () => {
    if (!entryToDelete) return;
    try {
      await deleteMetadataEntry(entryToDelete);
      await dispatch(refreshList());
      success(t("deleteSuccessMessage"));
      setEntryToDelete("");
    } catch (e: any) {
      error(t("deleteErrorMessage"));
    }
  }, [success, error, t, dispatch, entryToDelete]);

  const columns = useMemo(
    () => [
      {
        title: t("name"),
        key: "name",
        dataIndex: "name",
      },
      {
        title: t("options"),
        key: "options",
        render: (entry: IMetadataEntry) => {
          if (!entry.options) return null;
          return (
            <div className={styles.options}>
              {entry.options.map((option) => (
                <Caption className={styles.option} key={option}>
                  {option}
                </Caption>
              ))}
            </div>
          );
        },
      },
      {
        title: t("type"),
        key: "type",
        dataIndex: "type",
        render: (type: EMetadataTypes) => {
          return t(type);
        },
      },
      {
        title: t("required"),
        key: "required",
        dataIndex: "required",
        render: (required: boolean) => {
          if (required) {
            return <IconCheck stroke="var(--color-green-80)" />;
          }
          return "";
        },
      },
      {
        title: "",
        key: "more",
        render: (entry: IMetadataEntry) => {
          if (
            ability.cannot("metadata:update", "products") &&
            ability.cannot("metadata:delete", "products")
          ) {
            return null;
          }
          return (
            <Tooltip
              color="white"
              arrow={false}
              className={styles.tooltip}
              placement="bottomRight"
              title={
                <div className={styles.buttons}>
                  <Can I="metadata:update" a="products">
                    <button
                      className={styles.button}
                      onClick={() => onEditEntry(entry)}
                    >
                      <IconEdit /> <Body2>{t("edit")}</Body2>
                    </button>
                  </Can>
                  <Can I="metadata:delete" a="products">
                    <button
                      className={styles.button}
                      onClick={() => setEntryToDelete(entry.id)}
                    >
                      <IconTrash /> <Body2>{t("delete")}</Body2>
                    </button>
                  </Can>
                </div>
              }
            >
              <LMIconButton>
                <IconMore />
              </LMIconButton>
            </Tooltip>
          );
        },
      },
    ],
    [t, onEditEntry, ability]
  ) as ColumnType<IMetadataEntry>[];

  return (
    <>
      {contextHolder}
      <LMTable
        loading={status === ApiStatuses.loading}
        columns={columns}
        dataSource={list}
        total={{ title: t("entries"), amount: total }}
      />
      <ConfirmationModal
        content={
          <>
            <div className={styles["confirmation-title"]}>
              <H3>{t("confirmationTitle")}</H3>
            </div>
            <Body1>{t("deletionConfirmation")}</Body1>
          </>
        }
        confirmText={t("confirm")}
        open={!!entryToDelete}
        onConfirm={handleDeleteEntry}
        onCancel={() => setEntryToDelete("")}
      />
    </>
  );
};
