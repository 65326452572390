import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMetadataEntryFormValues, IMetadataFilters, IMetadataSliceState } from './types';
import { ApiStatuses } from '../../app/types';
import { createMetadataEntry, getMetadataEntries, getMetadataEntry, updateMetadataEntry } from './api';

export const initialState: IMetadataSliceState = {
  list: [],
  status: ApiStatuses.initial,
  detailsStatus: ApiStatuses.initial,
  total: 0,
  filters: {
    nameFilter: "",
    requiredFilter: false,
  }
};


export const fetchList = createAsyncThunk(
  'metadata/fetchList',
  async () => {
    const response = await getMetadataEntries();
    return response.data;
  }
);

export const refreshList = createAsyncThunk(
  'metadata/refreshList',
  async () => {
    const response = await getMetadataEntries();
    return response.data;
  }
);

export const fetchMetadataDetails = createAsyncThunk(
  'metadata/fetchMetadataDetails',
  async (metadataEntryId: string) => {
    const response = await getMetadataEntry(metadataEntryId);
    return response.data;
  }
);

export const updateMetadata = createAsyncThunk(
  'metadata/updateMetadata',
  async ({ id, entry }: { id: string, entry: IMetadataEntryFormValues }) => {
    const response = await updateMetadataEntry(id, entry);
    return response.data;
  }
);

export const createMetadata = createAsyncThunk(
  'metadata/createMetadata',
  async (entry: IMetadataEntryFormValues) => {
    const response = await createMetadataEntry(entry);
    return response.data;
  }
);

const slice = createSlice({
  name: "metadata",
  initialState,
  reducers: {
    setFilters(
      state: IMetadataSliceState,
      action: PayloadAction<Partial<IMetadataFilters>>
    ) {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchList
      .addCase(fetchList.pending, (state) => {
        state.status = ApiStatuses.loading;
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        state.status = ApiStatuses.success;
        state.list = action.payload;
        state.total = action.payload.length;
      })
      .addCase(fetchList.rejected, (state) => {
        state.status = ApiStatuses.fail;
      })
      // refreshList
      .addCase(refreshList.fulfilled, (state, action) => {
        state.list = action.payload;
        state.total = action.payload.length;
      })
  },
});

export const metadata = slice.reducer;
export const actions = slice.actions;