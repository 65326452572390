import { useTranslation } from "react-i18next";
import { Body1 } from "../../components/Typography";
import { SupportForm } from "../../modules/support";
import { useState } from "react";
import { SupportSuccess } from "../../modules/support/SupportSuccess";
import styles from "./Support.module.css";

export const Support = () => {
  const { t } = useTranslation("support");
  const [submitted, setSubmitted] = useState(false);

  const handleSuccess = () => setSubmitted(true);

  return (
    <div className={styles.container}>
      {submitted ? (
        <SupportSuccess />
      ) : (
        <>
          <Body1>{t("pageTip")}</Body1>
          <SupportForm onSuccess={handleSuccess} />
        </>
      )}
    </div>
  );
};
