import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ITenantDto, ITenantsSliceState } from './types';
import { ApiStatuses } from '../../app/types';
import { getTenant, updateTenant as _updateTenant } from './api';
import { MessageOperation } from '../signalR';

export const initialState: ITenantsSliceState = {
  status: ApiStatuses.initial,
};

export const fetchTenant = createAsyncThunk(
  'tenants/fetchTenant',
  async (tenantId: number) => {
    const response = await getTenant(tenantId);
    return response.data;
  }
);

export const updateTenant = createAsyncThunk(
  'tenants/updateTenant',
  async ({ tenantId, tenant }: { tenantId: number, tenant: Partial<ITenantDto> }) => {
    const response = await _updateTenant(tenantId, tenant);
    return response.data;
  }
);

const slice = createSlice({
  name: "tenants",
  initialState,
  reducers: {
    updateTenantFromSignalR(state: ITenantsSliceState, action: PayloadAction<{ operation: MessageOperation, tenantString: string }>) {
      if (action.payload.operation === MessageOperation.Updates) {
        const updatedState = JSON.parse(action.payload.tenantString) as ITenantDto;
        state.selectedTenant = updatedState;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchSystem
      .addCase(fetchTenant.pending, (state) => {
        state.status = ApiStatuses.loading;
      })
      .addCase(fetchTenant.fulfilled, (state, action) => {
        state.status = ApiStatuses.success;
        state.selectedTenant = action.payload;
      })
      .addCase(fetchTenant.rejected, (state) => {
        state.status = ApiStatuses.fail;
      })
      // updateSystem
      .addCase(updateTenant.fulfilled, (state, action) => {
        state.selectedTenant = action.payload;
      })
  },
});

export const tenants = slice.reducer;
export const actions = slice.actions;