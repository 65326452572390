import { FC, useState } from "react";
import Table from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { IIndexCellProduct } from "../types";
import { calculateTotalForType, getCompareTableColumns } from "./utils";
import styles from "./IndexingDetails.module.css";

interface Props {
  cellProducts: IIndexCellProduct[];
}

export const IndexingCompareTable: FC<Props> = ({ cellProducts }) => {
  const [page, setPage] = useState(1);
  const { t } = useTranslation("indexing");

  const columns = getCompareTableColumns(t);

  const handlePageChange = (updatedPage: number) => {
    setPage(updatedPage);
  };

  return (
    <Table
      dataSource={cellProducts}
      rowKey="palletId"
      columns={columns}
      pagination={{
        showSizeChanger: false,
        size: "small",
        current: page,
        onChange: handlePageChange,
        pageSize: 10,
        total: cellProducts.length,
      }}
      summary={(pageData) => {
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={2}>
                <p className={styles["total-title"]}>{t("totalQuantity")}</p>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} colSpan={3}>
                <div className={styles.totals}>
                  <div className={styles.total}>{t("valid")} </div>
                  <div className={styles.total}>{t("invalid")} </div>
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <div className={styles.total}>
                  <span className={styles["total-value"]}>
                    {calculateTotalForType(cellProducts, true)}
                  </span>
                </div>
                <div className={styles.total}>
                  <span className={styles["total-value"]}>
                    {calculateTotalForType(cellProducts, false)}
                  </span>
                </div>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
};
