import { FC, useCallback } from "react";
import { IControlCommand } from "../types";
import { Form } from "antd";
import { LMNewInput } from "../../../components";
import { Body2 } from "../../../components/Typography";
import { LMNewButton } from "../../../components/LMNewButton";
import { useTranslation } from "react-i18next";
import { IconRun } from "../../../assets";
import { LMCheck } from "../../../components/LMCheck";
import { runCommand } from "../api";
import styles from "./ControlCommand.module.css";

interface Props {
  command: IControlCommand;
  serialNumber: string;
  onSuccess: () => void;
  onError: () => void;
}

export const ControlCommand: FC<Props> = ({
  command,
  serialNumber,
  onSuccess,
  onError,
}) => {
  const { t } = useTranslation("control");

  const getInitialValues = useCallback(() => {
    const values: Record<string, boolean | string | number> = {};
    Object.keys(command.fieldsDescription).forEach((field) => {
      if (command.parameters[field] === "Boolean") {
        values[field] = false;
        return;
      }
      if (
        command.parameters[field] === "Int32" ||
        command.parameters[field] === "Double"
      ) {
        values[field] = 0;
        return;
      }
      values[field] = "";
    });
    return values;
  }, [command]);

  const handleFinish = async (values: Record<string, string>) => {
    try {
      await runCommand(serialNumber, {
        commandSystem: command.commandSystem,
        name: command.commandName,
        parameters: values,
      });
      onSuccess();
    } catch {
      onError();
    }
  };

  return (
    <div className={styles.container}>
      <Body2>{command.commandDescription}</Body2>
      <Form
        layout={"inline"}
        className={styles.form}
        onFinish={handleFinish}
        initialValues={getInitialValues()}
        autoComplete="off"
      >
        {Object.keys(command.fieldsDescription).map((field) => {
          const label = command.fieldsDescription[field] as string;
          if (command.parameters[field] === "Boolean") {
            return (
              <Form.Item
                key={field}
                name={field}
                label={label}
                className={styles.row}
                valuePropName="checked"
              >
                <LMCheck />
              </Form.Item>
            );
          }
          return (
            <Form.Item
              key={field}
              name={field}
              label={label}
              className={styles.row}
            >
              <LMNewInput
                type={command.parameters[field] === "Int32" ? "number" : "text"}
              />
            </Form.Item>
          );
        })}
        <div className={styles.button}>
          <LMNewButton iconRight={<IconRun />} type="submit">
            {t("run")}
          </LMNewButton>
        </div>
      </Form>
    </div>
  );
};
