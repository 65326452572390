import { Auth0Provider } from "@auth0/auth0-react";
import { PropsWithChildren } from "react";
import { Nullable } from "../../app/types";
import { scopes } from "./scopes";
import { useNavigate } from "react-router-dom";
import { getLastPathname } from "./utils";

export const WithAuth = ({
  children,
}: PropsWithChildren<any>): Nullable<JSX.Element> => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    const lastPathName = getLastPathname();
    if (!lastPathName) return;
    navigate(lastPathName);
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_DASHBOARD_CLIENT_ID!}
      redirectUri={window.location.origin}
      onRedirectCallback={handleRedirect}
      useRefreshTokens={true}
      audience={"solaris-api"}
      scope={scopes.join(" ")}
    >
      {children}
    </Auth0Provider>
  );
};
