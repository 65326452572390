import { FC } from "react";
import classNames from "classnames/bind";
import styles from "./RelevanceDot.module.css";

const cx = classNames.bind(styles);

interface Props {
  isRelevant?: boolean;
  isPresentation?: boolean;
}

export const RelevanceDot: FC<Props> = ({
  isRelevant = false,
  isPresentation = false,
}) => {
  return (
    <span
      className={cx({
        container: true,
        presentation: isPresentation,
        relevant: isRelevant,
      })}
    />
  );
};
