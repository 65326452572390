import { SystemsDecorator } from "../../modules/systems";
import { KeysList } from "../../modules/keys/KeysList";

export const KeyListSystemContainer = () => {
  return (
    <SystemsDecorator>
      {(system) => {
        return <KeysList systemId={system.id} />;
      }}
    </SystemsDecorator>
  );
};
