import { FC, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useSearchParams } from "react-router-dom";
import { ColumnType } from "antd/lib/table";
import { actions } from "../slice";
import {
  EMediaStatus,
  EMediaType,
  IMediaItem,
  IMediaItemsListFilters,
} from "../types";
import { AppDispatch } from "../../../app/store";
import { ApiStatuses, SortTypes } from "../../../app/types";
import { DATE_FORMAT, TIME_NO_SECONDS_FORMAT } from "../../../app/constants";
import { LMTable } from "../../../components/LMTable";
import { MediaStatus } from "../MediaStatus";
import { MediaType } from "../MediaType";
import { LMNewButton } from "../../../components/LMNewButton";
import { IconPlus } from "../../../assets";
import { RequestMediaModal } from "../RequestMediaModal";
import { useAppNotifications } from "../../../components/LMNotifications";
import { MediaPlayModal } from "../MediaPlayModal";
import styles from "./MediaItemsList.module.css";

interface Props {
  list: IMediaItem[];
  filters: IMediaItemsListFilters;
  status: ApiStatuses;
  systemId: number;
  onRefresh: () => void;
}

export const MediaItemsList: FC<Props> = ({
  list,
  filters,
  status,
  systemId,
  onRefresh,
}) => {
  const [requestModalShown, setRequestModalShown] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { contextHolder, success, error } = useAppNotifications();
  const dispatch = useDispatch<AppDispatch>();

  const { t } = useTranslation("monitoring");

  const handlePageChange = (updatedPage: number) => {
    dispatch(actions.setPage(updatedPage));
  };

  const handlePageSizeChange = (updatedSize: string) => {
    dispatch(actions.setPageSize(Number(updatedSize)));
    dispatch(actions.setPage(1));
  };

  const handleSortDateChange = (column: string, order: SortTypes) => {
    dispatch(actions.setDateSort(order));
  };

  const handleShowRequestMediaModal = () => setRequestModalShown(true);
  const handleHideRequestMediaModal = () => setRequestModalShown(false);

  const handleSuccessAddedRequests = (amount: number) => {
    handleHideRequestMediaModal();
    if (amount > 1) {
      success(`${amount} ${t("requestsSuccess")}`);
      return;
    }
    success(t("requestSuccess"));
    onRefresh();
  };

  const handleErrorAddedRequests = () => {
    handleHideRequestMediaModal();
    error(t("requestError"));
    onRefresh();
  };

  const handleRowClick = (item: IMediaItem) => {
    if (item.mediaStatus !== EMediaStatus.Success) return;
    searchParams.set("mediaId", item.id.toString());
    setSearchParams(searchParams);
  };

  const columns = useMemo(
    () => [
      {
        title: t("date"),
        key: "created",
        dataIndex: "createdOn",
        render: (createdOn: string) => {
          return (
            <>
              {format(new Date(createdOn), DATE_FORMAT)}
              <span className={styles.time}>
                &nbsp;
                {format(new Date(createdOn), TIME_NO_SECONDS_FORMAT)}
              </span>
            </>
          );
        },
        sorter: true,
      },
      {
        title: t("type"),
        key: "mediaType",
        dataIndex: "mediaType",
        render: (type: EMediaType) => <MediaType type={type} />,
      },
      {
        title: t("user"),
        key: "user",
        dataIndex: "user",
      },
      {
        title: t("status"),
        dataIndex: "mediaStatus",
        key: "mediaStatus",
        render: (status: EMediaStatus) => <MediaStatus status={status} />,
      },
    ],
    [t]
  ) as ColumnType<IMediaItem>[];

  const onRow = (media: IMediaItem) => ({
    onClick: () => handleRowClick(media),
    style: {
      cursor:
        media.mediaStatus === EMediaStatus.Success ? "pointer" : "initial",
    },
  });

  return (
    <>
      {contextHolder}
      <LMTable
        loading={status === ApiStatuses.loading}
        columns={columns}
        dataSource={list}
        total={{ title: t("total"), amount: filters.total }}
        action={
          <LMNewButton
            onClick={handleShowRequestMediaModal}
            iconRight={<IconPlus />}
            type="primary"
            small
          >
            {t("requestMedia")}
          </LMNewButton>
        }
        onChangePageSize={handlePageSizeChange}
        onChangePage={handlePageChange}
        onSortChange={handleSortDateChange}
        pageSize={filters.pageSize}
        onRow={onRow}
        page={filters.page}
      />
      <RequestMediaModal
        onSuccess={handleSuccessAddedRequests}
        onError={handleErrorAddedRequests}
        systemId={systemId}
        open={requestModalShown}
        onCancel={handleHideRequestMediaModal}
      />
      <MediaPlayModal />
    </>
  );
};
