import { FC } from "react";
import { ImagePlaceholder } from "../../assets";
import styles from "./ProductPhotoCell.module.css";

interface Props {
  name: string;
  imageId?: string;
  onPreview?: (imageId: string) => void;
}

export const ProductPhotoCell: FC<Props> = ({ name, imageId, onPreview }) => {
  const handleClick = () => {
    if (!onPreview || !imageId) return;
    onPreview(imageId);
  };

  return (
    <div className={styles.wrapper}>
      <span className={styles.container} onClick={handleClick}>
        {imageId ? (
          <img
            src={`https://imagedelivery.net/aVfW2-rBW-pPqTdLRDNMyA/${imageId}/public`}
            alt={name}
          />
        ) : (
          <ImagePlaceholder />
        )}
      </span>
      {name}
    </div>
  );
};
