import { AxiosPromise } from 'axios';
import { IApiList } from '../../app/types';
import { axios } from '../axios';
import { ICreateKeyDto, IKey, IKeyDetails, IKeysFilters, UpdateKeyDto } from './types';
import { getPagination, getSearchFilter } from '../../app/utils';

const getStatusFilter = (statuses: string) => {
  if (statuses.includes(",")) {
    return "";
  }
  if (statuses.includes("inactive")) {
    return "&active=false";
  }
  if (statuses.includes("active")) {
    return "&active=true";
  }
  return "";
};

const getPrivilegesFilter = (privileges: string) => {
  if (!privileges) {
    return "";
  }
  let query = "";
  privileges.split(",").forEach((status) => {
    query = `${query}&privilege=${status}`;
  });
  return query;
};

export const getKeys = (systemId: number, filters: IKeysFilters): AxiosPromise<IApiList<IKey>> => {
  const { page, pageSize, idOrNameFilter, statusFilter, privilegesFilter } = filters;
  return axios.get(`/keys?systemId=${systemId}${getPagination(page, pageSize)}${getSearchFilter(idOrNameFilter, "idOrName")}${getStatusFilter(statusFilter)}${getPrivilegesFilter(privilegesFilter)}`);
}

export const getKey = (keyId: string): AxiosPromise<IKeyDetails> => {
  return axios.get(`/keys/${keyId}`);
}

export const getActiveKeysCount = (systemId: number): AxiosPromise<IApiList<IKey>> => {
  return axios.get(`/keys?systemId=${systemId}&active=true&pageSize=10&page=1`);
}

export const updateKey = (keyId: number, key: UpdateKeyDto): AxiosPromise<IKey> => {
  return axios.patch(`/keys/${keyId}`, key);
}

export const createKey = (key: ICreateKeyDto): AxiosPromise<IKey> => {
  return axios.post('/keys', key);
}