import { IconFolderBlue, IconTrash, IconUpload } from "../../assets";
import { Button2, Caption } from "../Typography";
import { useTranslation } from "react-i18next";
import { ChangeEvent, FC, SyntheticEvent, useState } from "react";
import { LMIconButton } from "../LMIconButton";
import { useAppNotifications } from "../LMNotifications";
import styles from "./LMUpload.module.css";

const MAX_FILE_SIZE_MB = 10;

interface Props {
  value?: string;
  imageId?: string;
  onChange?: (value: string | undefined) => void;
  onClear?: () => void;
}

const toBase64 = async (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);

    reader.readAsDataURL(file);
  });
};

const getImageSrc = (value?: string, imageId?: string) => {
  if (value) {
    return value;
  }
  if (imageId) {
    return `https://imagedelivery.net/aVfW2-rBW-pPqTdLRDNMyA/${imageId}/public`;
  }
  return "";
};

export const LMUpload: FC<Props> = ({ value, imageId, onChange, onClear }) => {
  const { t } = useTranslation("layout");
  const [fileName, setFileName] = useState("");
  const { contextHolder, error } = useAppNotifications();

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    const maxFileSizeBites = MAX_FILE_SIZE_MB * 1024 * 1024;
    if (file.size > maxFileSizeBites) {
      error(t("fileSizeError", { size: MAX_FILE_SIZE_MB }));
      return;
    }
    setFileName(file.name);
    const fileBase64 = await toBase64(file);
    if (onChange) {
      onChange(fileBase64);
    }
  };

  const handleFileUploadClick = () => {
    document.getElementById("fileInput")?.click();
  };

  const handleDelete = (event: SyntheticEvent) => {
    event.stopPropagation();
    setFileName("");
    if (!onChange) return;
    onChange(undefined);
    if (!onClear) return;
    onClear();
  };

  return (
    <div className={styles.container} onClick={handleFileUploadClick}>
      {contextHolder}
      <input
        type="file"
        id="fileInput"
        onChange={handleChange}
        style={{ display: "none" }}
        accept="image/*"
      />
      {value || imageId ? (
        <span className={styles["image-container"]}>
          <img src={getImageSrc(value, imageId)} alt="Uploaded" />
        </span>
      ) : (
        <div className={styles["icon-container"]}>
          <IconUpload />
        </div>
      )}
      <div className={styles.info}>
        {fileName || imageId ? (
          <>
            {fileName && <Button2 className={styles.title}>{fileName}</Button2>}
          </>
        ) : (
          <>
            <Button2 className={styles.title}>{t("uploadTitle")}</Button2>
            <Caption className={styles.rule}>
              {t("uploadRule", { size: MAX_FILE_SIZE_MB })}
            </Caption>
          </>
        )}
      </div>
      {fileName || imageId ? (
        <div className={styles["browse-container"]}>
          <IconUpload />
          <LMIconButton onClick={handleDelete}>
            <IconTrash />
          </LMIconButton>
        </div>
      ) : (
        <div className={styles["browse-container"]}>
          <IconFolderBlue />
          <Button2 className={styles["browse-text"]}>{t("uploadTip")}</Button2>
        </div>
      )}
    </div>
  );
};
