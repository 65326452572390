import { SystemsDecorator } from "../../modules/systems";
import { System } from "./System";

export const SystemContainer = () => {
  return (
    <SystemsDecorator>
      {(system) => <System tenantId={system.tenantId} systemId={system.id} />}
    </SystemsDecorator>
  );
};
