import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import {
  fetchMediaItems,
  monitoringActions,
  EMonitoringViewType,
} from "../../modules/monitoring";
import styles from "./Monitoring.module.css";
import { MediaItemsFilters } from "../../modules/monitoring/MediaItemsFilters";
import { MediaItemsList } from "../../modules/monitoring/MediaItemsList";
import { MonitoringStreams } from "../../modules/monitoring/MonitoringStreams";

interface Props {
  systemId: number;
}

export const Monitoring: FC<Props> = ({ systemId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [loaded, setLoaded] = useState(false);
  const [searchParams] = useSearchParams();

  const { status, filters, list } = useSelector((state: RootState) => {
    return state.monitoring;
  });

  useEffect(() => {
    const start = searchParams.get("start");
    const end = searchParams.get("end");
    dispatch(
      monitoringActions.setFilters({
        user: searchParams.get("user") || "",
        statuses: searchParams.get("statuses") || "",
        types: searchParams.get("types") || "",
        viewType: searchParams.get("viewType") || EMonitoringViewType.library,
        dates: {
          start: start ? new Date(start) : null,
          end: end ? new Date(end) : null,
        },
      })
    );
    setLoaded(true);
  }, [searchParams, dispatch]);

  const getMediaItems = useCallback(() => {
    if (!loaded) return;
    dispatch(
      fetchMediaItems({
        systemId,
        filters,
      })
    );
  }, [systemId, filters, dispatch, loaded]);

  useEffect(() => {
    getMediaItems();
  }, [getMediaItems]);

  return (
    <div className={styles.container}>
      <div className={styles.filters}>
        <MediaItemsFilters filters={filters} />
      </div>
      <div className={styles.content}>
        {filters.viewType === EMonitoringViewType.library ? (
          <MediaItemsList
            list={list}
            filters={filters}
            status={status}
            systemId={systemId}
            onRefresh={getMediaItems}
          />
        ) : (
          <MonitoringStreams systemId={systemId} />
        )}
      </div>
    </div>
  );
};
