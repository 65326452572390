import { SegmentedValue } from "antd/es/segmented";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { IconMachineSpeed } from "../../../assets";
import { CardContainer } from "../../../components/CardContainer";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { IconWithBackground } from "../../../components/IconWithBackground";
import { LMNewRadio } from "../../indexing/LMNewRadio";
import { ISystemDto } from "../../systems";
import { updateSystem } from "../../systems/slice";
import { EMachineSpeedOptions } from "../../systems/types";
import { MachineSpeedConfirmationContent } from "./MachineSpeedConfirmationContent";
import { getIconBackgroundColor, getIconColor } from "./utils";

interface Props {
  system: ISystemDto;
}

export const MachineSpeed: FC<Props> = ({ system }) => {
  const [defaultValue, setDefaultValue] = useState(system.machineSpeed);
  const [value, setValue] = useState(system.machineSpeed);
  const { t } = useTranslation("machineSpeed");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const handleConfirm = () => {
    dispatch(
      updateSystem({ systemId: system.id, system: { machineSpeed: value } })
    );
    handleCloseConfirmationModal();
  };

  const handleAcceptNewOrdersUpdate = (updatedValue: SegmentedValue) => {
    setValue(updatedValue as EMachineSpeedOptions);
    setConfirmModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setValue(defaultValue);
    setConfirmModalOpen(false);
  };

  useEffect(() => {
    setDefaultValue(system.machineSpeed);
  }, [system.machineSpeed]);

  return (
    <>
      <CardContainer
        icon={
          <IconWithBackground
            icon={<IconMachineSpeed stroke={getIconColor(system)} />}
            color={getIconBackgroundColor(system)}
          />
        }
        title={t("title")}
        subtitle={t("subtitle")}
      >
        <LMNewRadio
          onChange={handleAcceptNewOrdersUpdate}
          options={[
            { label: t("low"), value: EMachineSpeedOptions.Low },
            { label: t("medium"), value: EMachineSpeedOptions.Medium },
            { label: t("fast"), value: EMachineSpeedOptions.Fast },
          ]}
          value={system.machineSpeed}
        />
      </CardContainer>
      <ConfirmationModal
        content={<MachineSpeedConfirmationContent />}
        confirmText={t("confirm")}
        open={confirmModalOpen}
        onConfirm={handleConfirm}
        onCancel={handleCloseConfirmationModal}
      />
    </>
  );
};
