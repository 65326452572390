import { splitCamelCase } from "../../app/utils";
import { IOrderWithMetadata, IProduct, IProductSku } from "./types";

export const sortSkus = (a: IProductSku, b: IProductSku) => {
  if (a.sku < b.sku) return -1;
  if (a.sku > b.sku) return 1;
  return 0;
};

export const extendByMetadata = (products: IProduct[]) => {
  const metadataList: { title: string, value: string }[] = [];
  const productsWithMetadata: IOrderWithMetadata[] = [];
  products.forEach(product => {
    if (!product.metadata) {
      productsWithMetadata.push(product as IOrderWithMetadata);
      return;
    }
    let currentProductMetadata = {};
    Object.keys(product.metadata).forEach(key => {
      currentProductMetadata = {
        ...currentProductMetadata,
        [key]: product.metadata[key]
      }
      Object.keys(product.metadata).forEach(metadataKey => {
        if (!product.metadata[metadataKey] || metadataList.some(metadataItem => metadataItem.value === metadataKey)) return;
        metadataList.push({ title: splitCamelCase(metadataKey), value: metadataKey });
      })
    });
    const productWithMetadata = {
      ...product,
      ...currentProductMetadata
    } as IOrderWithMetadata;
    productsWithMetadata.push(productWithMetadata);
  })
  return {
    metadataList,
    productsWithMetadata
  }
}