import { EControlMode, IControlCommand, TControlCommandGrouped } from "./types";

export const isControlModeAuto = (mode: EControlMode) => {
  return mode === EControlMode.Auto || mode === EControlMode.AutoSystemTerminated || mode === EControlMode.Idle;
}

export const groupCommandBySystem = (commands: IControlCommand[]): TControlCommandGrouped => {
  const grouped: TControlCommandGrouped = {};
  commands.forEach(command => {
    if (grouped[command.commandSystem]) {
      grouped[command.commandSystem].push(command);
      return;
    }
    grouped[command.commandSystem] = [command];
  });
  return grouped;
}