import { useEffect, useState } from "react";
import { H2 } from "../../components/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LMIconButton } from "../../components/LMIconButton";
import { IconBack } from "../../assets";
import { getIdFromPath, getRootFromPath, getTitleFromPath } from "./utils";
import { MaintenanceTabs } from "./MaintenanceTabs";
import styles from "./HeaderTitle.module.css";
import { ProductPortfolioTabs } from "./ProductPortfolioTabs";

export const HeaderTitle = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation("layout");
  const [title, setTitle] = useState(getTitleFromPath(location.pathname, t));
  const [id, setId] = useState(getIdFromPath(location.pathname));

  useEffect(() => {
    const updatedTitle = getTitleFromPath(location.pathname, t);
    const updatedId = getIdFromPath(location.pathname);
    setTitle(updatedTitle);
    setId(updatedId);
  }, [location, t]);

  const handleBack = () => navigate(-1);

  if (getRootFromPath(location.pathname) === "maintenance") {
    return <MaintenanceTabs />;
  }

  if (getRootFromPath(location.pathname) === "products-portfolio") {
    return <ProductPortfolioTabs />;
  }

  if (id) {
    return (
      <div className={styles.container}>
        <H2 className={styles["title-button"]}>
          <LMIconButton onClick={handleBack}>
            <IconBack />
          </LMIconButton>{" "}
          {title} <span className={styles.id}>({id})</span>
        </H2>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <H2>{title}</H2>
    </div>
  );
};
