import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { PageHeader } from "../../components";
import {
  fetchTransfers,
  transfersActions,
  TransfersList,
  TransferDetails,
} from "../../modules/transfers";
import styles from "./Transfers.module.css";
import { PageWrapper } from "../../components/PageWrapper";
import { useSearchParams } from "react-router-dom";

export const Transfers = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation("transfers");

  const { status, page, list, total, selectedTransferId } = useSelector(
    (state: RootState) => {
      return state.transfers;
    }
  );

  useEffect(() => {
    const transferId = searchParams.get("transferId");
    if (transferId) {
      dispatch(transfersActions.setSelectedTransferId(transferId));
    }
    return () => {
      dispatch(transfersActions.setSelectedTransferId());
    };
  }, [searchParams, dispatch]);

  const getTransfers = useCallback(() => {
    dispatch(fetchTransfers({ page }));
  }, [dispatch, page]);

  useEffect(() => {
    getTransfers();
  }, [getTransfers]);

  return (
    <PageWrapper>
      <PageHeader
        title={t("title")}
        searchFor="transfers"
        searchPlaceholder={t("searchPlaceholder")}
      />
      <div className={styles.container}>
        <div className={styles.list}>
          <TransfersList
            transfers={list}
            page={page}
            total={total}
            status={status}
          />
        </div>
        {selectedTransferId && (
          <TransferDetails transferId={selectedTransferId} />
        )}
      </div>
    </PageWrapper>
  );
};
