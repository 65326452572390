import { TFunction } from "i18next";

export const getTitleFromPath = (path: string, t: TFunction) => {
  const paths = path.split("/");
  if (paths.length <= 2) return "";
  const root = paths[paths.length - 2];
  if (root === "orders") {
    return t("orderDetail");
  }
  const titleKey = paths[paths.length - 1];
  return titleKey && titleKey.length > 2 ? t(titleKey) : t("home");
};

export const getIdFromPath = (path: string) => {
  const paths = path.split("/");
  const root = paths[paths.length - 2];
  if (root === "orders") {
    return paths[paths.length - 1];
  }
};

export const getRootFromPath = (path: string) => {
  const paths = path.split("/");
  const root = paths[paths.length - 2];
  return root;
};

export const getMaintenanceKey = (path: string) => {
  const paths = path.split("/");
  const root = paths[paths.length - 1];
  return root;
};