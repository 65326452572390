import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { EMediaType } from "../types";
import styles from "./MediaType.module.css";
import { IconMediaImage, IconMediaVideo } from "../../../assets";

interface Props {
  type: EMediaType;
}

export const MediaType: FC<Props> = ({ type }) => {
  const { t } = useTranslation("monitoring");
  return (
    <div className={styles.container}>
      {type === EMediaType.images ? <IconMediaImage /> : <IconMediaVideo />}
      {t(type.toLowerCase())}
    </div>
  );
};
