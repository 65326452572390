import { splitCamelCase } from "../../app/utils";
import { IStation, IStationProduct, IStationProductWithMetadata } from "./types";

export const sortPallets = (a: IStation, b: IStation) => {
  if (parseInt(a.location.substring(1)) < parseInt(b.location.substring(1))) return -1;
  if (parseInt(a.location.substring(1)) > parseInt(b.location.substring(1))) return 1;
  return 0;
};

export const extendByMetadata = (products: IStationProduct[]) => {
  const metadataList: { title: string, value: string }[] = [];
  const productsWithMetadata: IStationProductWithMetadata[] = [];
  products.forEach(product => {
    if (!product.variants) {
      productsWithMetadata.push({ ...product, key: product.productId } as IStationProductWithMetadata);
      return;
    }
    product.variants.forEach(variant => {
      Object.keys(variant.metadata).forEach(metadataName => {
        if (!metadataList.some(m => m.value === metadataName)) {
          metadataList.push({ title: splitCamelCase(metadataName), value: metadataName })
        }
      })
      const productWithMetadata = {
        ...product,
        ...variant.metadata,
        quantity: variant.quantity,
        key: Math.floor((Math.random() * 100000) + 1),
      } as IStationProductWithMetadata;
      productsWithMetadata.push(productWithMetadata);
    })
  })
  return {
    metadataList,
    productsWithMetadata
  }
}