import { FC } from "react";
import { IStation } from "../types";
import { Station, StationGap } from "../Station";
import { sortPallets } from "../utils";
import { Nullable } from "../../../app/types";
import { useSearchParams } from "react-router-dom";

interface Props {
  stations: IStation[];
  selectedStation: Nullable<IStation>;
  side: "A" | "B";
}

export const getStationLength = (stations: IStation[], side: "A" | "B") => {
  const filteredStations = stations
    .filter((s) => s.location.includes(side))
    .slice();
  filteredStations.sort(sortPallets);
  const last = filteredStations[filteredStations.length - 1];
  if (!last) return 0;
  const lastLength = last.location.substring(1);
  return parseInt(lastLength);
};

export const SystemStationsLine: FC<Props> = ({
  stations,
  selectedStation,
  side,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handlePalletSelect = (station: IStation) => {
    if (!station.palletId) return;
    searchParams.set("palletId", station.palletId);
    setSearchParams(searchParams);
  };

  const renderStationByIndex = (index: number) => {
    const station = stations.find((s) => s.location === `${side}${index}`);
    if (station) {
      return (
        <Station
          key={station.palletId}
          station={station}
          isSelected={selectedStation?.palletId === station.palletId}
          onSelect={handlePalletSelect}
        />
      );
    }
    return <StationGap key={index} />;
  };

  return (
    <>
      {new Array(getStationLength(stations, side))
        .fill(null)
        .map((_, index) => renderStationByIndex(index + 1))}
    </>
  );
};
