import { FC } from "react";
import { Tag } from "antd";
import classNames from "classnames/bind";
import { SeverityLabels } from "../types";
import styles from "./MonitorsList.module.css";

interface Props {
  severity: SeverityLabels;
}

const cx = classNames.bind(styles);

export const SeverityLabel: FC<Props> = ({ severity }) => {
  return (
    <Tag
      className={cx({
        label: true,
        error: severity === SeverityLabels.error,
        info: severity === SeverityLabels.info,
        warning: severity === SeverityLabels.warning,
      })}
    >
      {severity}
    </Tag>
  );
};
