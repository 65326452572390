import { FC, useCallback, useContext, useEffect } from "react";
import hlsjs from "hls.js";
import styles from "./MediaStream.module.css";
import { Auth0Context } from "@auth0/auth0-react";
import { getToken } from "../../auth";
import { capitalizeFirst } from "../../../app/utils";

interface Props {
  camera: string;
  stream: string;
}

export const MediaStream: FC<Props> = ({ camera, stream }) => {
  const { getAccessTokenSilently } = useContext(Auth0Context);

  const handleInitHls = useCallback(async () => {
    if (!hlsjs.isSupported()) return;

    const video = document.getElementById(stream) as HTMLMediaElement;

    if (!video) return;

    const token = await getToken(getAccessTokenSilently);

    if (!token) return;

    const hls = new hlsjs({
      xhrSetup: (xhr) => {
        xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      },
    });
    hls.loadSource(stream);
    hls.attachMedia(video);
    return hls;
  }, [stream, getAccessTokenSilently]);

  useEffect(() => {
    handleInitHls();
  }, [handleInitHls]);

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{capitalizeFirst(camera)}</h3>
      <video
        preload="auto"
        className={styles.video}
        controls
        autoPlay
        muted
        id={stream}
      />
    </div>
  );
};
