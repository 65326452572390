import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";
import generatePicker, {
  PickerProps,
} from "antd/es/date-picker/generatePicker";
import "antd/es/date-picker/style/index";
import { FC } from "react";
import styles from "./LMDatePicker.module.css";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

export const Picker = generatePicker<Date>(dateFnsGenerateConfig);

interface Props extends Omit<PickerProps<Date>, "picker"> {
  value?: Date;
  size?: "small" | "large";
}

export const LMDatePicker: FC<Props> = ({
  className,
  size = "small",
  ...rest
}) => {
  return (
    <Picker
      {...rest}
      className={`${cx({ picker: true, [size]: true })} ${className || ""}`}
      format={"MMM dd, yyyy"}
    />
  );
};

export const LMRangePicker = Picker.RangePicker;
