import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Body2, H3, Label } from "../../../components/Typography";
import { ISystemsState } from "../../systemAttributes";
import { IStation } from "../types";
import styles from "./SystemStations.module.css";
import {
  IconSystemBuffers,
  IconSystemCompressor,
  IconSystemPackerStock,
  IconSystemStations,
  IconSystemTemperature,
} from "../../../assets";
import { ApiStatuses } from "../../../app/types";

const getStateValueByName = (name: string, state: ISystemsState) => {
  const entity = Object.values(state)
    .flat()
    .find((e) => e.name === name);
  if (!entity) return "";
  return entity.valueDisplay;
};

const isStateEntityExists = (name: string, state: ISystemsState) => {
  const exists = Object.values(state).some((e) => e.name === name);
  return exists;
};

interface Props {
  state: ISystemsState;
  stationsStatus: ApiStatuses;
  stations: IStation[];
}

export const SystemStationsHeader: FC<Props> = ({
  state,
  stations,
  stationsStatus,
}) => {
  const { t } = useTranslation("system");

  return (
    <div className={styles.header}>
      {stationsStatus === ApiStatuses.success && (
        <div className={styles["header-item-container"]}>
          <div className={styles["header-icon-container"]}>
            <IconSystemStations />
          </div>
          <div className={styles["header-item-content"]}>
            <div className={styles["header-item-content-value"]}>
              <H3>{stations.filter((s) => s.palletId).length}</H3>
              <Body2 className={styles.light}>/{stations.length}</Body2>
            </div>
            <Label className={styles["header-item-content-label"]}>
              {t("stations")}
            </Label>
          </div>
        </div>
      )}
      <div className={styles["header-item-container"]}>
        <div className={styles["header-icon-container"]}>
          <IconSystemCompressor />
        </div>
        <div className={styles["header-item-content"]}>
          <div className={styles["header-item-content-value"]}>
            <H3>{getStateValueByName("Compressor", state)}</H3>
          </div>
          <Label className={styles["header-item-content-label"]}>
            {t("compressor")}
          </Label>
        </div>
      </div>
      {isStateEntityExists("Buffer", state) && (
        <div className={styles["header-item-container"]}>
          <div className={styles["header-icon-container"]}>
            <IconSystemBuffers />
          </div>
          <div className={styles["header-item-content"]}>
            <div className={styles["header-item-content-value"]}>
              <H3>{getStateValueByName("Buffer", state)}</H3>
            </div>
            <Label className={styles["header-item-content-label"]}>
              {t("buffers")}
            </Label>
          </div>
        </div>
      )}
      <div className={styles["header-item-container"]}>
        <div className={styles["header-icon-container"]}>
          <IconSystemPackerStock />
        </div>
        <div className={styles["header-item-content"]}>
          <div className={styles["header-item-content-value"]}>
            <H3>{getStateValueByName("PackerSupply", state)}</H3>
          </div>
          <Label className={styles["header-item-content-label"]}>
            {t("packerStock")}
          </Label>
        </div>
      </div>
      <div className={styles["header-item-container"]}>
        <div className={styles["header-icon-container"]}>
          <IconSystemTemperature />
        </div>
        <div className={styles["header-item-content"]}>
          <div className={styles["header-item-content-value"]}>
            <H3>{getStateValueByName("Temperature", state)}</H3>
          </div>
          <Label className={styles["header-item-content-label"]}>
            {t("temperature")}
          </Label>
        </div>
      </div>
    </div>
  );
};
