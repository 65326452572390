import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { ApiStatuses, IApiList } from '../../app/types';
import { getIndexingRequestDetails, getIndexingRequestsList, getMaintenancesForIndexing, deleteIndexingRequest as _deleteIndexingRequest } from './api';
import { IIndexingRequest, IIndexingSliceState } from './types';

export const initialState: IIndexingSliceState = {
  status: ApiStatuses.initial,
  detailsStatus: ApiStatuses.success,
  isCreateDrawerOpen: false,
  total: 0,
  page: 1,
  list: [],
  maintenances: [],
};

export const fetchList = createAsyncThunk(
  'indexing/fetchList',
  async ({ systemId, page }: { systemId: number, page: number }) => {
    const response = await getIndexingRequestsList(systemId, page, 10);
    return response.data;
  }
);

export const fetchMaintenancesForIndexing = createAsyncThunk(
  'indexing/fetchMaintenancesForIndexing',
  async ({ systemId }: { systemId: number }) => {
    const response = await getMaintenancesForIndexing(systemId);
    return response.data;
  }
);

export const refreshList: AsyncThunk<IApiList<IIndexingRequest>, { systemId: number; }, {}> = createAsyncThunk(
  'indexing/refreshList',
  async ({ systemId }, { getState }) => {
    const state = getState() as RootState;
    const response = await getIndexingRequestsList(systemId, state.indexing.page, 10);
    return response.data;
  }
);

export const fetchIndexingRequest = createAsyncThunk(
  'indexing/fetchIndexingRequest',
  async ({ requestId }: { requestId: number }) => {
    const response = await getIndexingRequestDetails(requestId);
    return response.data;
  }
);

export const deleteIndexingRequest = createAsyncThunk(
  'indexing/deleteIndexingRequest',
  async ({ requestId }: { requestId: number }) => {
    const response = await _deleteIndexingRequest(requestId);
    return response.data;
  }
);

const slice = createSlice({
  name: "indexing",
  initialState,
  reducers: {
    setPage(state , action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setIsCreateDrawerOpen(state , action: PayloadAction<boolean>) {
      state.isCreateDrawerOpen = action.payload;
    },
    setIndexingRequest(state , action: PayloadAction<number | undefined>) {
      state.selectedIndexingRequestId = action.payload;
    },
  },
  extraReducers: (builder) => builder
    // fetchList
    .addCase(fetchList.pending, (state) => {
      state.status = ApiStatuses.loading;
    })
    .addCase(fetchList.fulfilled, (state, action) => {
      state.status = ApiStatuses.success;
      state.list = action.payload.values;
      state.total = action.payload.count;
    })
    .addCase(fetchList.rejected, (state) => {
      state.status = ApiStatuses.fail;
    })
    // fetchIndexingRequest
    .addCase(fetchIndexingRequest.pending, (state) => {
      state.detailsStatus = ApiStatuses.loading;
    })
    .addCase(fetchIndexingRequest.fulfilled, (state, action) => {
      state.detailsStatus = ApiStatuses.success;
      state.requestDetails = action.payload;
    })
    .addCase(fetchIndexingRequest.rejected, (state) => {
      state.detailsStatus = ApiStatuses.fail;
    })
    // refreshList
    .addCase(refreshList.fulfilled, (state, action) => {
      state.status = ApiStatuses.success;
      state.list = action.payload.values;
      state.total = action.payload.count;
    })
    // fetchMaintenancesForIndexing
    .addCase(fetchMaintenancesForIndexing.fulfilled, (state, action) => {
      state.maintenances = action.payload.values;
    })
});

export const indexing = slice.reducer;
export const actions = slice.actions;