import { Routes as Switch, Route } from "react-router-dom";
import { ProtectedRoute } from "../../modules/auth";
import { Orders } from "../../pages/Orders";
import { System } from "../../pages/System";
import { Replenishment } from "../../pages/Replenishment";
import { Transfers } from "../../pages/Transfers";
import { Monitoring } from "../../pages/Monitoring";
import { AbilityContext } from "../../modules/casl";
import { FC, useContext } from "react";
import { RouteNotMatch } from "../../modules/navigation";
import { Indexing } from "../../pages/Indexing";
import { Home } from "../../pages/Home";
import { KeyList } from "../../pages/KeyList";
import { Order } from "../../pages/Order";
import { ProductsPortfolio } from "../../pages/ProductsPortfolio";
import { Store } from "../../pages/Store";
import { Control } from "../../pages/Control";
import { Support } from "../../pages/Support";
import { Metadata } from "../../pages/Metadata";
import { Warranty } from "../../pages/Warranty";

const isProduction = process.env.REACT_APP_ENV === "production";

export const Routes: FC = () => {
  const ability = useContext(AbilityContext);
  const params = isProduction ? "/:client/:systemId" : "/:systemId";
  return (
    <Switch>
      <Route path="*" element={<RouteNotMatch />} />
      <Route path="/warranty" element={<Warranty />} />
      <Route path={params} element={<ProtectedRoute component={Home} />} />
      <Route
        path={`${params}/orders`}
        element={<ProtectedRoute component={Orders} />}
      />
      <Route
        path={`${params}/orders/:orderId`}
        element={<ProtectedRoute component={Order} />}
      />
      <Route
        path={`${params}/maintenance/replenishment`}
        element={<ProtectedRoute component={Replenishment} />}
      />
      <Route
        path={`${params}/maintenance/transfers`}
        element={<ProtectedRoute component={Transfers} />}
      />
      <Route
        path={`${params}/maintenance/indexing`}
        element={<ProtectedRoute component={Indexing} />}
      />
      <Route
        path={`${params}/maintenance/keys`}
        element={<ProtectedRoute component={KeyList} />}
      />
      <Route
        path={`${params}/system`}
        element={<ProtectedRoute component={System} />}
      />
      <Route
        path={`${params}/products-portfolio/portfolio`}
        element={<ProtectedRoute component={ProductsPortfolio} />}
      />
      <Route
        path={`${params}/products-portfolio/metadata`}
        element={<ProtectedRoute component={Metadata} />}
      />
      <Route
        path={`${params}/control`}
        element={<ProtectedRoute component={Control} />}
      />
      <Route
        path={`${params}/support`}
        element={<ProtectedRoute component={Support} />}
      />
      {ability.can("create", "orders") ? (
        <Route
          path={`${params}/store`}
          element={<ProtectedRoute component={Store} />}
        />
      ) : (
        <></>
      )}
      {ability.can("get", "media") ? (
        <Route
          path={`${params}/monitoring`}
          element={<ProtectedRoute component={Monitoring} />}
        />
      ) : (
        <></>
      )}
    </Switch>
  );
};
