import { ColumnsType } from "antd/lib/table";
import { TFunction } from "react-i18next";
import { IMaintenanceProduct, IOperation, IStockCompareProduct } from "../types";

export const getMainColumns = (t: TFunction): ColumnsType<IStockCompareProduct> => (
  [
    {
      title: t("name"),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t("sku"),
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: t("diff"),
      dataIndex: 'diff',
      key: 'diff',
    },
  ]
);

export const getOperationsColumns = (t: TFunction): ColumnsType<IOperation> => ([
  {
    title: t("operation"),
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: t("palletId"),
    dataIndex: 'palletId',
    key: 'palletId'
  },
  {
    title: t("transferId"),
    dataIndex: 'shippingCode',
    key: 'shippingCode'
  }
]);

export const getProductsColumns = (t: TFunction): ColumnsType<IMaintenanceProduct> => ([
  {
    title: t("sku"),
    key: "sku",
    render: (product: IMaintenanceProduct) => {
      return product.product.sku;
    }
  },
  {
    title: t("name"),
    key: "name",
    render: (product: IMaintenanceProduct) => {
      return product.product.name;
    }
  },
  {
    title: t("quantity"),
    key: "quantity",
    dataIndex: 'quantity'
  }
]);

export const comparator = ( a: IStockCompareProduct, b: IStockCompareProduct) => {
  if ( a.name < b.name ){
    return -1;
  }
  if ( a.name > b.name ){
    return 1;
  }
  return 0;
}