import { ColumnType } from "antd/lib/table";
import { TFunction } from "i18next";
import { IIndexCellProduct, IIndexPalletRequest } from "../types";
import styles from "./IndexingDetails.module.css";
import { IconCheck, IconTimes } from "../../../assets";

export const getCompareTableColumns = (
  t: TFunction
): ColumnType<IIndexCellProduct>[] => {
  return [
    {
      title: t("palletId") as string,
      dataIndex: "palletId",
      key: "palletId",
    },
    {
      title: t("row") as string,
      dataIndex: "slotLevel",
      key: "slotLevel",
    },
    {
      title: t("column") as string,
      dataIndex: "slotColumn",
      key: "slotColumn",
    },
    {
      title: t("sku") as string,
      key: "sku",
      render: (cellProduct: IIndexCellProduct) => {
        if (
          !cellProduct.after.sku ||
          cellProduct.before.sku === cellProduct.after.sku
        ) {
          return cellProduct.before.sku;
        }
        return `${cellProduct.before.sku}→${cellProduct.after.sku}`;
      },
    },
    {
      title: t("quantity") as string,
      key: "quantity",
      render: (cellProduct: IIndexCellProduct) => {
        const { before, after } = cellProduct;
        if (before.quantity === after.quantity) {
          return after.quantity;
        }
        if (before.quantity > after.quantity) {
          return (
            <>
              {after.quantity}(
              <span className={styles.red}>
                {after.quantity - before.quantity}
              </span>
              )
            </>
          );
        }
        return (
          <>
            {after.quantity}(
            <span className={styles.green}>
              +{after.quantity - before.quantity}
            </span>
            )
          </>
        );
      },
    },
    {
      title: t("validity") as string,
      dataIndex: "valid",
      key: "valid",
      render: (validity: boolean) => {
        if (validity) {
          return <IconCheck stroke="var(--color-green-80)" />;
        }
        return <IconTimes />;
      },
    },
    {
      title: t("error") as string,
      dataIndex: "error",
      key: "error",
    },
  ];
};

export const getRequestsTableColumns = (
  t: TFunction
): ColumnType<IIndexPalletRequest>[] => {
  return [
    {
      title: t("palletId") as string,
      dataIndex: "palletId",
      key: "palletId",
    },
    {
      title: t("side") as string,
      dataIndex: "side",
      key: "side",
    },
    {
      title: t("row") as string,
      dataIndex: "row",
      key: "row",
    },
    {
      title: t("column") as string,
      dataIndex: "column",
      key: "column",
    },
  ];
};

export const calculateTotalForType = (
  cells: IIndexCellProduct[],
  valid: boolean
): number => {
  let number = 0;
  cells.forEach((cell) => {
    if (cell.valid === valid) {
      number += 1;
    }
  });
  return number;
};
