import { useCallback, useContext, useState } from "react";
import { ApiStatuses } from "../../app/types";
import { AbilityBuilder, AnyMongoAbility, Ability } from "@casl/ability";
import { Auth0Context } from "@auth0/auth0-react";
import jwt_decode from "jwt-decode";
import { axios } from "./utils";
import { useEffect } from "react";
import { useAbility } from "@casl/react";
import { AbilityContext } from "../casl";
import { LoaderWrapper } from "../../components";
import {
  authActions,
  getToken,
  all_clients,
  clients,
  getClientNameFromScope,
  getEnvironment,
  LogoutModal,
} from "../auth";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import { AppSystemContainer } from "../systems";
import { getClientNameFromLocation } from "../../app/utils";
import { setEnvironment } from "../auth/utils";
import { AxiosHeaders } from "axios";

const isProduction = process.env.REACT_APP_ENV === "production";

interface IToken {
  permissions: string[];
}

const setupAbilities = (scopes: string[], ability: AnyMongoAbility) => {
  const { can, rules } = new AbilityBuilder(Ability);
  scopes.forEach((scope) => {
    const values = scope.split(/:(.*)/s);
    /**
     * manage is reserved word in CASL, it allows all actions.
     */
    let action = values[1];
    const target = values[0];
    if (action === "admin") {
      action = "manage";
      can(action, target);
      return;
    }
    if (action === "manage") {
      action = "managing";
    }
    can(action, target);
  });
  ability.update(rules);
};

const WithAxios = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  const handleSetAllClientsAccess = useCallback(
    (scopes: string[]) => {
      // Should work only on production
      if (!isProduction) return;
      const clientNameFromLocation = getClientNameFromLocation();
      const isParamInClients = clients.some(
        (c) => c === `clients:${clientNameFromLocation}`
      );
      const isPermissions = scopes.some(
        (p) =>
          p === `clients:${clientNameFromLocation}` || all_clients.includes(p)
      );
      const isAllClientsAccess = scopes.some((s) => s === all_clients[0]);
      dispatch(authActions.setClientsAllAccess(isAllClientsAccess));
      // Check location for client name and check if it exists in permissions
      if (isParamInClients && isPermissions) {
        setEnvironment(`clients:${clientNameFromLocation}`);
        axios.defaults.baseURL = `https://api.${clientNameFromLocation}.1mrobotics.com/`;
        return;
      }
      const environment = getEnvironment();
      // Check local storage and check if user has access to all clients
      if (isAllClientsAccess && environment) {
        const client = getClientNameFromScope(environment);
        axios.defaults.baseURL = `https://api.${client}.1mrobotics.com/`;
        return;
      }
      // For all clients select the first client from the list
      if (isAllClientsAccess) {
        setEnvironment(clients[0]);
        const defaultClient = getClientNameFromScope(clients[0]);
        axios.defaults.baseURL = `https://api.${defaultClient}.1mrobotics.com/`;
        return;
      }
      const clientScope = scopes.find(
        (s) => s === clients.find((c) => c === s)
      );
      // Set env of the client received in scopes
      if (clientScope) {
        setEnvironment(clientScope);
        const client = getClientNameFromScope(clientScope);
        axios.defaults.baseURL = `https://api.${client}.1mrobotics.com/`;
      }
    },
    [dispatch]
  );

  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
  } = useContext(Auth0Context);
  const ability = useAbility(AbilityContext);

  const loadToken = useCallback(async () => {
    if (isLoading) return;
    if (!isAuthenticated) {
      setLoading(false);
      return loginWithRedirect();
    }
    const accessToken = await getToken(getAccessTokenSilently);

    setToken(accessToken);
  }, [getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect]);

  useEffect(() => {
    if (!token) return;
    axios.interceptors.request.use(
      async (config) => {
        const newHeaders = {
          ...(config.headers || {}),
          Authorization: `Bearer ${token}`,
        } as unknown as AxiosHeaders;
        return {
          ...config,
          headers: newHeaders,
        };
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      async (config) => {
        return config;
      },
      (error) => {
        if (error.response?.status === 401) {
          setLogoutModalOpen(true);
        }
        return Promise.reject(error);
      }
    );
    setLoading(false);
  }, [token]);

  useEffect(() => {
    loadToken();
  }, [loadToken]);

  const setupAbilityByToken = useCallback(async () => {
    if (!token) return;
    const tokenDecoded: IToken = jwt_decode(token);
    handleSetAllClientsAccess(tokenDecoded.permissions);
    setupAbilities(tokenDecoded.permissions, ability);
  }, [token, ability, handleSetAllClientsAccess]);

  useEffect(() => {
    setupAbilityByToken();
  }, [setupAbilityByToken]);

  return (
    <>
      <LoaderWrapper
        status={loading ? ApiStatuses.loading : ApiStatuses.success}
      >
        {!loading ? <AppSystemContainer /> : <></>}
      </LoaderWrapper>
      <LogoutModal
        open={logoutModalOpen}
        onClose={() => setLogoutModalOpen(false)}
      />
    </>
  );
};

export default WithAxios;
