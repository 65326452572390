import { FC, useState } from "react";
import Input from "react-phone-number-input";
import "react-phone-number-input/style.css";
import styles from "./PhoneInput.module.css";
import classNames from "classnames/bind";
import { FormInstance } from "antd";

const cx = classNames.bind(styles);

interface Props<T> {
  onChange?: (value: number) => void;
  formInstance?: FormInstance<T>;
  name?: string;
  id?: string;
  value?: string;
}

export const PhoneInput: FC<Props<unknown>> = ({
  onChange,
  formInstance,
  name,
  id,
  value,
}) => {
  const [focused, setFocused] = useState(false);

  const handleBlur = () => setFocused(!focused);

  const handleFocus = () => setFocused(true);

  const handleChange = (value: unknown) => {
    if (onChange) {
      onChange(value as number);
    }
    if (formInstance && name) {
      formInstance.setFieldValue(name, value);
    }
  };

  return (
    <div className={cx({ container: true, focused })}>
      <Input
        international
        countryCallingCodeEditable={false}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        value={value}
        id={id}
      />
    </div>
  );
};
