import { initTranslation } from "./modules/i18n";
import { WithAuth } from "./modules/auth/WithAuth";
import WithAxios from "./modules/axios/WithAxios";

const DEFAULT_LANGUAGE = "en";

initTranslation(DEFAULT_LANGUAGE);

export const App = () => {
  return (
    <WithAuth>
      <WithAxios />
    </WithAuth>
  );
};
