import classNames from "classnames/bind";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button2 } from "../../../components/Typography";
import { EOrderStatus, EOrderGroups } from "../types";
import { getColorByStatus } from "../utils";
import styles from "./OrdersBoard.module.css";

const cx = classNames.bind(styles);

interface Props {
  status: EOrderGroups;
  total: number;
}

export const OrderBoardHeader: FC<Props> = ({ status, total }) => {
  const { t } = useTranslation("order");
  return (
    <div
      className={cx({
        "head-container": true,
        [getColorByStatus(status as unknown as EOrderStatus)]: true,
      })}
    >
      <div className={styles.head}>
        <div className={styles.dot} />
        <Button2 className={styles.status}>{t(status)}</Button2>
        <Button2 className={styles.total}>{total}</Button2>
      </div>
      <div className={styles.line} />
    </div>
  );
};
