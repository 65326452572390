import { FC, SyntheticEvent, useCallback, useEffect, useMemo } from "react";
import Table, { ColumnType } from "antd/lib/table";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { DATE_TIME_FORMAT } from "../../../app/constants";
import { ApiStatuses } from "../../../app/types";
import { IIndexingRequest, IndexingRequestStatus } from "../types";
import {
  actions,
  deleteIndexingRequest,
  fetchList,
  refreshList,
} from "../slice";
import { AppDispatch, RootState } from "../../../app/store";
import { IndexingRequestStatusTag } from "../IndexingRequestStatusTag";
import styles from "./IndexingList.module.css";
import { Button } from "antd";

interface Props {
  systemId: number;
}

export const IndexingList: FC<Props> = ({ systemId }) => {
  const { t } = useTranslation("indexing");
  const dispatch = useDispatch<AppDispatch>();
  const [, setSearchParams] = useSearchParams();

  const { list, page, total, status } = useSelector((state: RootState) => {
    return state.indexing;
  });

  const handleFetchIndexingList = useCallback(() => {
    dispatch(fetchList({ systemId, page }));
  }, [systemId, dispatch, page]);

  const handleDeleteIndexingRequest = useCallback(
    async (event: SyntheticEvent, requestId: number) => {
      event.stopPropagation();
      await dispatch(deleteIndexingRequest({ requestId }));
      await dispatch(refreshList({ systemId }));
    },
    [systemId, dispatch]
  );

  useEffect(() => {
    handleFetchIndexingList();
  }, [handleFetchIndexingList]);

  const columns = useMemo(
    () => [
      {
        title: t("id"),
        dataIndex: "id",
        key: "id",
      },
      {
        title: t("trigger"),
        dataIndex: "triggerType",
        key: "triggerType",
      },
      {
        title: t("status"),
        dataIndex: "status",
        render: (status: IndexingRequestStatus) => (
          <IndexingRequestStatusTag status={status} />
        ),
        key: "status",
      },
      {
        title: t("createdOn"),
        dataIndex: "createdOn",
        key: "createdOn",
        render: (time: string) => format(new Date(time), DATE_TIME_FORMAT),
      },
      {
        title: "",
        key: "action",
        render: (request: IIndexingRequest) => (
          <Button
            danger
            onClick={(event) => handleDeleteIndexingRequest(event, request.id)}
          >
            {t("delete")}
          </Button>
        ),
      },
    ],
    [t, handleDeleteIndexingRequest]
  ) as ColumnType<IIndexingRequest>[];

  const handlePageChange = (updatedPage: number) => {
    dispatch(actions.setPage(updatedPage));
  };

  const handleRowClick = (indexingRequest: IIndexingRequest) => {
    setSearchParams({ requestId: indexingRequest.id.toString() });
    dispatch(actions.setIndexingRequest(indexingRequest.id));
  };

  const onRow = (indexingRequest: IIndexingRequest) => ({
    onClick: () => handleRowClick(indexingRequest),
    style: {
      cursor: "pointer",
    },
  });

  return (
    <div className={styles.container}>
      <Table
        dataSource={list}
        rowKey="id"
        columns={columns}
        onRow={onRow}
        loading={status === ApiStatuses.loading}
        pagination={{
          showSizeChanger: false,
          size: "small",
          current: page,
          onChange: handlePageChange,
          pageSize: 10,
          total,
        }}
      />
    </div>
  );
};
