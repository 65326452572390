import { Form } from "antd";
import { forwardRef, useImperativeHandle } from "react";
import { EMediaType, IRequestMediaFormValues } from "../types";
import styles from "./RequestMediaModal.module.css";
import { useTranslation } from "react-i18next";
import {
  LMDatePicker,
  LMDropdown,
  LMTextarea,
  LMTimePicker,
} from "../../../components";
import { Label } from "../../../components/Typography";
import { LMIconButton } from "../../../components/LMIconButton";
import { IconTrash } from "../../../assets";

interface Props {
  index: number;
  isRemovable: boolean;
  refId: number;
  onRemove: (index: number) => void;
  onSubmit: (index: number, values: IRequestMediaFormValues) => void;
}

interface RefProps {
  submit: () => void;
}

export const RequestMediaForm = forwardRef<RefProps, Props>(
  ({ index, onSubmit, isRemovable, onRemove, refId }, ref) => {
    const { t } = useTranslation("monitoring");
    const [form] = Form.useForm<IRequestMediaFormValues>();

    const mediaTypes = [
      { name: t(EMediaType.images.toLowerCase()), value: EMediaType.images },
      { name: t(EMediaType.videos.toLowerCase()), value: EMediaType.videos },
    ];

    const durationItems = [
      { name: `1 ${t("minute")}`, value: "1" },
      { name: `2 ${t("minutes")}`, value: "2" },
      { name: `3 ${t("minutes")}`, value: "3" },
      { name: `4 ${t("minutes")}`, value: "4" },
      { name: `5 ${t("minutes")}`, value: "5" },
    ];

    const disabledDate = (current: Date) => {
      return current > new Date();
    };

    useImperativeHandle(ref, () => ({
      submit() {
        form.submit();
      },
    }));

    const handleSubmit = (values: IRequestMediaFormValues) =>
      onSubmit(index, values);

    const handleRemove = () => onRemove(refId);

    return (
      <Form
        layout={"vertical"}
        form={form}
        autoComplete="off"
        onFinish={handleSubmit}
      >
        <main className={styles["form-content"]}>
          <div className={styles["form-header"]}>
            <Label>
              {t("media")} {index + 1}
            </Label>
            {isRemovable && (
              <LMIconButton onClick={handleRemove}>
                <IconTrash />
              </LMIconButton>
            )}
          </div>
          <div className={styles["form-row"]}>
            <div className={styles["type-container"]}>
              <Form.Item
                label={t("type")}
                name="mediaType"
                rules={[{ required: true, message: t("requiredError") }]}
              >
                <LMDropdown
                  items={mediaTypes}
                  placeholder={t("typePlaceholderForm")}
                />
              </Form.Item>
            </div>
            <div className={styles["date-container"]}>
              <Form.Item
                label={t("date")}
                name="date"
                rules={[{ required: true, message: t("requiredError") }]}
              >
                <LMDatePicker
                  size="large"
                  allowClear={false}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </div>
            <div className={styles["time-container"]}>
              <Form.Item
                label={t("time")}
                name="time"
                rules={[{ required: true, message: t("requiredError") }]}
              >
                <LMTimePicker size="large" allowClear={false} />
              </Form.Item>
            </div>
            <div className={styles["duration-container"]}>
              <Form.Item
                label={t("duration")}
                name="duration"
                rules={[{ required: true, message: t("requiredError") }]}
              >
                <LMDropdown
                  items={durationItems}
                  placeholder={t("durationPlaceholderForm")}
                />
              </Form.Item>
            </div>
          </div>
          <div className={styles["textarea-container"]}>
            <Form.Item
              label={t("details")}
              name="details"
              rules={[{ required: true, message: t("requiredError") }]}
            >
              <LMTextarea placeholder={t("detailsPlaceholder")} />
            </Form.Item>
          </div>
        </main>
      </Form>
    );
  }
);
