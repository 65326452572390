import {
  FC,
  HTMLProps,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { FormInstance } from "antd";
import { LMIconButton } from "../LMIconButton";
import { IconMinus, IconPlus } from "../../assets";
import styles from "./LMInputNumberIncremental.module.css";

interface Props<T> extends HTMLProps<HTMLInputElement> {
  icon?: ReactNode;
  max?: number;
  formInstance?: FormInstance<T>;
  onValueChange: (value: number) => void;
  onRemove?: () => void;
}

export const LMInputNumberIncremental: FC<Props<unknown>> = ({
  icon,
  value: _value,
  max,
  onValueChange,
  onRemove,
  name,
  ...rest
}) => {
  const [value, setValue] = useState(_value ? Number(_value) : 0);

  const increment = () => {
    const updatedValue = max && value + 1 > max ? max : value + 1;
    setValue(updatedValue);
    onValueChange(updatedValue);
  };

  useEffect(() => {
    if (value === _value) return;
    setValue(Number(_value));
  }, [_value, value]);

  const decrement = () => {
    const updatedValue = value - 1 < 0 ? 0 : value - 1;
    if (updatedValue === 0 && onRemove) {
      onRemove();
      return;
    }
    setValue(updatedValue);
    onValueChange(updatedValue);
  };

  const handleChange = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    let updatedValue = Number(target.value);
    if (max && updatedValue > max) {
      updatedValue = max;
    }
    if (updatedValue < 0) {
      updatedValue = 0;
    }
    setValue(updatedValue);
    onValueChange(updatedValue);
  };

  return (
    <div className={styles.container}>
      <LMIconButton className={styles["icon-minus"]} onClick={decrement}>
        <IconMinus stroke="var(--color-gray)" />
      </LMIconButton>
      <input
        {...rest}
        autoComplete="off"
        className={styles.input}
        onChange={handleChange}
        value={value}
        type="number"
      />
      <LMIconButton className={styles["icon-plus"]} onClick={increment}>
        <IconPlus stroke="var(--color-gray)" />
      </LMIconButton>
    </div>
  );
};
