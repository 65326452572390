import { FC, ReactElement } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { LoaderWrapper } from "../../components";
import { ISystemDto } from "./types";

interface Props {
  children: (selectedSystem: ISystemDto) => ReactElement;
}

export const SystemsDecorator: FC<Props> = ({ children }) => {
  const status = useSelector((state: RootState) => {
    return state.systems.status;
  });
  const selectedSystem = useSelector((state: RootState) => {
    return state.systems.selectedSystem;
  });
  return (
    <LoaderWrapper status={status}>
      {selectedSystem ? children(selectedSystem) : <></>}
    </LoaderWrapper>
  );
};
