import { FC } from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { Button2 } from "../../../components/Typography";
import { EMediaStatus } from "../types";
import { getColorByStatus } from "./utils";
import styles from "./MediaStatus.module.css";

const cx = classNames.bind(styles);

interface Props {
  status: EMediaStatus;
}

export const MediaStatus: FC<Props> = ({ status }) => {
  const { t } = useTranslation("monitoring");
  return (
    <Button2
      className={cx({ container: true, [getColorByStatus(status)]: true })}
    >
      <span className={styles.dot} />
      {t(status)}
    </Button2>
  );
};
