import { FC } from "react";
import { compareAsc, formatDistanceStrict } from "date-fns";
import { useTranslation } from "react-i18next";
import { IStatusModel } from "../types";
import { OrderTimelineItem } from "./OrderTimelineItem";
import { H3 } from "../../../components/Typography";
import styles from "./OrderTimeline.module.css";

interface Props {
  order: IStatusModel[];
  shipping: IStatusModel[];
}

export interface IStatusModelExtended extends IStatusModel {
  side: "left" | "right";
  difference: string;
  span: number;
}

const extend = (
  items: IStatusModel[],
  side: "left" | "right"
): IStatusModelExtended[] => {
  return items.map((item, index) => {
    const prev = items[index - 1];
    if (prev) {
      return {
        ...item,
        side,
        difference: formatDistanceStrict(
          new Date(prev.eventTime),
          new Date(item.eventTime)
        ),
        span: 0,
      };
    }
    return {
      ...item,
      side,
      difference: "",
      span: 0,
    };
  });
};

const getItemsBySide = (
  items: IStatusModelExtended[],
  side: "left" | "right"
) => {
  let span = 0;
  const updatedItems: IStatusModelExtended[] = [];
  items.forEach((item) => {
    if (item.side !== side) {
      span += 1;
      return null;
    }
    updatedItems.push({
      ...item,
      span,
    });
    span = 0;
  });
  return updatedItems;
};

export const OrderTimeline: FC<Props> = ({ shipping, order }) => {
  const { t } = useTranslation("order");

  const orderExtended: IStatusModelExtended[] = extend(order, "left");
  const shippingExtended: IStatusModelExtended[] = extend(shipping, "right");

  const timeline = [...shippingExtended, ...orderExtended].sort((a, b) =>
    compareAsc(new Date(a.eventTime), new Date(b.eventTime))
  );

  return (
    <div className={styles.container}>
      <H3 className={styles.title}>{t("timeline")}</H3>
      <div className={styles.content}>
        <div className={styles["left-container"]}>
          {getItemsBySide(timeline, "left").map((timelineItem, index) => (
            <OrderTimelineItem
              key={timelineItem.id}
              item={timelineItem}
              isFirst={index === 0}
            />
          ))}
        </div>
        <div className={styles["divider-container"]}>
          <div className={styles.divider} />
        </div>
        <div className={styles["right-container"]}>
          {getItemsBySide(timeline, "right").map((timelineItem, index) => (
            <OrderTimelineItem
              key={timelineItem.id}
              item={timelineItem}
              isFirst={index === 0}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
