import { TFunction } from "i18next";
import { ISystemDto } from "../../systems";
import { ISystemConfiguration, ISystemTasksValues } from "../types";

export const getSystemTasksNames = (system: ISystemDto, t: TFunction) => {
  if (system.activeTasks.length === 0) return t("empty");
  return system.activeTasks.map((task) => t(task)).join(", ");
};

export const getIconColor = (system: ISystemDto) => {
  if (system.activeTasks.length === 0) {
    return "var(--color-red-70)";
  }
  if (system.activeTasks.length === 3) {
    return "var(--color-green-70)";
  }
  return "var(--color-yellow-70)";
};

export const getIconBackgroundColor = (system: ISystemDto) => {
  if (system.activeTasks.length === 0) {
    return "red";
  }
  if (system.activeTasks.length === 3) {
    return "green";
  }
  return "yellow";
};

export const getDefaultValues = (system: ISystemDto, configuration: ISystemConfiguration): ISystemTasksValues => {
  const values = {} as Partial<ISystemTasksValues>;
  configuration.activeTasks.forEach(task => {
    values[task.activeTask] = system.activeTasks.some(t => t === task.activeTask);
  });
  return values as ISystemTasksValues;
}