import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISupportSliceState } from './types';

export const initialState: ISupportSliceState = {
  isDrawerOpen: false
};

const slice = createSlice({
  name: "support",
  initialState,
  reducers: {
    setDrawerOpen(state: ISupportSliceState, action: PayloadAction<boolean>) {
      state.isDrawerOpen = action.payload;
    },
  },
});

export const support = slice.reducer;
export const actions = slice.actions;