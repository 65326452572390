import { useTranslation } from "react-i18next";
import { IconHealthy } from "../../../assets";
import { IconWithBackground } from "../../../components/IconWithBackground";
import { Body1, H3 } from "../../../components/Typography";
import styles from "./SystemHealth.module.css";

export const SystemHealthConfirmationContent = () => {
  const { t } = useTranslation("healthy");
  return (
    <div className={styles["confirmation-container"]}>
      <div className={styles["icon-container"]}>
        <IconWithBackground
          icon={<IconHealthy stroke="var(--color-orange-70)" />}
          color="orange"
          isCircle
        />
      </div>
      <div className={styles["confirmation-title"]}>
        <H3>{t("confirmationTitle")}</H3>
      </div>
      <Body1>{t("confirmationDescription")}</Body1>
    </div>
  );
};
