import { SystemsDecorator } from "../../modules/systems";
import { Orders } from "./Orders";

export const OrdersContainer = () => {
  return (
    <SystemsDecorator>
      {(system) => {
        return <Orders tenantId={system.tenantId} />;
      }}
    </SystemsDecorator>
  );
};
