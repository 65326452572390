import { FC } from "react";
import { Form } from "antd";
import { useSearchParams } from "react-router-dom";
import { CreateKeyForm } from "./CreateKeyForm";
import { FormModal } from "../../../components/FormModal";
import { ICreateKeyDto } from "../types";
import styles from "./CreateKeyModal.module.css";

interface Props {
  systemId: number;
}

export const CreateKeyModal: FC<Props> = ({ systemId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm<ICreateKeyDto>();

  const handleSubmit = () => {
    form.submit();
  };

  const handleClose = () => {
    searchParams.delete("create");
    setSearchParams(searchParams);
  };

  return (
    <div className={styles.container}>
      <FormModal
        onCancel={handleClose}
        open={!!searchParams.get("create")}
        onConfirm={handleSubmit}
        width={480}
        content={
          <CreateKeyForm
            form={form}
            systemId={systemId}
            onClose={handleClose}
          />
        }
      />
    </div>
  );
};
