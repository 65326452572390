import { AxiosPromise } from "axios";
import { axios } from "../axios";
import { IApiList, IDateRange } from "../../app/types";
import {
  IManualPickupLocation,
  IOrder,
  IShippingRetry,
  EOrderPartOperations,
  EShipmentOperations,
  EOrderStatus,
  IOrdersListFilters,
  EOrderOperations,
} from "./types";
import { getPagination, getSearchFilter, getSortFilter } from "../../app/utils";
//import mock from './mock.json';

const getStatusFilter = (statuses: EOrderStatus[]) => {
  if (statuses.length === 0) {
    return "";
  }
  let query = "";
  statuses.forEach((status) => {
    query = `${query}&status=${status}`;
  });
  return query;
};

const getDatesFilter = (dates: IDateRange) => {
  let query = "";
  if (dates.start) {
    query = `&fromTime=${dates.start.toISOString()}`;
  }
  if (dates.end) {
    query = `${query}&toTime=${dates.end.toISOString()}`;
  }
  return query;
};



export const getOrders = (
  tenantId: number,
  filters: IOrdersListFilters
): AxiosPromise<IApiList<IOrder>> => {
  //return Promise.resolve({ data: mock.list } as unknown as AxiosPromise);
  const {
    page,
    pageSize,
    statusFilter,
    searchFilter,
    dateFilter,
    dateFilterSort,
    idFilter
  } = filters;
  return axios.get(
    `/orders?tenantId=${tenantId}${getPagination(page, pageSize)}${getStatusFilter(
      statusFilter
    )}${getSearchFilter(searchFilter, "details", 2)}${getSearchFilter(idFilter, "ordersIds")}${getDatesFilter(dateFilter)}${getSortFilter(
      dateFilterSort
    )}`
  );
};

export const getBoard = (
  tenantId: number,
  idFilter: string
): AxiosPromise<IOrder[]> => {
  return axios.get(
    `/orders/board?tenantId=${tenantId}${getSearchFilter(idFilter, "orderId")}`
  );
};

export const getOrder = (orderId: string): AxiosPromise<IOrder> => {
  //return Promise.resolve({ data: mock.order } as unknown as AxiosPromise);
  return axios.get(`/orders/${orderId}`);
};

export const getOrderOperations = (orderId: string): AxiosPromise<EOrderOperations[]> => {
  //return Promise.resolve({ data: mock.operations } as unknown as AxiosPromise);
  return axios.get(`/orders/${orderId}/operations`);
};

export const getOrderPartOperations = (
  orderPartId: string
): AxiosPromise<EOrderPartOperations[]> => {
  //return Promise.resolve({ data: mock.orderPartOperations } as unknown as AxiosPromise);
  return axios.get(`/orders/parts/${orderPartId}/operations`);
};

export const getShipmentOperations = (
  orderId: string
): AxiosPromise<EShipmentOperations[]> => {
  return axios.get(`/shipping/${orderId}/operations`);
};

export const manualDelivery = (orderPartId: string): AxiosPromise<string[]> => {
  return axios.post(`/orders/parts/${orderPartId}/manual/delivery`);
};

export const notifyFailure = (orderId: string): AxiosPromise<IOrder> => {
  return axios.post(`/orders/${orderId}/cancel?onFailure=true`);
};

export const manualPickup = (
  orderPartId: string
): AxiosPromise<IManualPickupLocation[]> => {
  return axios.post(`/orders/parts/${orderPartId}/manual/pickup`);
};

export const retryOrder = (orderPartId: string): AxiosPromise<IOrder> => {
  return axios.post(`/orders/parts/${orderPartId}/retry`);
};

export const cancelOrder = (orderId: string): AxiosPromise<IOrder> => {
  return axios.post(`/orders/${orderId}/cancel`);
};

export const queueOrder = (orderId: string): AxiosPromise<IOrder> => {
  return axios.post(`/orders/${orderId}/queue`);
};

export const completeOrder = (orderId: string): AxiosPromise<IOrder> => {
  return axios.post(`/orders/${orderId}/complete`);
};

export const resetOrder = (orderId: string): AxiosPromise<IOrder> => {
  return axios.post(`/orders/${orderId}/reset`);
};

export const retryShipping = (
  orderId: string,
  details: IShippingRetry
): AxiosPromise<IOrder> => {
  return axios.post(`/shipping/retry?orderId=${orderId}`, details);
};

export const exportToCSV = (
  tenantId: number,
  filters: IOrdersListFilters
): AxiosPromise<string> => {
  const { statusFilter, searchFilter, dateFilter, dateFilterSort } = filters;
  return axios.get(
    `/orders/report?tenantId=${tenantId}${getPagination(-1, -1)}${getStatusFilter(
      statusFilter
    )}${getSearchFilter(searchFilter, "details")}${getDatesFilter(dateFilter)}${getSortFilter(
      dateFilterSort
    )}`
  );
};

export const processOrders = (ordersIds: string[]): AxiosPromise<IOrder[]> => {
  return axios.post(`/orders/queue`, ordersIds);
};
