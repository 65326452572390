import { ICreateProduct, IProductFormValues } from "../types";

export const mapProductToCreate = (values: IProductFormValues): ICreateProduct => {
  return {
    name: values.name,
    categoryName: values.categoryName,
    temperature: values.temperature,
    external: values.external,
    metadata: values.metadata,
    base64Image: values.base64Image ? values.base64Image.split(',')[1] : ""
  }
}