import { Modal } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { LMNewButton } from "../LMNewButton";
import styles from "./ConfirmationModal.module.css";

interface Props {
  open: boolean;
  onConfirm?: () => void;
  onCancel: () => void;
  content: JSX.Element;
  cancelText?: string;
  confirmText?: string;
}

export const ConfirmationModal: FC<Props> = ({
  open,
  onConfirm,
  onCancel,
  content,
  cancelText,
  confirmText,
}) => {
  const { t } = useTranslation("layout");
  return (
    <Modal
      open={open}
      footer={null}
      onCancel={onCancel}
      width={400}
      className={styles.container}
    >
      <div className={styles.content}>{content}</div>
      <div className={styles.buttons}>
        <LMNewButton color="default" onClick={onCancel}>
          {cancelText || t("cancel")}
        </LMNewButton>
        <LMNewButton onClick={onConfirm}>
          {confirmText || t("confirm")}
        </LMNewButton>
      </div>
    </Modal>
  );
};
