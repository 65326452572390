import { AxiosPromise } from "axios";
import { IApiList, IDateRange } from "../../app/types";
import { axios } from "../axios";
import { Blobs, EMediaStatus, EMediaType, ICreateMediaRequests, IFetchMediaItemsProps, IMediaItem, Streams } from "./types";
import { getPagination, getSearchFilter, getSortFilter } from "../../app/utils";

const getStatusFilter = (statuses: EMediaStatus[]) => {
  if (statuses.length === 0) {
    return "";
  }
  let query = '';
  statuses.forEach(status => {
    query = `${query}&status=${status}`;
  })
  return query;
}

const getTypesFilter = (types: EMediaType[]) => {
  if (types.length === 0) {
    return "";
  }
  let query = '';
  types.forEach(type => {
    query = `${query}&type=${type}`;
  })
  return query;
}

const getDatesFilter = (dates: IDateRange) => {
  let query = '';
  if (dates.start) {
    query = `&fromTime=${dates.start.toISOString()}`
  }
  if (dates.end) {
    query = `${query}&toTime=${dates.end.toISOString()}`
  }
  return query;
}

export const getMediaItems = (args: IFetchMediaItemsProps): AxiosPromise<IApiList<IMediaItem>> => {
  const {
    systemId,
    filters
  } = args;
  const {
    pageSize,
    page,
    statusFilter,
    typeFilter,
    dateFilter,
    dateFilterSort,
    userFilter
  } = filters;
  return axios.get(`/media?systemId=${systemId}${getPagination(page, pageSize)}${getStatusFilter(statusFilter)}${getSearchFilter(userFilter, "user")}${getTypesFilter(typeFilter)}${getDatesFilter(dateFilter)}${getSortFilter(dateFilterSort)}`);
}

export const getMediaStreams = (systemId: number): AxiosPromise<Streams> => {
  return axios.get(`/media/${systemId}/live`);
}

export const getMediaBlobs = (requestId: string, mediaType: string): AxiosPromise<Blobs> => {
  return axios.get(`/media/blobs?requestId=${requestId}&mediaType=${mediaType}`);
}

export const createMediaRequests = (requests: ICreateMediaRequests): AxiosPromise<IMediaItem> => {
  return axios.post(`/media`, requests);
}