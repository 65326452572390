import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../app/store";
import { getEnvironment } from "../../modules/auth";

const isProduction = process.env.REACT_APP_ENV === "production";

export const useLink = () => {
  const navigate = useNavigate();
  const { selectedSystem } = useSelector((state: RootState) => {
    return state.systems;
  });
  const environment = getEnvironment() as string;
  const environmentPath = isProduction
    ? `/${environment.replace("clients:", "")}`
    : "";

  return (to: string) => {
    if (!selectedSystem) return;
    navigate(`${environmentPath}/${selectedSystem.id}${to}`);
  };
};
