import { ISystemDto } from "../../systems";
import { EMachineSpeedOptions } from "../../systems/types";

export const getIconColor = (system: ISystemDto) => {
  if (system.machineSpeed === EMachineSpeedOptions.Low) {
    return "var(--color-red-70)";
  }
  if (system.machineSpeed === EMachineSpeedOptions.Fast) {
    return "var(--color-green-70)";
  }
  return "var(--color-yellow-70)";
};

export const getIconBackgroundColor = (system: ISystemDto) => {
  if (system.machineSpeed === EMachineSpeedOptions.Low) {
    return "red";
  }
  if (system.machineSpeed === EMachineSpeedOptions.Fast) {
    return "green";
  }
  return "yellow";
};