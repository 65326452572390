import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EMediaStatus, EMediaType, EMonitoringViewType, IFetchMediaItemsProps, IMediaItem, IMonitoringSlice } from './types';
import { ApiStatuses, IDateRange, SortTypes } from '../../app/types';
import {
  getMediaBlobs,
  getMediaItems, getMediaStreams
} from './api';
import { MessageOperation } from '../signalR';

export const initialState: IMonitoringSlice = {
  list: [],
  filters: {
    page: 1,
    userFilter: "",
    statusFilter: [],
    typeFilter: [],
    dateFilter: { start: null, end: null },
    dateFilterSort: "none",
    pageSize: 10,
    total: 0,
    viewType: EMonitoringViewType.library,
  },
  status: ApiStatuses.initial,
  selectedCameras: [],
  streamsStatus: ApiStatuses.initial,
  blobs: null,
  streams: null,
  selectedMedia: null,
  mediaToPlay: null,
};

export const fetchList = createAsyncThunk(
  'monitoring/fetchList',
  async (props: IFetchMediaItemsProps) => {
    const response = await getMediaItems(props);
    return response.data;
  }
);

export const fetchStreams = createAsyncThunk(
  'media/fetchStreams',
  async ({ systemId }: { systemId: number }) => {
    const response = await getMediaStreams(systemId);
    return response.data;
  }
);

export const fetchBlobs = createAsyncThunk(
  'media/fetchBlobs',
  async ({ requestId, mediaType }: { requestId: string, mediaType: EMediaType }) => {
    const response = await getMediaBlobs(requestId, mediaType);
    return response.data;
  }
);

const isFiltersChanged = (
  state: IMonitoringSlice,
  payload: { user: string, statuses: string, viewType: string, dates: IDateRange, types: string }
) => {
  if (state.filters.userFilter !== payload.user) return false;
  const statuses = payload.statuses ? payload.statuses.split(",") as EMediaStatus[] : [];
  if (state.filters.statusFilter.join(",") !== statuses.join(",")) return false;
  const types = payload.types ? payload.types.split(",") as EMediaType[] : [];
  if (state.filters.typeFilter.join(",") !== types.join(",")) return false;
  if (state.filters.viewType !== payload.viewType) return false;
  if (JSON.stringify(state.filters.dateFilter) !== JSON.stringify(payload.dates)) return false;
}

const slice = createSlice({
  name: "monitoring",
  initialState,
  reducers: {
    setPage(state: IMonitoringSlice, action: PayloadAction<number>) {
      state.filters.page = action.payload;
    },
    setPageSize(state: IMonitoringSlice, action: PayloadAction<number>) {
      state.filters.pageSize = action.payload;
    },
    setFilters(state: IMonitoringSlice, action: PayloadAction<{ user: string, statuses: string, viewType: string, dates: IDateRange, types: string }>) {
      if (isFiltersChanged(state, action.payload)) {
        state.filters.page = 1;
      }
      state.filters.userFilter = action.payload.user;
      state.filters.statusFilter = action.payload.statuses ? action.payload.statuses.split(",") as EMediaStatus[] : [];
      state.filters.typeFilter = action.payload.types ? action.payload.types.split(",") as EMediaType[] : [];
      state.filters.viewType = action.payload.viewType as EMonitoringViewType;
      state.filters.dateFilter = action.payload.dates;
      
    },
    setDateSort(state: IMonitoringSlice, action: PayloadAction<SortTypes>) {
      state.filters.dateFilterSort = action.payload;
    },
    setCameras(state: IMonitoringSlice, action: PayloadAction<string[]>) {
      state.selectedCameras = action.payload;
    },
    setSelectedMedia(state: IMonitoringSlice, action: PayloadAction<IMediaItem>) {
      state.selectedMedia = action.payload;
    },
    resetSelectedMedia(state: IMonitoringSlice) {
      state.mediaToPlay = null;
      state.blobs = null;
      state.selectedMedia = null;
    },
    setMediaToPLay(state: IMonitoringSlice, action: PayloadAction<string >) {
      state.mediaToPlay = action.payload;
    },
    setNextImageToPlay(state: IMonitoringSlice) {
      if (!state.blobs || !state.mediaToPlay) return;
      const images = Object.values(state.blobs)
      const currentIndex = images.indexOf(state.mediaToPlay);
      const nextIndex = currentIndex + 1 > images.length - 1 ? 0 : currentIndex + 1;
      state.mediaToPlay = images[nextIndex];
    },
    setPrevImageToPlay(state: IMonitoringSlice) {
      if (!state.blobs || !state.mediaToPlay) return;
      const images = Object.values(state.blobs)
      const currentIndex = images.indexOf(state.mediaToPlay);
      const prevIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
      state.mediaToPlay = images[prevIndex];
    },
    updateList(state, action: PayloadAction<{ operation: MessageOperation, mediaString: string }>) {
      if (state.status !== ApiStatuses.success) return;
      const media = JSON.parse(action.payload.mediaString);
      if (action.payload.operation === MessageOperation.Updates) {
        state.list = state.list.map(m => m.id === media.id ? media : m);
      }
      if (action.payload.operation === MessageOperation.Create && state.filters.page === 1) {
        state.list = [media, ...state.list.slice(0, -1)]
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchList
      .addCase(fetchList.pending, (state) => {
        state.status = ApiStatuses.loading;
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        state.status = ApiStatuses.success;
        state.list = action.payload.values;
        state.filters.total = action.payload.count;
      })
      .addCase(fetchList.rejected, (state) => {
        state.status = ApiStatuses.fail;
      })
      // fetchStreams
      .addCase(fetchStreams.pending, (state) => {
        state.status = ApiStatuses.loading;
      })
      .addCase(fetchStreams.fulfilled, (state, action) => {
        state.streamsStatus = ApiStatuses.success;
        state.streams = action.payload;
      })
      .addCase(fetchStreams.rejected, (state) => {
        state.status = ApiStatuses.fail;
      })
      // fetchBlobs
      .addCase(fetchBlobs.pending, (state) => {
        state.status = ApiStatuses.loading;
      })
      .addCase(fetchBlobs.fulfilled, (state, action) => {
        state.streamsStatus = ApiStatuses.success;
        state.blobs = action.payload;
      })
      .addCase(fetchBlobs.rejected, (state) => {
        state.status = ApiStatuses.fail;
      })
  },
});

export const monitoring = slice.reducer;
export const actions = slice.actions;