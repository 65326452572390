import { Form, RadioChangeEvent } from "antd";
import { Col, Row } from "antd";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import {
  LMRadio,
  LMSelect,
  Option,
  RadioButton,
  LMButton,
  LMInput,
} from "../../../components";
import { IIndexingRangeForm } from "../types";
import { IStation } from "../../system";
import { fetchStations } from "../../system";
import styles from "./CreateRangeForm.module.css";
import { generateId } from "../../../app/utils";

interface Props {
  systemId: number;
  stations: IStation[];
  values?: IIndexingRangeForm;
  onAddRange?: (rangeValues: IIndexingRangeForm) => void;
  onRemove?: (rangeValues: IIndexingRangeForm) => void;
}

export const CreateRangeForm: FC<Props> = ({
  systemId,
  stations,
  values,
  onAddRange,
  onRemove,
}) => {
  const [palletId, setPalletId] = useState<string | undefined>(
    values ? values.palletId : undefined
  );

  const [side, setSide] = useState<"A" | "B" | undefined>(
    values ? values.side : undefined
  );
  const [row, setRow] = useState<number | undefined>(
    values ? values.row : undefined
  );
  const [column, setColumn] = useState<number | undefined>(
    values ? values.column : undefined
  );
  const { t } = useTranslation("indexing");
  const dispatch = useDispatch<AppDispatch>();

  const handleFetchStations = useCallback(() => {
    dispatch(fetchStations(systemId));
  }, [systemId, dispatch]);

  useEffect(() => {
    handleFetchStations();
  }, [handleFetchStations]);

  const handleChangeSide = (e: RadioChangeEvent) => {
    setSide(e.target.value);
  };

  const handleChangePallet = (value: string) => {
    if (!value) {
      setRow(undefined);
      setColumn(undefined);
      setSide(undefined);
    }
    setPalletId(value);
  };

  const handleChangeRow = (event: ChangeEvent<HTMLInputElement>) => {
    setRow(Number(event.target.value));
  };

  const handleChangeColumn = (event: ChangeEvent<HTMLInputElement>) => {
    setColumn(Number(event.target.value));
  };

  const handleResetValues = () => {
    setPalletId(undefined);
    setSide(undefined);
    setRow(undefined);
    setColumn(undefined);
  };

  const handleAdd = () => {
    if (!onAddRange || !palletId) return;
    onAddRange({
      id: generateId(),
      palletId,
      side,
      row: row || row === 0 ? row : undefined,
      column: column || column === 0 ? column : undefined,
    });
    handleResetValues();
  };

  const handleRemove = () => {
    if (!values || !onRemove) return;
    onRemove(values);
  };

  return (
    <div className={!values ? styles.active : undefined}>
      {!values && (
        <h3 className={styles["range-form-title"]}>{t("rangeTitle")}</h3>
      )}
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label={t("pallet")}>
            <LMSelect
              size={"large"}
              onChange={handleChangePallet}
              allowClear
              optionLabelProp="label"
              showSearch
              disabled={Boolean(values)}
              value={palletId}
            >
              {stations
                .filter((s) => s.palletId)
                .map((station) => (
                  <Option
                    label={station.palletId}
                    key={station.palletId}
                    value={station.palletId}
                  >
                    <div className={styles.flex}>{station.palletId}</div>
                  </Option>
                ))}
            </LMSelect>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item label={t("side")}>
            <LMRadio
              onChange={handleChangeSide}
              disabled={Boolean(values)}
              value={side}
            >
              <RadioButton value="A">A</RadioButton>
              <RadioButton value="B">B</RadioButton>
            </LMRadio>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label={t("row")}>
            <LMInput
              disabled={Boolean(values)}
              size="large"
              type="number"
              min={0}
              value={row}
              onChange={handleChangeRow}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label={t("column")}>
            <LMInput
              disabled={Boolean(values)}
              size="large"
              type="number"
              min={0}
              value={column}
              onChange={handleChangeColumn}
            />
          </Form.Item>
        </Col>
        <Col span={5} className={styles["button-container"]}>
          {Boolean(values) ? (
            <LMButton type="primary" size="large" danger onClick={handleRemove}>
              {t("remove")}
            </LMButton>
          ) : (
            <Form.Item>
              <LMButton
                type="primary"
                disabled={!palletId}
                formAction="button"
                className={styles["add-button"]}
                size="large"
                onClick={handleAdd}
              >
                {t("confirm")}
              </LMButton>
            </Form.Item>
          )}
        </Col>
      </Row>
    </div>
  );
};
