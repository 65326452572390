import { FC, useEffect, useState } from "react";
import { IProductSku } from "../types";
import styles from "./ProductSkusList.module.css";
import { Button2 } from "../../../components/Typography";
import { LMNewButton } from "../../../components/LMNewButton";
import { useTranslation } from "react-i18next";
import { Popover } from "antd";
import { IconDropdownArrow } from "../../../assets";

interface Props {
  skus: IProductSku[];
}

const calculateCount = (windowWidth: number) => {
  const width = windowWidth - 600;
  return Math.round(width / 100);
};

const getSkusToShowInTheList = (skus: IProductSku[], amount: number) => {
  return skus.slice(0, amount);
};

const getSkusToShowInThePopover = (skus: IProductSku[], start: number) => {
  const list = skus.slice(start, skus.length);
  return (
    <ul className={styles.list}>
      {list.map((item) => (
        <li key={item.id}>{item.sku}</li>
      ))}
    </ul>
  );
};

export const ProductSkusList: FC<Props> = ({ skus }) => {
  const { t } = useTranslation("productsPortfolio");
  const [skusToShow, setSkusToShow] = useState(
    calculateCount(window.innerWidth)
  );

  useEffect(() => {
    setSkusToShow(calculateCount(window.innerWidth));
    window.addEventListener("resize", () => {
      setSkusToShow(calculateCount(window.innerWidth));
    });
  }, []);

  return (
    <div className={styles.container}>
      {getSkusToShowInTheList(skus, skusToShow).map((sku) => (
        <Button2 key={sku.id} className={styles.item}>
          {sku.sku}
        </Button2>
      ))}
      {skus.length - skusToShow > 0 && (
        <Popover content={getSkusToShowInThePopover(skus, skusToShow)}>
          <LMNewButton
            color="transparent"
            className={styles["more-button"]}
            iconRight={
              <span className={styles.icon}>
                <IconDropdownArrow stroke="var(--color-primary-80)" />
              </span>
            }
          >
            {skus.length - skusToShow} {t("more")}
          </LMNewButton>
        </Popover>
      )}
    </div>
  );
};
