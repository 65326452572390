import { ArrowRightOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { LMLink, LMSelect, LoaderWrapper, Option } from "../../../components";
import { fetchIndexingRequest } from "../slice";
import { IIndexCellProduct } from "../types";
import { IndexingCompareTable } from "./IndexingCompareTable";
import { IndexingRangesTable } from "./IndexingRangesTable";
import { fetchStations } from "../../system";
import styles from "./IndexingDetails.module.css";

interface Props {
  requestId: number;
  systemId: number;
}

export const IndexingDetails: FC<Props> = ({ requestId, systemId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation("indexing");
  const [palletId, setPalletId] = useState<string | undefined>();

  const { detailsStatus, requestDetails } = useSelector((state: RootState) => {
    return state.indexing;
  });

  const pallets = useSelector((state: RootState) => {
    return state.system.stations;
  });

  const handleChangePallet = (value: string) => {
    setPalletId(value);
  };

  const getIndexingRequest = useCallback(() => {
    dispatch(fetchIndexingRequest({ requestId }));
  }, [requestId, dispatch]);

  useEffect(() => {
    getIndexingRequest();
  }, [getIndexingRequest]);

  const handleFetchStations = useCallback(() => {
    dispatch(fetchStations(systemId));
  }, [systemId, dispatch]);

  useEffect(() => {
    handleFetchStations();
  }, [handleFetchStations]);

  const filterByPallet = (
    cellProducts: IIndexCellProduct[],
    _palletId?: string
  ) => {
    if (!palletId) return cellProducts;
    return cellProducts.filter((p) => p.palletId === palletId);
  };

  return (
    <LoaderWrapper status={detailsStatus}>
      <div className={styles.container}>
        {requestDetails && (
          <>
            <header className={styles.header}>{`${t("compareTitle")} #${
              requestDetails.id
            }`}</header>
            <Row gutter={16}>
              {requestDetails.maintenanceId && (
                <Col span={24}>
                  <span className={styles.label}>
                    {t("maintenanceId")}:{" "}
                    <LMLink
                      to={`/maintenance/replenishment?maintenanceId=${requestDetails.maintenanceId}`}
                      className={styles.value}
                    >
                      {requestDetails.maintenanceId}
                      <ArrowRightOutlined />
                    </LMLink>
                  </span>
                </Col>
              )}
              <Col span={16} className={styles.tips}>
                <div className={styles["select-container"]}>
                  <div className={styles.label}>{t("filterByPallet")}</div>
                  <LMSelect
                    size={"large"}
                    onChange={handleChangePallet}
                    placeholder={t("palletIdPlaceholder")}
                    allowClear
                    optionLabelProp="label"
                    showSearch
                    value={palletId}
                  >
                    {pallets
                      .filter((p) => p.palletId)
                      .map((pallet) => (
                        <Option
                          label={pallet.palletId}
                          key={pallet.palletId}
                          value={pallet.palletId}
                        >
                          <div>{pallet.palletId}</div>
                        </Option>
                      ))}
                    <Option label={"12"} key={"12"} value={"12"}>
                      <div>{"12"}</div>
                    </Option>
                  </LMSelect>
                </div>
              </Col>
              <Col span={24}>
                <IndexingCompareTable
                  cellProducts={filterByPallet(
                    requestDetails.indexedProducts,
                    palletId
                  )}
                />
              </Col>
              {requestDetails.indexPalletRequests.length === 0 ? (
                <Col span={24}>
                  <div className={styles["no-request"]}>
                    {t("wholeSystemIndexing")}
                  </div>
                </Col>
              ) : (
                <>
                  <Col span={24}>
                    <h3 className={styles["ranges-title"]}>
                      {t("rangesTitle")}
                    </h3>
                  </Col>
                  <Col span={24}>
                    <IndexingRangesTable
                      requests={requestDetails.indexPalletRequests}
                    />
                  </Col>
                </>
              )}
            </Row>
          </>
        )}
      </div>
    </LoaderWrapper>
  );
};
