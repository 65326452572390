import { useEffect, useState } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import {
  CreateMetadataForm,
  IMetadataEntryFormValues,
  MetadataEntryDetails,
  MetadataFilters,
  MetadataList,
  metadataActions,
} from "../../modules/metadata";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";

import { useAppNotifications } from "../../components/LMNotifications";
import { FormModal } from "../../components/FormModal";
import { Nullable } from "../../app/types";
import { IMetadataEntry } from "../../modules/metadata/types";
import { useSearchParams } from "react-router-dom";
import styles from "./Metadata.module.css";

export const Metadata = () => {
  const { t } = useTranslation("metadata");
  const { contextHolder, success, error } = useAppNotifications();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [selectedEntry, setSelectedEntry] =
    useState<Nullable<IMetadataEntry>>(null);

  const [form] = Form.useForm<IMetadataEntryFormValues>();

  const { status, filters, list, total } = useSelector((state: RootState) => {
    return state.metadata;
  });

  useEffect(() => {
    dispatch(
      metadataActions.setFilters({
        nameFilter: searchParams.get("name") || "",
        requiredFilter: searchParams.get("required") ? true : false,
      })
    );
  }, [searchParams, dispatch]);

  const showCreateModal = () => setCreateModalOpen(true);
  const hideCreateModal = () => setCreateModalOpen(false);

  const handleConfirmCreateEntry = () => form.submit();

  const handleEditSuccess = () => success(t("updateSuccessMessage"));
  const handleEditError = () => error(t("updateErrorMessage"));

  return (
    <div className={styles.container}>
      {contextHolder}
      <div className={styles.filters}>
        <MetadataFilters
          nameFilter={filters.nameFilter}
          requiredFilter={filters.requiredFilter}
          onShowCreateMetadataModal={showCreateModal}
        />
      </div>
      <div className={styles.list}>
        <MetadataList
          list={list}
          total={total}
          onEditEntry={(entry: IMetadataEntry) => setSelectedEntry(entry)}
          status={status}
        />
      </div>
      <FormModal
        onConfirm={handleConfirmCreateEntry}
        submitText={t("add")}
        content={<CreateMetadataForm form={form} onClose={hideCreateModal} />}
        open={createModalOpen}
        onCancel={hideCreateModal}
      />
      <MetadataEntryDetails
        key={selectedEntry?.id}
        entry={selectedEntry}
        onHide={() => setSelectedEntry(null)}
        onSuccess={handleEditSuccess}
        onError={handleEditError}
      />
    </div>
  );
};
