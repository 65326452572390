import { FC, useEffect, useState } from "react";
import { Popover } from "antd";
import { IOrderPart } from "../types";
import { Button2 } from "../../../components/Typography";
import { LMNewButton } from "../../../components/LMNewButton";
import styles from "./OrderPartsList.module.css";
import { OrderStatus } from "../OrderStatus";

interface Props {
  parts: IOrderPart[];
}

const calculateCount = (windowWidth: number) => {
  const width = windowWidth - 900;
  return Math.round(width / 100);
};

const getSkusToShowInTheList = (parts: IOrderPart[], amount: number) => {
  return parts.slice(0, amount);
};

const getSkusToShowInThePopover = (parts: IOrderPart[], start: number) => {
  const list = parts.slice(start, parts.length);
  return (
    <ul className={styles.list}>
      {list.map((item) => (
        <li key={item.id} className={styles.item}>
          <OrderStatus status={item.status} onlyDot />
          {item.id}
        </li>
      ))}
    </ul>
  );
};

export const OrderPartsList: FC<Props> = ({ parts }) => {
  const [partsToShow, setPartsToShow] = useState(
    calculateCount(window.innerWidth)
  );

  useEffect(() => {
    setPartsToShow(calculateCount(window.innerWidth));
    window.addEventListener("resize", () => {
      setPartsToShow(calculateCount(window.innerWidth));
    });
  }, []);

  return (
    <div className={styles.container}>
      {getSkusToShowInTheList(parts, partsToShow).map((part) => (
        <Button2 key={part.id} className={styles.item}>
          <OrderStatus status={part.status} onlyDot /> {part.id}
        </Button2>
      ))}
      {parts.length - partsToShow > 0 && (
        <Popover content={getSkusToShowInThePopover(parts, partsToShow)}>
          <LMNewButton color="transparent" className={styles["more-button"]}>
            +{parts.length - partsToShow}
          </LMNewButton>
        </Popover>
      )}
    </div>
  );
};
