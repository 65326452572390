import { FC } from "react";
import { Checkbox, CheckboxProps } from "antd";
import styles from "./LMCheck.module.css";

interface Props extends CheckboxProps {}

export const LMCheck: FC<Props> = (props) => {
  return (
    <Checkbox {...props} className={`${props.className} ${styles.check}`} />
  );
};
