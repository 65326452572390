import { Divider } from "antd";
import classNames from "classnames/bind";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { RootState } from "../../../app/store";
import { capitalizeFirst } from "../../../app/utils";
import { IconOrganization } from "../../../assets";
import { LMDropdown } from "../../../components";
import { Label } from "../../../components/Typography";
import { clients } from "../scopes";
import {
  getClientNameFromScope,
  getEnvironment,
  setEnvironment,
} from "../utils";
import styles from "./EnvironmentSelector.module.css";

const cx = classNames.bind(styles);

interface Props {
  collapsed: boolean;
}

const isProduction = process.env.REACT_APP_ENV === "production";

export const EnvironmentSelector: FC<Props> = ({ collapsed }) => {
  const { t } = useTranslation("layout");
  const location = useLocation();
  const params = useParams();

  const { clientsAllAccess } = useSelector((state: RootState) => {
    return state.auth;
  });

  const handleChange = (client: string) => {
    const environment = getEnvironment();
    setEnvironment(client);
    if (environment) {
      window.location.href = location.pathname.replace(
        params.client as string,
        getClientNameFromScope(client)
      );
    }
  };

  if (!clientsAllAccess || !isProduction) return null;

  const title = t("organization") as string;
  const shortTitle = title.slice(0, 3);

  return (
    <>
      <div className={cx({ header: true, collapsed })}>
        <Divider className={styles.divider} />
        <Label className={cx({ title: true, collapsed })}>
          {collapsed ? shortTitle : title}
        </Label>
      </div>
      <div className={styles.container}>
        <LMDropdown
          defaultValue={getEnvironment() || clients[0]}
          onChange={handleChange}
          Icon={IconOrganization}
          items={clients.map((client) => ({
            name: capitalizeFirst(getClientNameFromScope(client)),
            value: client,
          }))}
          collapsed={collapsed}
          title={t("chooseEnv")}
        />
      </div>
    </>
  );
};
