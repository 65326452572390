import { FC, useCallback } from "react";
import { IControlCommand, TControlCommandGrouped } from "../types";
import { ControlFilters } from "../ControlFilters";
import { groupCommandBySystem } from "../utils";
import { ControlModule } from "../ControlModule";
import styles from "./ControlCommands.module.css";

interface Props {
  commands: IControlCommand[];
  searchString: string;
  serialNumber: string;
  systemId: number;
}

export const ControlCommands: FC<Props> = ({
  commands,
  searchString,
  systemId,
  serialNumber,
}) => {
  const filterCommands = useCallback(() => {
    const groupedCommands = groupCommandBySystem(commands);
    const filteredCommands: TControlCommandGrouped = {};
    Object.keys(groupedCommands).forEach((module) => {
      const commandNames = groupedCommands[module]
        .map((name) => name.commandName.toLowerCase())
        .join(",");
      const search = searchString.toLowerCase();
      if (commandNames.includes(search)) {
        filteredCommands[module] = groupedCommands[module].filter((command) => {
          return command.commandName.toLowerCase().includes(search);
        });
      }
    });
    return filteredCommands;
  }, [commands, searchString]);

  return (
    <div>
      <div className={styles.filters}>
        <ControlFilters searchString={searchString} systemId={systemId} />
      </div>
      <div className={styles.modules}>
        {Object.keys(filterCommands()).map((module) => {
          const moduleCommands = filterCommands()[module] as IControlCommand[];
          return (
            <ControlModule
              commands={moduleCommands}
              serialNumber={serialNumber}
              moduleName={module}
              key={module}
            />
          );
        })}
      </div>
    </div>
  );
};
