import { ChangeEvent, FC, HTMLProps, ReactNode } from "react";
import styles from "./LMNewInput.module.css";
import { FormInstance } from "antd";

interface Props<T> extends HTMLProps<HTMLInputElement> {
  icon?: ReactNode;
  formInstance?: FormInstance<T>;
}

export const LMNewInput: FC<Props<unknown>> = ({
  icon,
  formInstance,
  type = "text",
  ...rest
}) => {
  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (rest.onChange) rest.onChange(evt);
    if (formInstance && rest.name) {
      formInstance.setFieldValue(rest.name, evt.target.value);
    }
  };

  if (icon) {
    return (
      <div className={styles.container}>
        <span className={styles.icon}>{icon}</span>
        <input
          {...rest}
          className={`${styles.input} ${styles["with-icon"]}`}
          type={type}
          onChange={handleChange}
          autoComplete="off"
        />
      </div>
    );
  }

  return (
    <input
      {...rest}
      onChange={handleChange}
      autoComplete="off"
      className={styles.input}
      type={type}
    />
  );
};
