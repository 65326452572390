import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "antd";
import { LMNewButton } from "../../../components/LMNewButton";
import { IconPlus } from "../../../assets";
import { ProductSkuForm } from "../ProductSkuForm";
import { IProductSku } from "../types";
import { ProductSkuItem, ProductSkuItemHeader } from "../ProductSkuItem";
import { useAppNotifications } from "../../../components/LMNotifications";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { refreshList, refreshSelectedProduct } from "../slice";
import { addProductSku } from "../api";
import styles from "./ProductSkus.module.css";

const { Panel } = Collapse;

interface Props {
  productId: number;
  skus: IProductSku[];
  editable: boolean;
  onClose: () => void;
}

export const ProductSkus: FC<Props> = ({
  productId,
  skus,
  editable,
  onClose,
}) => {
  const { t } = useTranslation("productsPortfolio");
  const { t: tLayout } = useTranslation("layout");
  const [skusInMemory, setSkusInMemory] = useState(skus);
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const { contextHolder, success, error } = useAppNotifications();
  const [isCreateFormShown, setIsCreateFormShown] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const showCreateForm = () => setIsCreateFormShown(true);
  const hideCreateForm = () => setIsCreateFormShown(false);

  const handleAddSkuSuccess = () => success(t("addSkuSuccess"));
  const handleAddSkuError = () => error(t("addSkuError"));

  const handleAddSku = (sku: IProductSku) => {
    setDirty(true);
    setSkusInMemory([...skusInMemory, sku]);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const newSkus = skusInMemory.filter(
      (sku) => !skus.find((s) => s.id === sku.id)
    );
    try {
      for (let i = 0; i < newSkus.length; i++) {
        const newSku = newSkus[i];
        await addProductSku(productId, {
          sku: newSku.sku,
          barcodes: newSku.barcodes || [],
        });
      }
      await dispatch(refreshList());
      await dispatch(refreshSelectedProduct());
      setLoading(false);
      handleAddSkuSuccess();
    } catch (error) {
      handleAddSkuError();
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      {contextHolder}
      {editable && (
        <div className={styles.head}>
          <LMNewButton
            color="default"
            iconRight={<IconPlus stroke="var(--color-gray-80)" />}
            onClick={showCreateForm}
          >
            {t("addSku")}
          </LMNewButton>
        </div>
      )}
      {isCreateFormShown && (
        <ProductSkuForm
          onClose={hideCreateForm}
          onSuccess={handleAddSkuSuccess}
          onError={handleAddSkuError}
          onAdd={handleAddSku}
        />
      )}
      <Collapse className={styles["skus-list"]} expandIconPosition="end">
        {skusInMemory.map((sku) => (
          <Panel
            className={styles["sku-item"]}
            header={<ProductSkuItemHeader sku={sku} />}
            key={sku.id}
          >
            <ProductSkuItem sku={sku} editable={editable} />
          </Panel>
        ))}
      </Collapse>
      {editable && (
        <div className={styles.buttons}>
          <LMNewButton
            color="default"
            onClick={onClose}
            className={styles.button}
          >
            {t("cancel")}
          </LMNewButton>

          <LMNewButton disabled={!dirty || loading} onClick={handleSubmit}>
            {loading ? tLayout("pleaseWait") : t("saveChanges")}
          </LMNewButton>
        </div>
      )}
    </div>
  );
};
