import { FC } from "react";
import { IProductSku } from "../types";
import styles from "./ProductSkuItem.module.css";
import { Body2, Button2 } from "../../../components/Typography";
import { useTranslation } from "react-i18next";
import { Divider } from "antd";

interface Props {
  sku: IProductSku;
}

export const ProductSkuItemHeader: FC<Props> = ({ sku }) => {
  const { t } = useTranslation("productsPortfolio");
  return (
    <div className={styles["headerContainer"]}>
      <Button2 className={styles.name}>{sku.sku}</Button2>
      <Body2 className={styles.details}>
        <span className={styles.label}>{t("barCodes")}:&nbsp;</span>
        <span className={styles.value}>
          {sku.barcodes ? sku.barcodes.length : 0}
        </span>
        {/* <Divider type="vertical" className={styles.divider} />
        <span className={styles.label}>{t("disabledSystems")}:&nbsp;</span>
        <span className={styles.value}>0</span> */}
      </Body2>
      <Divider className={styles.line} />
    </div>
  );
};
