import { Modal } from "antd";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { LMNewButton } from "../LMNewButton";
import styles from "./FormModal.module.css";

interface Props {
  open: boolean;
  onConfirm?: () => void;
  onCancel: () => void;
  content: JSX.Element;
  cancelText?: string;
  submitText?: string;
  width?: number;
  loading?: boolean;
}

export const FormModal: FC<Props> = ({
  open,
  onConfirm,
  onCancel,
  content,
  cancelText,
  submitText,
  width = 400,
  loading = false,
}) => {
  const { t } = useTranslation("layout");

  const getSubmitText = useCallback(() => {
    if (loading) {
      return t("pleaseWait");
    }
    if (submitText) {
      return submitText;
    }
    return t("submit");
  }, [loading, submitText, t]);

  return (
    <Modal
      open={open}
      footer={null}
      onCancel={onCancel}
      width={width}
      className={styles.container}
    >
      <div className={styles.content}>{content}</div>

      <div className={styles.buttons}>
        <LMNewButton
          color="default"
          onClick={onCancel}
          className={styles.button}
        >
          {cancelText || t("cancel")}
        </LMNewButton>
        <LMNewButton
          onClick={onConfirm}
          disabled={loading}
          className={styles.button}
        >
          {getSubmitText()}
        </LMNewButton>
      </div>
    </Modal>
  );
};
