import { useState } from "react";
import classNames from "classnames/bind";
import { Dropdown, MenuProps } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { LMIconButton } from "../../components/LMIconButton";
import { IconQuestionHeader, IconSupport, IconWarranty } from "../../assets";
import { AppDispatch } from "../../app/store";
import { actions } from "../../modules/support/slice";
import styles from "./HeaderOptions.module.css";

const cx = classNames.bind(styles);

export const HeaderOptions = () => {
  const { t } = useTranslation("layout");
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch<AppDispatch>();

  const handleShowSupportModal = () => {
    dispatch(actions.setDrawerOpen(true));
  };

  const handleOpenWarrantyPage = () => {
    window.open(`${window.location.origin}/warranty`, "_blank");
  };

  const items: MenuProps["items"] = [
    {
      label: t("support"),
      key: t("support") as string,
      onClick: () => handleShowSupportModal(),
      itemIcon: <IconSupport stroke="var(--stroke)" />,
    },
    {
      label: t("warranty"),
      key: t("warranty") as string,
      onClick: () => handleOpenWarrantyPage(),
      itemIcon: <IconWarranty stroke="var(--stroke)" />,
    },
  ];

  return (
    <>
      <iframe
        title="form-iframe"
        name="form-iframe"
        id="form-iframe"
        style={{ display: "none" }}
      />
      <Dropdown menu={{ items, className: styles.menu }} onOpenChange={setOpen}>
        <div className={cx({ container: true, open })}>
          <LMIconButton onClick={() => setOpen(true)} color="white">
            <IconQuestionHeader stroke="var(--stroke)" />
          </LMIconButton>
        </div>
      </Dropdown>
    </>
  );
};
