import { FC } from "react";
import { Select } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { Body2 } from "../../../components/Typography";
import styles from "./BarCodesSelector.module.css";

interface Props<T> {
  onChange?: (value: string[]) => void;
  defaultValue?: string;
  title?: string;
  placeholder?: string;
  small?: boolean;
  value?: string[];
  formInstance?: FormInstance<T>;
  name?: string;
}

export const BarCodesSelector: FC<Props<unknown>> = ({
  onChange,
  placeholder,
  value,
}) => {
  const { t } = useTranslation("productsPortfolio");

  return (
    <>
      <Select
        open={false}
        dropdownRender={() => <></>}
        mode="tags"
        style={{ width: "100%" }}
        onChange={onChange}
        tokenSeparators={[","]}
        placeholder={placeholder}
        className={styles.container}
        value={value}
      />
      <Body2 className={styles.tip}>{t("barCodesTip")}</Body2>
    </>
  );
};
