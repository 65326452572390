import { FC } from "react";
import { Input, InputProps } from "antd";
import styles from "./LMInput.module.css";
import { TextAreaProps } from "antd/lib/input";

const { TextArea } = Input;

export const LMInput: FC<InputProps> = ({ ...rest }) => {
  return <Input {...rest} className={styles.input} />;
};

export const LMTextArea: FC<TextAreaProps> = ({ ...rest }) => {
  return (
    <TextArea {...rest} className={`${styles.input} ${styles.textarea}`} />
  );
};
